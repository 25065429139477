// import { appTextType } from '../hooks/useText'

export const text : any = {
  startScreen: 'Deel anoniem jouw mening en ontdek wat andere jongeren denken',
  userInfoTitle: 'Voor we beginnen ...',
  userInfoIntro: 'Waddist gebruikt deze info alleen om te zien wat jongeren van alle leeftijden over heel Vlaanderen en Brussel denken.\n\nDe app deelt deze info niet.',
  userInfoBtnLabel: 'Klaar!',
  bottomTabHome: 'Home',
  bottomTabResults: 'Antwoorden',
  bottomTabBadges: 'Badges',
  bottomTabFeedback: 'Feedback',
  thxAnswers: 'Dank je wel voor je antwoorden van vandaag',
  nextQuestionsOn: ', de volgende vragen verschijnen op ',
  readTipBtn: 'Lees tip',
  watchTipBtn: 'Kijk video',
  CtaResults: 'Zie wat andere jongeren gisteren antwoordden',
  CtaFeedback: 'Zelf een goede vraag? Stuur ze in',
  BadgeScreenTitle: 'Goed bezig!',
  ResultScreenTitle: 'Antwoorden van gisteren',
  FeedbackScreenTitle: 'Feedback',
  FeedbackScreenIntro: 'Heb je tips voor of vragen over de app?\nTijdens de testfase kan je feedback geven',
  NotificationsDisabled: 'Notificaties voor deze app zijn afgezet in uw systeeminstellingen.',
  NotificationsAllowed: 'Laat Waddist toe mij notificaties te sturen',
  NotificationsSettingsTitle: 'Notificaties',
  NotificationsSettingsLink: 'Zet dit aan in de instellingen van uw telefoon.',
  OfflineTitle: 'Waddist is niet beschikbaar',
  OfflineText: 'Heeft je telefoon internettoegang?',
  OfflineButton: 'Probeer opnieuw',
  tipRead: 'Gelezen',
  tipTitle: 'Tip van de {"\n"}dag',
  next: 'Volgende',
  nextQuestion: 'Volgende vraag',
  questionProposedBy: 'vraag gesteld door',
  questionsAnsweredText: 'vragen beantwoord',
  tipsViewed: 'tips bekeken',
  day: 'dag',
  days: 'dagen',
  currentStreak: 'Streak van',
  badgesEarned: 'Behaalde badges',
  achievable: 'Nog te behalen',
  appNeedsUpdate: 'De app moet bijgewerkt worden.',
  whatHappensWithMyAnswers: 'Wat gebeurt er met mijn antwoorden?',
  badgesAlmostAchieved: 'Deze badges zijn bijna binnen',
  extraInfo: 'Extra info of hulp',
  seeAllInfoLines: 'Zie alle infolijnen voor jongeren',
  askThroughChatMailPhone: 'Stel je vraag via chat, mail, telefoon of ga langs.',
  turnOn: 'zet aan',
  no: 'Nee',
  questionProposal1: "Stuur vraag in",
  questionProposal2: "Wat wil jij vragen aan andere gebruikers?",
  questionProposal3: "Wil je nog iets zeggen? ",
  questionProposal4: "Stuur je vraag in",
  tipProposal1: "Stuur tip in",
  tipProposal2: "Welke tip wil jij meegeven aan andere gebruikers?",
  tipProposal3: "Wil je nog iets zeggen bij deze tip?",
  tipProposal4: " Stuur tip in",
  general1: "Zoek je hulp?",
  general2: "Wat wil jij aan de makers van Waddist vragen?",
  general3: "Je vraag of suggestie",
  general4: "Stuur vraag of suggestie",
  bugReport1: "Meld fout in de app",
  bugReport2: "",
  bugReport3: "Wat loopt er fout in de app?",
  bugReport4: "Meld fout",
  yourQuestion: "Jouw vraag",
  possibleAnswers: "Mogelijke antwoorden op je vraag",
  answerToYourQuestion: "Antwoord ",
  addAnswer: "Voeg antwoord toe",
  maxOf6Answers: "Er is een maximum van 6 antwoorden",
  linkToTip: "Link naar tip",
  yourName: "Je naam",
  myNameCanBeShown: "Mijn naam mag vermeld worden.",
  anonymous: "anoniem",
  canContactMe: "Waddist mag mij contacteren",
  yourMailOrPhone: "Je e-mailadres of gsm-nummer",
  fillInOneOfTwo: "Vul één van de twee",
  provideValidMail: "Geef een geldig emailadres in",
  yourPhoneNumber: "Gsm-nummer",
  provideValidPhone: "Geef een geldig telefoonnummer in",
  noResultsForToday: "Geen resultaten voor vandaag",
  settingsTitle: "Instellingen",
  userDataTitle: "Werk gebruikersinformatie bij",
  privacyStatementTitle: "Privacy statement",
  tAndCTitle: "Terms and conditions",
  standardColorTitle: "Zet standaard kleur terug",
  logoutTitle: "Log out",
  language: "Language"
}
