export const normalizeFontSize = (size: number) => {
    const width = window.innerWidth
    const height = window.innerHeight
    const scale = width / 375
    const newSize = size * scale

    const outputSize = Math.round(window.devicePixelRatio * newSize)

    return outputSize
}

const isBigScreen = () => window.innerWidth >= 1824
const isDesktop = () => window.innerWidth >= 992 && window.innerWidth < 1824
const isTablet = () => window.innerWidth >= 768 && window.innerWidth < 992
const isLandscape = () => !window.matchMedia('(orientation: portrait)').matches
const isMobile = () => window.innerWidth < 768
const isRetina = () => window.devicePixelRatio >= 2

export const pageNormalFontSize = isMobile()
    ? normalizeFontSize(6)
    : isBigScreen()
      ? normalizeFontSize(2.7)
      : isTablet()
        ? normalizeFontSize(3.4)
        : normalizeFontSize(3)

export const pageTitleFontSize = isMobile()
    ? normalizeFontSize(7)
    : isBigScreen()
      ? normalizeFontSize(3)
      : isTablet()
        ? normalizeFontSize(3.6)
        : normalizeFontSize(3.3)

export const navBarNormalFontSize = isMobile()
    ? normalizeFontSize(6)
    : isBigScreen()
      ? normalizeFontSize(2.3)
      : isTablet()
        ? normalizeFontSize(3.4)
        : normalizeFontSize(3)

export const navBarTitleFontSize = isMobile()
    ? normalizeFontSize(7)
    : isDesktop()
      ? normalizeFontSize(4)
      : isBigScreen()
        ? normalizeFontSize(3)
        : normalizeFontSize(2)

export const cardNormalFontSize = isMobile()
    ? normalizeFontSize(7)
    : isDesktop()
      ? normalizeFontSize(4)
      : isBigScreen()
        ? normalizeFontSize(3)
        : normalizeFontSize(2)

export const cardTitleFontSize = isMobile()
    ? normalizeFontSize(7)
    : isDesktop()
      ? normalizeFontSize(4)
      : isBigScreen()
        ? normalizeFontSize(3)
        : normalizeFontSize(2)

export const badgesNumbersFontSize = isMobile()
    ? normalizeFontSize(4.5)
    : isDesktop()
      ? normalizeFontSize(1.6)
      : isBigScreen()
        ? normalizeFontSize(1.4)
        : isTablet()
          ? normalizeFontSize(2.4)
          : isLandscape()
            ? normalizeFontSize(1.1)
            : normalizeFontSize(2)

export const badgesTextFontSize = isMobile()
    ? normalizeFontSize(2.4)
    : isDesktop()
      ? normalizeFontSize(1)
      : isBigScreen()
        ? normalizeFontSize(1.2)
        : isTablet()
          ? normalizeFontSize(1.8)
          : normalizeFontSize(1.6)

export const fontSize = isMobile()
    ? normalizeFontSize(7)
    : isDesktop()
      ? normalizeFontSize(4)
      : isBigScreen()
        ? normalizeFontSize(3)
        : normalizeFontSize(2)

export const fontTitleSize = isMobile()
    ? normalizeFontSize(7)
    : isDesktop()
      ? normalizeFontSize(4.5)
      : isBigScreen()
        ? normalizeFontSize(3)
        : normalizeFontSize(2)

export const cardButtonFontSize = isMobile()
    ? normalizeFontSize(5)
    : isDesktop()
      ? normalizeFontSize(4)
      : isBigScreen()
        ? normalizeFontSize(3)
        : normalizeFontSize(5)

export const iconSize = isMobile() ? 32 : isDesktop() ? 45 : isBigScreen() ? 47 : 30

export const styles: { [key: string]: React.CSSProperties } = {
    card: {
        borderRadius: 0,
        marginTop: 10,
        marginBottom: 10,
        padding: 15,
        boxShadow: '0px 1px 2.22px rgba(0, 0, 0, 0.22)',
    },
    btnViewContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        padding: 0,
        marginTop: 20,
    },
    checkboxLabelContainer: {
        padding: '0 15px',
    },
    answerTouchContainer: {
        boxShadow: 'none',
        display: 'flex',
        flexDirection: 'row',
        padding: '0 0 0 10px',
        alignItems: 'center',
    },
    answerCheckboxContainer: {
        width: 30,
        height: 30,
        marginRight: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    answerTextContainer: {
        padding: '0 15px',
    },
    ctaCardContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        marginTop: 10,
        alignContent: 'center',
        padding: 0,
        borderRadius: 0,
        borderWidth: 0,
    },
    logoutButton: {
        padding: 2,
        fontSize: navBarNormalFontSize,
        width: 110,
        textAlign: 'right',
        textDecoration: 'none',
    },
    navButton: {
        padding: isMobile() ? 4 : 3,
        fontSize: navBarNormalFontSize,
    },
    pageButton: {
        margin: 15,
        minWidth: isMobile() ? '75%' : '50%',
        backgroundColor: 'rgba(30,30,30,.3)',
        fontSize: navBarNormalFontSize,
    },
    navBarUlElement: isMobile() ? { marginRight: 1.9, marginTop: 15 } : { margin: '1%', border: 0 },
}
