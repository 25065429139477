import { StyleSheet, View, Modal } from 'react-native-web'
import Svg, { G, Path } from 'react-native-svg-web'
import useColorScheme from '../../hooks/useColorScheme'
import Logo from '../Logo'
export default function Loadingscreen() {
    const { getByStyleProp } = useColorScheme()
    const bgArray = getByStyleProp('appBackground')
    const newAppBackground = Array.isArray(bgArray) ? `linear-gradient(to left, ${bgArray.join(', ')})` : bgArray

    const backgroundColor = getByStyleProp('appBackground')
    const styles = StyleSheet.create({
        modalBackground: {
            flex: 1,
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'space-around',
            backgroundImage: newAppBackground ? newAppBackground : '#FFFFFF',
        },
        activityIndicatorWrapper: {
            backgroundImage: newAppBackground ? newAppBackground : '#FFFFFF',
            height: '100%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
        },
    })
    return (
        <Modal transparent={true} animationType={'none'} visible={true} onRequestClose={() => {}}>
            <View style={styles.modalBackground}>
                <View style={styles.activityIndicatorWrapper}>
                    <Logo type="start" />
                </View>
            </View>
        </Modal>
    )
}
