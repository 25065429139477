import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
// import { getLocales } from 'expo-localization';

import en from './locales/en'
import nl from './locales/nl'
import fr from './locales/fr'
import cs from './locales/cs'

i18n.use(initReactI18next)
    .use(LanguageDetector)
    .init({
        resources: {
            en: { translation: en },
            nl: { translation: nl },
            fr: { translation: fr },
            cs: { translation: cs },
        },
        // lng: 'en', // default language
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
        //@ts-ignore
        detection: {
            order: ['navigator', 'querystring'],
            // {'querystring' | 'cookie' | 'sessionStorage' | 'sessionStorage' | 'navigator' | 'htmlTag' }
        },
    })
export default i18n
