import React from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import { KEY_AUTH_ACCESS_TOKEN } from '../helpers/constants'
import QuesetionScreens from '../components/screens/QuestionScreen'
import { isAuthenticated, logout } from '../helpers/keycloakAuth'
import log from 'loglevel'

interface ProtectedRouteProps {
    path: string
    children: React.ReactNode
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
    const checkingToken = (): boolean => {
        const token = sessionStorage.getItem(KEY_AUTH_ACCESS_TOKEN)
        if (token) return true
        else return false
    }
    try {
        if (checkingToken() && isAuthenticated())
            return (
                <>
                    <QuesetionScreens>{children}</QuesetionScreens>
                    <Outlet />
                </>
            )
        return <Navigate to="/" replace />
    } catch (error) {
        log.error('error in protected route ', error)
        logout()
    }
}
