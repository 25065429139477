import { env } from '../constants/EnvVars'
import { useTenant } from '../hooks/tenantContext'

export type envVar = {
    baseUrl: string
    apiSuffix: string
    oidc_clientId: string
    oidc_realm: string
    oidc_redirectUrl: string
    autoDiscoverUrl: string
    oidc_clientSecret?: string
    bare_realm?: string
    kc_url?: string
}

export type envVars = {
    dev: envVar
    staging: envVar
    prod: envVar
}

type useVars = {
    getEnvVars: (_env?: string) => envVar
    setEnvVars: (envVars: envVar) => void
}

let ENV: envVar

export const getEnvVars = (): envVar => {
    if (ENV) {
        return ENV
    } else {
        return env
    }
}

export const setEnvVars = (envVar: envVar): void => {
    ENV = envVar
}

export default function useVars(envVar?: envVar): useVars {
    if (envVar) {
        ENV = envVar
    }

    const { tenant } = useTenant()

    if (tenant && tenant.tenant.slug) {
        ENV.baseUrl = tenant.tenant.baseUrl!
        ENV.apiSuffix = tenant.tenant.apiSuffix!
        ENV.oidc_clientId = tenant.tenant.oidc_clientId!
        ENV.oidc_realm = tenant.tenant.oidc_realm!
        ENV.oidc_redirectUrl = env.oidc_redirectUrl
        ENV.autoDiscoverUrl = tenant.tenant.autoDiscoverUrl!
    } else {
        ENV = env
    }

    return {
        getEnvVars,
        setEnvVars,
    }
}
