import { BaseRepository, repoParams } from './baseRepository'
import { UserModel } from '../models/userModel'

export default class AuthRepository extends BaseRepository<UserModel> {
  id = 'authrepo'
  endpoint= '/auth/'
  entityModel= UserModel
  publicGet= false

  async me () : Promise<{result: UserModel, params: repoParams }> {
    const result = await this.getSingle({ id: 'me' })
    return result
  }

}
