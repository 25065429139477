export default {
    startScreen: "Partagez votre opinion de manière anonyme et découvrez ce que pensent d'autres collègues",
    userInfoTitle: '2 petites questions',
    userInfoIntro:
        "AskForceOne utilise uniquement ces informations pour voir ce que pensent les collègues de votre entreprise.\n\nL'application ne regarde pas les utilisateurs individuels et ne partage pas ces informations.",
    userInfoBtnLabel: 'Prêt!',
    bottomTabHome: 'Accueil',
    bottomTabResults: 'Réponses',
    bottomTabBadges: 'Insignes',
    bottomTabFeedback: 'Commentaires',
    thxAnswers: 'Merci pour vos réponses',
    nextQuestionsOn: ', les questions suivantes apparaîtront sur ',
    atThisScheduleTime: ' a 17:00',
    readTipBtn: 'Lire le conseil',
    watchTipBtn: 'Regarder la vidéo',
    CtaResults: "Voir ce que d'autres collègues ont répondu aux questions précédentes",
    CtaFeedback: "Quelle question voudriez-vous voir dans l'application?",
    CtaNotifications1: 'Vos notifications sont désactivées. Souhaitez-vous ',
    CtaNotifications2: 'recevoir une notification',
    CtaNotifications3: ' quand il y a de nouvelles questions ? \n\n Vous pouvez toujours modifier cela dans les paramètres.',
    BadgeScreenTitle: 'On fait bien!',
    ResultScreenTitle: 'Réponses aux questions précédentes',
    FeedbackScreenTitle: 'Commentaires',
    FeedbackScreenIntro:
        "Avez-vous des conseils ou des questions sur l'application?Vous pouvez donner votre avis pendant la phase de test",
    NotificationsDisabled: 'Les notifications pour cette application sont désactivées dans les paramètres de votre système.',
    NotificationsAllowed: "AskForceOne peut m'envoyer des notifications",
    NotificationsSettingsTitle: 'Notifications',
    NotificationsSettingsLink: 'Activez cette option dans les paramètres de votre téléphone.',
    OfflineTitle: 'AskForceOne est hors ligne',
    OfflineText1: 'Mettre votre ',
    OfflineText2: 'wifi ou données éteint et rallumé',
    OfflineText3: ', et essayez à nouveau.',
    OfflineText4: 'Toujours pas résolu?',
    OfflineText5: "Ensuite, l'application elle-même sera hors ligne et nous travaillons déjà dur pour la remettre en ligne.",
    OfflineCta1: 'Revenez plus tard',
    OfflineCta2: 'Vous saurez alors immédiatement quand AskForceOne sera de nouveau en ligne.',
    OfflineCtaLinkText: 'suivez Inuits sur Twitter.',
    OfflineCtaLink: 'https://twitter.com/inuits',
    OfflineButton: 'Réessayer',
    tipRead: 'Lire',
    tipTitle: 'Conseil du \njour',
    feedbackMsg: 'Merci pour vos commentaires',
    BadgeColorInfoTitle: "Vous voulez changer les couleurs dans l'application?\n",
    BadgeColorInfo: "Cliquez sur l'un des insignes que vous avez déjà obtenus.",
    BadgeColorInfoTitleNotReached: 'Que puis-je faire avec ces insignes?\n\n',
    BadgeColorInfoNotReached1: 'Avec un insigne ',
    BadgeColorInfoNotReached2: 'vous pouvez',
    BadgeColorInfoNotReached3: ' changer ',
    BadgeColorInfoNotReached4: 'les couleurs',
    BadgeColorInfoNotReached5:
        " dans l'application. Une fois que vous avez obtenu un badge, cliquez dessus et le tour est joué! Pouvez-vous collecter toutes les couleurs?",
    next: 'Prochain',
    nextQuestion: 'Question suivante',
    questionProposedBy: 'question posée par ',
    shareAnonymously: 'partager anonymement sur les réseaux sociaux ',
    questionsAnsweredText: 'questions répondues',
    tipsViewed: 'conseils consultés',
    day: 'jour',
    days: 'jours',
    currentStreak: 'Série de',
    badgesEarned: 'Insignes obtenus',
    achievable: 'Reste à obtenir',
    appNeedsUpdate: "L'application doit être mise à jour.",
    whatHappensWithMyAnswers: "Qu'advient-il de mes réponses?",
    badgesAlmostAchieved: 'Ces badges sont presque obtenus',
    extraInfo: 'Informations supplémentaires ou aide',
    seeAllInfoLines: "Voir toutes les lignes d'information pour les jeunes",
    askThroughChatMailPhone: 'Posez votre question par chat, courrier, téléphone ou rendez-nous visite.',
    turnOn: 'allumer',
    no: 'Non',
    questionProposal1: 'Soumettre la question',
    questionProposal2: "Que voudriez-vous demander à d'autres collègues?",
    questionProposal3: 'Vous voulez dire autre chose?',
    questionProposal4: 'Soumettez votre question',
    tipProposal1: 'Soumettre un conseil',
    tipProposal2: "Quel conseil voudriez-vous transmettre à d'autres collègues?",
    tipProposal3: 'Avez-vous autre chose à dire à propos de ce conseil?',
    tipProposal4: ' Soumettre le conseil',
    general1: "Vous cherchez de l'aide?",
    general2: 'Que voulez-vous demander aux créateurs de AskForceOne?',
    general3: 'Votre question ou suggestion',
    general4: 'Soumettre la question ou la suggestion',
    bugReport1: "Signaler une erreur dans l'application",
    bugReport2: '',
    bugReport3: "Qu'est-ce qui ne va pas dans l'application?",
    bugReport4: 'Signaler la erreur',
    yourQuestion: 'Votre question',
    possibleAnswers: 'Réponses possibles à votre question',
    answerToYourQuestion: 'Réponse ',
    addAnswer: 'Ajouter une réponse',
    maxOf6Answers: 'Il y a un maximum de 6 réponses',
    linkToTip: 'Lien vers le conseil',
    yourName: 'Votre nom',
    myNameCanBeShown: 'Mon nom peut être mentionné.',
    anonymous: 'Envoyer anonymement',
    canContactMe: 'AskForceOne peut me contacter',
    yourMailOrPhone: 'Votre adresse e-mail ou numéro de téléphone',
    fillInOneOfTwo: "Remplissez l'un des deux",
    provideValidMail: "S'il vous plaît, mettez une adresse email valide",
    yourPhoneNumber: 'Numéro de téléphone',
    provideValidPhone: "S'il vous plaît entrer un numéro de téléphone valide",
    noResultsForToday: "Aucun résultat pour aujourd'hui",
    settingsTitle: 'Paramètres',
    userDataTitle: "Mettre à jour les informations de l'utilisateur",
    privacyStatementTitle: 'Déclaration de confidentialité',
    tAndCTitle: 'Termes et conditions',
    standardColorTitle: 'Restaurer les couleurs par défaut',
    logoutTitle: 'Se déconnecter',
    answersText: 'réponses',
    weightedBasedOn: 'Pondéré sur la base de ',
    checkBadges: 'Voir insignes ',
    badgeTitleWelcome: 'Bienvenu',
    badgeTitleQuestions: 'Réponses aux questions',
    badgeTitleTips: 'Conseils consultés',
    badgeTitleStreak: 'Série',
    thxFeedbackQuestion1: 'Merci pour votre question',
    thxFeedbackTip1: 'Merci pour votre conseil',
    thxFeedbackGeneral1: 'Merci pour votre question ou suggestion',
    thxFeedbackBug1: "Merci d'avoir signalé une erreur",
    thxFeedbackQuestion2: "Vous le verrez peut-être bientôt dans l'application!",
    thxFeedbackTip2: "Vous le verrez peut-être bientôt dans l'application!",
    thxFeedbackGeneral2: "On va s'y mettre bientôt!",
    thxFeedbackBug2: "De cette façon, vous contribuez à améliorer l'application!",
    disclaimer1: "Pour votre information: pour utiliser l'application, vous devez accepter la",
    disclaimer2: "conditions d'utilisation ",
    disclaimer3: 'et la',
    disclaimer4: 'politique de confidentialité',
    disclaimer5: ' de AskForceOne.',
    disclaimer6: "Êtes-vous un pingouin? Ensuite, l'un de vos aidants doit donner son autorisation.",
    disclaimer7: 'Vous recevrez ',
    disclaimer8: "plus d'informations à ce sujet à l'étape suivante,",
    disclaimer9: ' avant de créer un compte.',
    privacyPolicyLink: 'https://askforce.one/privacy-policy/',
    //-------------
    contactedByApp: '',
    activationChoise: "Vous pouvez choisir d'utiliser le code d'activation",
    enterCodePlaceholder: "Entrez un code d'activation ici!",
    activate: 'Activer',
    scanQRCode: 'Scanner le code QR',
    stopCamera: 'Arrêter la caméra',
    start: 'Démarrer',
    or: 'ou',
    timer: "Définir l'heure d'expiration de la connexion.",
    timerReset: 'Réinitialiser le minuteur',
    useLinkToLogin: 'Utilisez le lien que vous avez reçu dans votre e-mail pour démarrer le processus de connexion.',
}
