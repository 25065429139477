import { RepositoryFactory } from '../repositories/repositoryFactory'
import { interceptorsType } from '../repositories/baseApiRepository'
import axios, { AxiosRequestConfig } from 'axios'
import { KEY_AUTH_REFRESH_TOKEN } from '../helpers/constants'
import OidcRepository, { ocidTokens } from '../repositories/OidcRepository'

export const buildRequestTokenInterceptorCallback: interceptorsType['request'] = async (config: AxiosRequestConfig) => {
    return config
}

export const buildResponseErrorInterceptorCallback: interceptorsType['response'] = async (errorVm: any) => {
    // Only intercept 401 unauthorized calls
    if (errorVm.response && errorVm.response.status && errorVm.response.status === 401) {
        try {
            // Refresh tokens and retry call
            const refreshToken: string | null = sessionStorage.getItem(KEY_AUTH_REFRESH_TOKEN)
            return (
                refreshToken &&
                RepositoryFactory.get(OidcRepository)
                    .refreshTokensFromServer(refreshToken)
                    .then((newTokens: ocidTokens | void) => {
                        if (newTokens && newTokens.access) {
                            errorVm.response.config.headers.Authorization = `Bearer ${newTokens.access}`
                        }
                        return new Promise((resolve, reject) => {
                            axios
                                .request(errorVm.response.config)
                                .then((response) => {
                                    resolve(response)
                                })
                                .catch((error) => {
                                    reject(error)
                                })
                        })
                    })
            )
        } catch (e) {
            return Promise.reject(errorVm)
        }
    }

    return Promise.reject(errorVm)
}
