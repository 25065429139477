export default {
    startScreen: 'Sdílejte anonymně své názory a zjistěte, co si myslí ostatní kolegové',
    userInfoTitle: '2 rychlé otázky',
    userInfoIntro:
        'AskForceOne aplikace tuhle informaci použije pouze k tomu, aby viděla, co si ostatní kolegové ve vaší společnosti myslí.\n\nAplikace nespojuje tyto informace s jedotlivými uživateli, ani je nesdílí s nikým dalším.',
    userInfoBtnLabel: 'Ready!',
    bottomTabHome: 'Domů',
    bottomTabResults: 'Odpovědi',
    bottomTabBadges: 'Odznaky',
    bottomTabFeedback: 'Zpětná vazba',
    thxAnswers: 'Děkujeme za Vaši odpověď',
    nextQuestionsOn: ', další otázka se objeví ',
    atThisScheduleTime: ' v 17:00',
    readTipBtn: 'Přečíst tipy',
    watchTipBtn: 'Podívat se na video',
    CtaResults: 'Podívejte se, jak na předchozí otázky odpovídali kolegové',
    CtaFeedback: 'Jakou otázku byste rádi položili?',
    CtaNotifications1: 'Vaše upozornění jsou vypnutá. Chtěli byste ',
    CtaNotifications2: 'dostat upozornění ',
    CtaNotifications3: 'pokud se objeví nová otázka? \n\nVždy můžete toto změnit v nastavení.',
    BadgeScreenTitle: 'Vedete si skvěle!',
    ResultScreenTitle: 'Odpovědi na předchozí otázky',
    FeedbackScreenTitle: 'Zpětná vazba',
    FeedbackScreenIntro: 'Máte nějaké tipy nebo otázky ohledně aplikace?\nMůžete dát zpětnou vazbu v průběhu testování',
    NotificationsDisabled: 'Upozornění pro tuto aplikaci jsou vypnuta ve Vašem nastavení systému.',
    NotificationsAllowed: 'AskForceOne mi může posílat upozornění',
    NotificationsSettingsTitle: 'Upozornění',
    NotificationsSettingsLink: 'Zapněte v nastavení telefonu.',
    OfflineTitle: 'AskForceOne je offline',
    OfflineText1: 'Zapněte a vypněte ',
    OfflineText2: 'wifi nebo mobilní data',
    OfflineText3: ' a zkuste to znova.',
    OfflineText4: 'Stále to nefunguje?',
    OfflineText5: 'Potom je aplikace offline a my už pracujeme na tom, aby byla co nejdřív zpět online.',
    OfflineCta1: 'Prosím přijděte později',
    OfflineCta2: 'Poté budete ihned vědět, že je AskForceOne aplikace zpět online.',
    OfflineCtaLinkText: 'sledujte Inuits na Twitteru.',
    OfflineCtaLink: 'https://twitter.com/inuits',
    OfflineButton: 'Zkusit znova',
    tipRead: 'Číst',
    tipTitle: 'Tip na dnešní \nden',
    feedbackMsg: 'Děkujeme za zpětbou vazbu',
    BadgeColorInfoTitle: 'Chcete změnit barvu aplikace?\n',
    BadgeColorInfo: 'Klikněte na jeden ze získaných odznaků.',
    BadgeColorInfoTitleNotReached: 'Co mohu se získanými odznaky dělat?\n\n',
    BadgeColorInfoNotReached1: 'S odznaky ',
    BadgeColorInfoNotReached2: 'můžete',
    BadgeColorInfoNotReached3: ' změnit ',
    BadgeColorInfoNotReached4: 'barvy',
    BadgeColorInfoNotReached5: ' Vaší aplikace. Když získáte odznak, klikněte na něj a je to! Dokážete posbírat všechny barvy?',
    next: 'Další',
    nextQuestion: 'Další otázka',
    questionProposedBy: 'otázku položil/a ',
    shareAnonymously: 'sdílet anonymně na sociální síť ',
    questionsAnsweredText: 'zodpovězené otázky',
    tipsViewed: 'zhlédnuté tipy',
    day: 'den',
    days: 'dny',
    currentStreak: 'Streak po',
    badgesEarned: 'Získané odznaky',
    achievable: 'Stále k získání',
    appNeedsUpdate: 'Aplikace musí být aktualizována.',
    whatHappensWithMyAnswers: 'Co se stalo s mými odpověďmi?',
    badgesAlmostAchieved: 'Téměř získané odznaky',
    extraInfo: 'Extra info nebo pomoc',
    seeAllInfoLines: 'Podívejte se na všechny informační linky pro mládež',
    askThroughChatMailPhone: 'Položte své otázky přes chat, mail, telefon nebo nás navštivte.',
    turnOn: 'Zapnout ',
    no: 'Ne',
    questionProposal1: 'Položte otázku',
    questionProposal2: 'Na co byste se rádi zeptali Vašich kolegů?',
    questionProposal3: 'Chcete říci ještě něco?',
    questionProposal4: 'Položtit otázku',
    tipProposal1: 'Přidat tip',
    tipProposal2: 'Jaký tip chcete dát Svým kolegům?',
    tipProposal3: 'Chcete říci něco dalšího o tomto Vašem tipu?',
    tipProposal4: ' Přidat tip',
    general1: 'Potřebujete pomoct?',
    general2: 'Na co se chcete tvůrců AskForceOne zeptat?',
    general3: 'Your question or suggestionVaše otázky a návrhy',
    general4: 'Poslat otázku nebo návrh',
    bugReport1: 'Nahlásit chybu v aplikaci',
    bugReport2: '',
    bugReport3: 'Co je s aplikací špatně?',
    bugReport4: 'Nahlásit chybu',
    yourQuestion: 'Vaše otázka',
    possibleAnswers: 'Možné odpovědi na Vaši otázku',
    answerToYourQuestion: 'Odpověď ',
    addAnswer: 'Přidat odpověď',
    maxOf6Answers: 'Je možné zadat maximálně 6 odpovědí',
    linkToTip: 'Odkaz na tip',
    yourName: 'Vaše jméno (nepovinné)',
    myNameCanBeShown: 'Mé jméno může být zmíněno.',
    anonymous: 'Poslat anonymně',
    canContactMe: 'AskForceOne mě může kontaktovat na email nebo telefonicky',
    yourMailOrPhone: 'vložte e-mailovou adresu nebo telefonní číslo, pokud chcete dostat odpověď i takto.',
    fillInOneOfTwo: ' a my vás takto budeme kotaktovat také.',
    provideValidMail: 'Prosím zadejte platnou emailovou adresu',
    yourPhoneNumber: 'Telefonní číslo',
    provideValidPhone: 'Prosím zadejte platné telefonní číslo',
    noResultsForToday: 'Žádné výsledky pro dnešní den',
    settingsTitle: 'Nastavení',
    userDataTitle: 'Aktualizovat informace o uživateli',
    privacyStatementTitle: 'Prohlášení o ochraně soukromí',
    tAndCTitle: 'Pravidla a podmínky',
    standardColorTitle: 'Obnovit základní barvy',
    logoutTitle: 'Odhlásit se',
    answersText: 'krát zodpovězeno',
    weightedBasedOn: 'Zváženo na základě ',
    checkBadges: 'Prohlédnout si Vaše odznaky ',
    badgeTitleWelcome: 'Vítejte',
    badgeTitleQuestions: 'Zodpovězené otázky',
    badgeTitleTips: 'Zobrazení tipů',
    badgeTitleStreak: 'Streak',
    thxFeedbackQuestion1: 'Děkujeme za Vaši otázku',
    thxFeedbackTip1: 'Děkujeme za Váš tip',
    thxFeedbackGeneral1: 'Děkujeme za Vaši otázku nebo návrh',
    thxFeedbackBug1: 'Děkujeme za nahlášení chyby',
    thxFeedbackQuestion2: 'V aplikaci brzy k vidění!',
    thxFeedbackTip2: 'V aplikaci brzy k vidění!',
    thxFeedbackGeneral2: 'Brzy na tom začneme pracovat!',
    thxFeedbackBug2: 'Takto můžete pomoci zlepšit aplikaci!',
    disclaimer1: 'Pro Vaši informaci: pro používání aplikace musí souhlasit s',
    disclaimer2: 'podmínkami použití ',
    disclaimer3: 'a také se ',
    disclaimer4: 'zásadami ochrany osobních údajů',
    disclaimer5: ' aplikace AskForceOne.',
    disclaimer6: 'Jsi tučňák? Tak ti někdo z podpory musí dát přístup.',
    disclaimer7: 'Další informace o tomto získáš ',
    disclaimer8: 'v dalším kroku,',
    disclaimer9: ' před tím, než si založíš účet.',
    language: 'Jazyk',
    privacyPolicyLink: 'https://askforce.one/privacy-policy/',
    contactedByApp: 'Budete kontaktováni pomocí aplikace a, pokud si tak přejete, ',
    //---------------
    activationChoise: 'Můžete si vybrat mezi použitím aktivačního kódu',
    enterCodePlaceholder: 'Sem zadejte aktivační kód!',
    activate: 'Aktivovat',
    scanQRCode: 'Skenovat QR kód',
    stopCamera: 'Zastavit kameru',
    start: 'Start',
    or: 'nebo',
    timer: 'Nastavit čas vypršení platnosti přihlášení',
    timerReset: 'Resetovat časovač',
    useLinkToLogin: 'Pro zahájení přihlašovacího procesu použijte odkaz, který jste obdrželi do vaší e-mailové schránky.',
}
