import log from 'loglevel'
import { AppScheduleBlockRepositoryInterface } from '../../helpers/interfaces/baseRepositoryInterface'
import AppScheduleBlockModel from '../../models/entities/AppScheduleBlockModel'
import ScheduleBlockModel from '../../models/entities/scheduleBlocksModel'
import { BaseRepository, repoParams } from '../baseRepository'

export class AppScheduleBlockRepository
    extends BaseRepository<AppScheduleBlockModel>
    implements AppScheduleBlockRepositoryInterface<AppScheduleBlockModel>
{
    id = '/app_schedule_blocks/'
    endpoint = '/schedule_blocks/'
    entityModel = AppScheduleBlockModel
    publicGet = false

    async respond(params: repoParams): Promise<{ result: AppScheduleBlockModel; params: repoParams }> {
        const model: AppScheduleBlockModel | undefined =
            (params.model as AppScheduleBlockModel).serializeForRespond !== undefined
                ? (params.model as AppScheduleBlockModel)
                : undefined

        const response = await this.post(this.endpoint + '' + params.id + '/respond/', model && model.serializeForRespond())
        return { result: this.entityModel.deserialize(response), params: params }
    }

    async tipRead(params: repoParams, tip_instance_id?: string): Promise<{ result: AppScheduleBlockModel; params: repoParams }> {
        const response = await this.post(this.endpoint + '' + params.id + '/tip_read/', null, { params: { tip_instance_id } })
        return { result: this.entityModel.deserialize(response), params: params }
    }

    async checkBlockId(): Promise<null | string> {
        const response = await this.get(this.endpoint + 'active_date/', {})
        return response.schedule_block
    }

    async getSingle(params: repoParams): Promise<{ result: AppScheduleBlockModel; params: repoParams }> {
        let currentEndPoint: string = this.endpoint

        if (params.id === 'active/') {
            currentEndPoint = currentEndPoint + 'active/?lang=' + params.lang
        }

        return this.get(currentEndPoint, {}, this.publicGet).then((response: any) => {
            log.debug('inside get schedule block : ', response)
            return { result: this.entityModel.deserialize(response), params: params }
        })
    }
}
