import { useEffect, useState } from 'react'
import FeedbackModel, { FeedbackScreenTypes } from '../../models/entities/feedbackModel'
import { FeedbackRepository } from '../../repositories/entities/feedbackRepository'
import useBaseRepository from '../../hooks/useRepository'
import { useForm, useFieldArray, useFormContext, RegisterOptions, FieldValues, Path, FormProvider } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import i18n from '../../i18n/i18n'
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber'
import { Text, View, ActivityIndicator } from 'react-native-web'
import { BiSolidMinusCircle } from 'react-icons/bi'
import { normalizeFontSize, pageNormalFontSize } from '../../constants/Layout'
import useColorScheme from '../../hooks/useColorScheme'
import useResponsiveStates from '../../hooks/useResponsiveStates'
import log from 'loglevel'
import { CODES } from '../../helpers/constants'

export const FeedbackScreenTypesTitles = {
    QUESTIONPROPOSAL: i18n.t('questionProposal1'),
    TIPPROPOSAL: i18n.t('tipProposal1'),
    GENERAL: i18n.t('general1'),
    BUGREPORT: i18n.t('bugReport1'),
} as const

export const FeedbackScreenSubTitles = {
    QUESTIONPROPOSAL: i18n.t('questionProposal2'),
    TIPPROPOSAL: i18n.t('tipProposal2'),
    GENERAL: i18n.t('general2'),
    BUGREPORT: i18n.t('bugReport2'),
} as const

export const FeedbackScreenRemarksTitles = {
    QUESTIONPROPOSAL: i18n.t('questionProposal3'),
    TIPPROPOSAL: i18n.t('tipProposal3'),
    GENERAL: i18n.t('general3'),
    BUGREPORT: i18n.t('bugReport3'),
} as const

export const FeedbackScreenSubmitTitles = {
    QUESTIONPROPOSAL: i18n.t('questionProposal4'),
    TIPPROPOSAL: i18n.t('tipProposal4'),
    GENERAL: i18n.t('general4'),
    BUGREPORT: i18n.t('bugReport4'),
} as const

type InputType = 'text' | 'textarea' | 'email' | 'number' | 'checkbox'

interface FormInputProps<TFieldValues extends FieldValues> {
    name: Path<TFieldValues>
    label: string
    errorMsg: string
    type?: InputType
    rules?: RegisterOptions<TFieldValues, Path<TFieldValues>> // Make sure to define the rules with the generic types
}

function FormInput<TFieldValues extends FieldValues>({
    name,
    label,
    errorMsg,
    type = 'text', // Only default to 'text' if type is not provided
    rules,
}: FormInputProps<TFieldValues>) {
    const {
        register,
        formState: { errors },
    } = useFormContext<TFieldValues>()

    return (
        <>
            {type === 'textarea' && (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', margin: 5 }}>
                    <label htmlFor={name} style={{ textAlign: 'left', fontSize: pageNormalFontSize }}>
                        {label}
                    </label>
                    <textarea
                        id={name}
                        {...register(name as Path<TFieldValues>, rules)}
                        aria-multiline={true}
                        aria-expanded={true}
                        style={{ width: '100%' }}
                        className="form-control"
                    />
                    {errors[name as Path<TFieldValues>] && <p className="text-danger">{errorMsg}</p>}
                </div>
            )}
            {type === 'text' && (
                <div style={{ display: 'flex', flexDirection: 'column', margin: 5, alignItems: 'flex-start', flexGrow: 1 }}>
                    <label htmlFor={name} style={{ fontSize: pageNormalFontSize }}>
                        {label}
                    </label>
                    <input
                        id={name}
                        {...register(name as Path<TFieldValues>, rules)}
                        style={{ width: '100%', boxSizing: 'border-box' }}
                        aria-expanded={true}
                        className="form-control"
                    />
                    {errors[name as Path<TFieldValues>] && <p className="text-danger">{errorMsg}</p>}
                </div>
            )}
            {type === 'checkbox' && (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexGrow: 1 }}>
                    <label htmlFor={name} style={{ fontSize: pageNormalFontSize }}>
                        {label}
                    </label>
                    <input
                        id={name}
                        {...register(name as Path<TFieldValues>, rules)}
                        type="checkbox"
                        className="form-check-input"
                    />
                    {errors[name as Path<TFieldValues>] && <p className="text-danger">{errorMsg}</p>}
                </div>
            )}
        </>
    )
}

interface QuestionFeedbackScreenProps {
    screenType?: keyof typeof FeedbackScreenTypes
}

function QuestionFeedbackScreen({ screenType }: QuestionFeedbackScreenProps) {
    const navigation = useNavigate()
    const { isMobile } = useResponsiveStates()
    const { getByStyleProp } = useColorScheme()
    const [countryCode, setCountryCode] = useState<string>('BE')
    const [loading, setLoading] = useState<boolean>(false)
    const methods = useForm({
        defaultValues: {
            email: '', // your default email value
            answers: [{ label: '' }],
            contactInfo: false, // make sure this is defined
            gsm: '',
            anonimous: true,
        },
    })

    const type: FeedbackScreenTypes = FeedbackScreenTypes[screenType ? screenType : 'QUESTION']

    const {
        handleSubmit,
        control,
        watch,
        formState: { errors },
    } = methods
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'answers',
    })

    const watchContactInfo = watch('contactInfo')

    const { doCall: postFeedback } = useBaseRepository<FeedbackModel>(FeedbackRepository, 'create')

    const onSubmit = async (data: any) => {
        let answers: string[] = []
        if (type === FeedbackScreenTypes.QUESTION) {
            data.answer.forEach((ans: any) => {
                answers.push(ans.label)
            })
        }

        const inputFeedback: any = {
            type: type,
            email: data.email,
            proposed_by: data.name,
            comment: data.remark,
            questionOrTip: data.questionOrTip,
            question_answers: answers,
            is_anonymous: data.anonymous ? data.anonymous : false,
        }

        if (data.gsm && data.gsm !== '') {
            const phone = await checkPhone(data.gsm)

            if (phone.isValid) {
                inputFeedback['phone_number'] = phone.number
            }
        }
        setLoading(true)
        postFeedback({
            model: FeedbackModel.deserialize(inputFeedback),
        })
            .then((model) => {
                navigation('/fb-result', { state: { success: type } })
                setLoading(false)
            })
            .catch((error) => {
                setLoading(false)
                log.error('Error: ', error.message)
            })
    }

    type phoneNumberType = {
        isValid: boolean
        number: string | undefined
    }

    const checkPhone = async (input: string): Promise<phoneNumberType> => {
        let validPhoneNumer: boolean = false
        let phoneNumber: string | undefined = undefined
        let googleLibInstance = PhoneNumberUtil.getInstance()
        let phoneInstance = googleLibInstance.parse(input, countryCode)
        phoneNumber = googleLibInstance.format(phoneInstance, PhoneNumberFormat.INTERNATIONAL)
        validPhoneNumer = googleLibInstance.isValidNumber(phoneInstance)
        return {
            isValid: validPhoneNumer,
            number: phoneNumber,
        }
    }
    const onOptionChangeHandler = (event: any) => {
        setCountryCode(event.target.value)
        console.log('User Selected Value - ', event.target.value)
    }
    const backgroundColor: string = getByStyleProp('cardBackground')

    const landCodes = () => {
        return CODES.map((option) => (
            <option key={option} value={option} selected={option === 'BE'}>
                {option}
            </option>
        ))
    }

    return (
        <div
            style={{
                minHeight: window.innerHeight,
                paddingBottom: 15,
                marginRight: 0,
                marginLeft: 0,
            }}
        >
            <FormProvider {...methods}>
                <div style={{ padding: 15 }}>
                    <div
                        style={{
                            marginTop: 5,
                            textAlign: 'center',
                            fontSize: pageNormalFontSize,
                        }}
                    >
                        {FeedbackScreenSubTitles[type]}
                    </div>
                </div>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    style={{
                        display: 'flex',
                        flexWrap: 'nowrap',
                        flexDirection: 'column',
                        backgroundColor: backgroundColor,
                        margin: 15,
                        boxShadow: '0 1px 5px rgba(0,0,0,0.4)',
                        border: 'none',
                    }}
                    className="img-thumbnail"
                >
                    <div style={{ padding: isMobile ? 0 : '3%' }}>
                        <div
                            style={{
                                fontFamily: 'Montserratbold',
                                marginTop: 10,
                                fontSize: pageNormalFontSize,
                            }}
                        >
                            {FeedbackScreenTypesTitles[type]}
                        </div>

                        {type === FeedbackScreenTypes.QUESTION && (
                            <View>
                                <FormInput
                                    name="email"
                                    label="Email"
                                    errorMsg="This field is required"
                                    rules={{ required: true }}
                                />

                                <FormInput
                                    label={i18n.t('yourQuestion')}
                                    name="questionOrTip"
                                    errorMsg="This field is required"
                                    rules={{ required: false }}
                                    type="textarea"
                                />

                                <div
                                    style={{
                                        fontFamily: 'Montserratmedium',
                                        marginLeft: 5,
                                        marginTop: 15,
                                        marginBottom: 10,
                                        textAlign: 'left',
                                        fontSize: pageNormalFontSize,
                                    }}
                                >
                                    {i18n.t('possibleAnswers')}
                                </div>
                                {fields.map((field, index) => (
                                    <div key={field.id} style={{ display: 'flex', alignItems: 'center' }}>
                                        <FormInput
                                            label={i18n.t('answerToYourQuestion') + (index + 1)}
                                            name={`answer[${index}].label`}
                                            errorMsg="This answer is required"
                                            rules={{ required: false }}
                                            type="text"
                                        />
                                        <BiSolidMinusCircle
                                            onClick={() => remove(index)}
                                            size={30}
                                            style={{ marginRight: 5, marginTop: 22 }}
                                        />
                                    </div>
                                ))}
                                <View
                                    parent={false}
                                    style={{
                                        flexDirection: 'row',
                                        paddingHorizontal: 10,
                                        paddingRight: 0,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    {fields.length < 6 ? (
                                        <button
                                            type="button"
                                            onClick={() => {
                                                append({ label: '' })
                                            }}
                                            className="btn btn-dark text-light"
                                            style={{ marginLeft: 'auto', marginRight: 5, marginTop: 10, marginBottom: 10 }}
                                        >
                                            {i18n.t('addAnswer')}
                                        </button>
                                    ) : (
                                        <div style={{ marginTop: 10, fontSize: normalizeFontSize(5), marginBottom: 10 }}>
                                            {i18n.t('maxOf6Answers')}
                                        </div>
                                    )}
                                </View>
                            </View>
                        )}
                        {type === FeedbackScreenTypes.TIP && (
                            <View parent={false} style={{ paddingHorizontal: 0 }}>
                                <FormInput
                                    label={i18n.t('linkToTip')}
                                    name="questionOrTip"
                                    errorMsg="This field is required"
                                    rules={{ required: false }}
                                />
                            </View>
                        )}

                        <FormInput
                            name="remark"
                            label={FeedbackScreenRemarksTitles[type]}
                            errorMsg="This field is required"
                            rules={{ required: false }}
                            type="textarea"
                        />

                        {(type === FeedbackScreenTypes.TIP ||
                            type === FeedbackScreenTypes.QUESTION ||
                            type === FeedbackScreenTypes.BUG) && (
                            <div
                                className="d-flex align-items-center justify-content-between"
                                style={{ marginTop: 15, marginBottom: 15, marginLeft: 5, marginRight: 5 }}
                            >
                                <FormInput
                                    label={i18n.t('anonymous')}
                                    name="anonymous"
                                    errorMsg={''}
                                    type="checkbox"
                                    rules={{ required: false }}
                                />
                            </div>
                        )}

                        {(type === FeedbackScreenTypes.TIP ||
                            type === FeedbackScreenTypes.QUESTION ||
                            type === FeedbackScreenTypes.BUG) && (
                            <FormInput label={i18n.t('yourName')} name="name" errorMsg="Error" rules={{ required: false }} />
                        )}

                        <div>
                            <div
                                className="d-flex align-items-center justify-content-between"
                                style={{ marginTop: 15, marginBottom: 15, marginLeft: 5, marginRight: 5 }}
                            >
                                <label htmlFor="contactInfo" style={{ textAlign: 'left', fontSize: pageNormalFontSize }}>
                                    {i18n.t('canContactMe')}
                                </label>
                                <input
                                    className="form-check-input"
                                    {...methods.register('contactInfo')}
                                    name="contactInfo" // This should come after to override any name set by methods.register
                                    type="checkbox"
                                />
                            </div>

                            {watchContactInfo && (
                                <View parent={false}>
                                    <div
                                        style={{
                                            textAlign: 'left',
                                            padding: 12,
                                            fontSize: pageNormalFontSize,
                                        }}
                                    >
                                        <Text style={{ fontFamily: 'Montserratlight', fontWeight: 'bolder' }}>
                                            {i18n.t('contactedByApp')}
                                        </Text>
                                        <Text style={{ fontFamily: 'Montserratlight', fontWeight: 'bolder' }}>
                                            {i18n.t('yourMailOrPhone')}
                                        </Text>
                                    </div>

                                    <FormInput
                                        label="E-mail"
                                        name="email"
                                        errorMsg={i18n.t('provideValidMail')}
                                        rules={{
                                            pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        }}
                                    />
                                    <div style={{ display: 'flex', alignItems: 'end' }}>
                                        <FormInput
                                            label={i18n.t('yourPhoneNumber')}
                                            name="gsm"
                                            errorMsg={i18n.t('provideValidPhone')}
                                            rules={{
                                                validate: async (value: any) => {
                                                    const validPhoneNumber = await checkPhone(value)
                                                    log.debug('PHONE VALIDATION ', validPhoneNumber)
                                                    return validPhoneNumber.isValid || value === ''
                                                },
                                            }}
                                        />
                                        <select
                                            onChange={onOptionChangeHandler}
                                            style={{
                                                width: 55,
                                                height: 38,
                                                fontFamily: 'Montserratbold',
                                                border: 0,
                                                borderRadius: 5,
                                                margin: 5,
                                                padding: 2,
                                            }}
                                        >
                                            {landCodes()}
                                        </select>
                                    </div>
                                </View>
                            )}
                        </div>
                        {loading ? (
                            <ActivityIndicator size="large" color={'green'} />
                        ) : (
                            <button
                                type="submit"
                                className="btn btn-dark text-light"
                                style={{ marginLeft: 'auto', marginRight: 5, marginBottom: 15 }}
                            >
                                {FeedbackScreenSubmitTitles[type]}
                            </button>
                        )}
                    </div>
                </form>
            </FormProvider>
        </div>
    )
}
export default QuestionFeedbackScreen
