import { logoTypes } from '../hooks/useLogo'
import { useTenant } from '../hooks/tenantContext'
import { useEffect, useState } from 'react'
import useResponsiveStates from '../hooks/useResponsiveStates'
import { LOGO_LINK, LOGO_SVG, TENANT_STORAGE_KEY } from '../helpers/constants'
import log from 'loglevel'
import TenantModel from '../models/entities/tenantModel'

const defaultLogo = (): string => {
    return `<svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 170.08 170.08"
    >
      <g transform="translate(0,0)">
        <g>
          <g>
            <g>
              <path fill="#FFFFFF" d="M75.22,62.74H70.7c-0.32,0-0.58-0.23-0.63-0.54l-0.69-4.63h-4.4l-0.69,4.63c-0.05,0.31-0.31,0.54-0.63,0.54
                h-4.11c-0.19,0-0.36-0.08-0.48-0.22c-0.12-0.14-0.17-0.33-0.14-0.51l4.56-28.49c0.05-0.31,0.32-0.54,0.63-0.54h6.55
                c0.31,0,0.58,0.23,0.63,0.54L75.85,62c0.03,0.18-0.02,0.37-0.14,0.51C75.58,62.65,75.41,62.74,75.22,62.74z M65.74,52.43h2.84
                l-1.42-9.48L65.74,52.43z"/>
            </g>
            <g>
              <path fill="#FFFFFF" d="M85.31,63.06c-4.65,0-7.31-2.84-7.31-7.8v-1.75c0-0.35,0.28-0.64,0.64-0.64h4.23
                c0.35,0,0.64,0.29,0.64,0.64v2.04c0,1.87,0.81,2.13,1.68,2.13c0.87,0,1.68-0.26,1.68-2.13c0-2.41-1.68-3.95-3.63-5.73
                c-2.4-2.2-5.12-4.68-5.12-9.37c0-5.03,2.57-7.8,7.23-7.8c4.66,0,7.23,2.77,7.23,7.8v0.9c0,0.35-0.28,0.64-0.64,0.64h-4.23
                c-0.35,0-0.64-0.29-0.64-0.64v-1.18c0-2-0.83-2.17-1.6-2.17c-0.78,0-1.6,0.17-1.6,2.17c0,2.41,1.68,3.95,3.63,5.73
                c2.4,2.2,5.12,4.68,5.12,9.37C92.62,60.22,89.95,63.06,85.31,63.06z"/>
            </g>
            <g>
              <path fill="#FFFFFF" d="M111.39,62.74h-4.6c-0.26,0-0.5-0.16-0.59-0.41l-3.86-10.05l-0.65,1.23v8.59c0,0.35-0.28,0.64-0.64,0.64
                h-4.48c-0.35,0-0.64-0.29-0.64-0.64V33.61c0-0.35,0.28-0.64,0.64-0.64h4.48c0.35,0,0.64,0.29,0.64,0.64v9.58l4.65-9.85
                c0.11-0.22,0.33-0.37,0.57-0.37h4.48c0.22,0,0.42,0.11,0.54,0.3c0.12,0.19,0.13,0.42,0.03,0.62l-6.1,12.45l6.12,15.53
                c0.08,0.2,0.05,0.42-0.07,0.59C111.8,62.63,111.6,62.74,111.39,62.74z"/>
            </g>
            <g>
              <path fill="#FFFFFF" d="M66.6,119.63c-4.88,0-7.68-2.94-7.68-8.06V96.27c0-5.12,2.8-8.06,7.68-8.06c4.88,0,7.68,2.94,7.68,8.06
                v15.31C74.28,116.69,71.48,119.63,66.6,119.63z M66.6,93.73c-0.86,0-1.74,0.27-1.74,2.24v15.9c0,1.98,0.88,2.24,1.74,2.24
                c0.86,0,1.74-0.27,1.74-2.24v-15.9C68.34,93.99,67.46,93.73,66.6,93.73z"/>
            </g>
            <g>
              <path fill="#FFFFFF" d="M93.34,119.3h-4.75c-0.3,0-0.56-0.2-0.63-0.48l-4.47-16.55v16.37c0,0.36-0.29,0.66-0.66,0.66h-4.16
                c-0.36,0-0.66-0.3-0.66-0.66V89.2c0-0.36,0.29-0.66,0.66-0.66h5.8c0.3,0,0.56,0.2,0.63,0.48l3.46,12.84V89.2
                c0-0.36,0.29-0.66,0.66-0.66h4.12c0.36,0,0.66,0.3,0.66,0.66v29.44C93.99,119,93.7,119.3,93.34,119.3z"/>
            </g>
            <g>
              <path fill="#FFFFFF" d="M111.37,119.3H98.75c-0.36,0-0.66-0.3-0.66-0.66V89.2c0-0.36,0.29-0.66,0.66-0.66h12.62
                c0.36,0,0.66,0.3,0.66,0.66v4.21c0,0.36-0.29,0.66-0.66,0.66h-7.34v6.89h5.69c0.36,0,0.66,0.3,0.66,0.66v4.21
                c0,0.36-0.29,0.66-0.66,0.66h-5.69v7.31h7.34c0.36,0,0.66,0.3,0.66,0.66v4.21C112.03,119,111.73,119.3,111.37,119.3z"/>
            </g>
          </g>
          <g>
            <g>
              <path fill="#FFFFFF" d="M76.25,163.72c-0.93,0-1.82-0.28-2.59-0.81L51.1,147.33l-6.29,0.66c-4.98,0.53-9.88-0.94-13.79-4.11
                c-3.91-3.17-6.35-7.66-6.87-12.64L14.28,37c-0.52-4.98,0.94-9.88,4.11-13.79c3.17-3.91,7.66-6.35,12.64-6.87l94.25-9.87
                c4.98-0.52,9.88,0.94,13.79,4.11c3.91,3.17,6.35,7.66,6.87,12.64l9.87,94.25c1.08,10.31-6.43,19.58-16.74,20.66l-59.79,6.27
                l1.5,14.32c0.18,1.76-0.68,3.48-2.2,4.39C77.86,163.5,77.06,163.72,76.25,163.72z M52.3,138.08c0.93,0,1.82,0.28,2.59,0.81
                l15.8,10.92l-0.94-9c-0.26-2.5,1.56-4.74,4.05-5l64.31-6.74c2.56-0.27,4.88-1.53,6.52-3.55c1.64-2.02,2.39-4.55,2.12-7.11
                l-9.87-94.25c-0.27-2.56-1.53-4.88-3.55-6.51c-2.03-1.64-4.55-2.39-7.11-2.13l-94.25,9.87c-2.56,0.27-4.88,1.53-6.51,3.55
                c-1.64,2.02-2.39,4.55-2.12,7.11l9.87,94.25c0.27,2.56,1.53,4.88,3.55,6.52c2.02,1.64,4.55,2.39,7.11,2.13l7.96-0.83
                C51.99,138.09,52.15,138.08,52.3,138.08z"/>
            </g>
          </g>
        </g>
        <g>
          <path fill="#FFFFFF" d="M49.13,71.61l10.22-1.07l0.14,1.31l-8.69,0.91l0.61,5.82l7.72-0.81l0.14,1.31l-7.72,0.81l0.81,7.67
            l-1.53,0.16L49.13,71.61z"/>
          <path fill="#FFFFFF" d="M67.96,69.29c5.05-0.53,8,3.2,8.46,7.6c0.46,4.4-1.64,8.66-6.7,9.19c-5.08,0.53-8.02-3.19-8.49-7.59
            C60.77,74.08,62.88,69.82,67.96,69.29z M69.58,84.77c4.24-0.45,5.67-4.2,5.3-7.72c-0.37-3.52-2.55-6.9-6.79-6.45
            c-4.26,0.45-5.69,4.2-5.32,7.72C63.14,81.84,65.32,85.21,69.58,84.77z"/>
          <path fill="#FFFFFF" d="M77.96,68.58l7.38-0.78c2.66-0.28,5.03,0.77,5.34,3.68c0.21,2.03-0.67,3.81-2.7,4.44l0,0.04
            c2.13,0.05,2.89,1.43,3.2,3.34c0.18,1.1,0.42,3.31,1.18,4.05l-1.69,0.18c-0.45-0.59-0.58-1.79-0.69-2.46
            c-0.37-2.24-0.82-4.73-3.72-4.31l-5.82,0.61l0.75,7.15l-1.53,0.16L77.96,68.58z M80.3,76.07l5.73-0.6c1.87-0.2,3.35-1.54,3.14-3.5
            c-0.21-1.96-1.51-3.08-3.81-2.84l-5.73,0.6L80.3,76.07z"/>
          <path fill="#FFFFFF" d="M105.29,70.63c-0.8-2.42-3.01-3.63-5.35-3.38c-4.26,0.45-5.69,4.2-5.32,7.72c0.37,3.52,2.55,6.89,6.81,6.45
            c2.98-0.31,4.63-2.81,4.62-5.71l1.53-0.16c-0.01,3.99-2.25,6.78-6.02,7.18c-5.08,0.53-8.02-3.19-8.49-7.59s1.64-8.66,6.72-9.19
            c3.05-0.32,6.26,1.19,7.02,4.53L105.29,70.63z"/>
          <path fill="#FFFFFF" d="M108.88,65.33L120,64.16l0.14,1.31l-9.59,1.01l0.61,5.82l8.98-0.94l0.14,1.31l-8.98,0.94l0.67,6.36l9.7-1.02
            l0.14,1.31l-11.24,1.18L108.88,65.33z"/>
        </g>
      </g>
    </svg>`
}

export type logoType = {
    type: logoTypes
}

const SvgFromUri = ({ uri, width, height }: { uri: string | null; width: number | string; height: number | string }) => {
    const adjustViewBox = (svgText: string) => {
        const parser = new DOMParser()
        const svgDoc = parser.parseFromString(svgText, 'image/svg+xml')
        const svgElement = svgDoc.querySelector('svg')
        const elementsWithFill = svgDoc.querySelectorAll('[fill]')
        function rgbToHex(rgb: any) {
            // Assuming the input is in the format: "rgb(r, g, b)"
            const [r, g, b] = rgb.match(/\d+/g).map(Number)

            // Convert each component to a two-digit hex value and concatenate
            return (
                '#' +
                [r, g, b]
                    .map((x) => {
                        const hex = x.toString(16)
                        return hex.length === 1 ? '0' + hex : hex
                    })
                    .join('')
            )
        }
        const backgroundColor = rgbToHex(window.getComputedStyle(document.body).backgroundColor)
        log.debug('background ', backgroundColor)
        elementsWithFill.forEach((element) => {
            if (backgroundColor.toUpperCase() === '#FFFFFF') element.removeAttribute('fill')
        })

        if (svgElement) {
            let viewBox = svgElement.getAttribute('viewBox')
            if (viewBox) {
                let [minX, minY, widthOrigin, heightOrigin] = viewBox.split(' ').map(Number)
                let w = window.innerWidth
                let h = window.innerHeight
                const deltaWidth = (w / parseFloat(width.toString())) * 2
                const deltaHeight = h / parseFloat(height.toString())

                widthOrigin -= parseFloat(width.toString()) / deltaWidth
                heightOrigin -= parseFloat(height.toString())

                svgElement.setAttribute('viewBox', [minX, minY, widthOrigin, heightOrigin].join(' '))
                svgElement.setAttribute('height', `${deltaHeight}`)
                svgElement.setAttribute('width', `${deltaWidth}`)
            }
            return svgDoc.documentElement.outerHTML
        }
        return svgText
    }

    const [svgContent, setSvgContent] = useState(adjustViewBox(defaultLogo()))

    useEffect(() => {
        if (uri) {
            let present_url = sessionStorage.getItem(LOGO_LINK)
            let logo = null
            if (uri === present_url) logo = sessionStorage.getItem(LOGO_SVG)
            logo
                ? setSvgContent(adjustViewBox(logo))
                : fetch(uri)
                      .then((response) => response.text())
                      .then((text) => {
                          setSvgContent(adjustViewBox(text))
                          sessionStorage.setItem(LOGO_SVG, text)
                      })
                      .catch(console.error)
        }
    }, [uri])

    return (
        <div
            style={{
                margin: 0,
                padding: 0,
            }}
            dangerouslySetInnerHTML={{ __html: svgContent }}
        />
    )
}

export default function Logo({ type = 'header' }: logoType) {
    // const { tenant } = useTenant()
    const tenantString = sessionStorage.getItem(TENANT_STORAGE_KEY)
    const tenant: TenantModel = tenantString ? JSON.parse(tenantString) : null
    // const [isLoaded, setIsLoaded] = useState(false);
    const { isMobile, isTablet } = useResponsiveStates()
    log.debug('TENANT from header ', tenant)
    log.debug('TENANT from header from storage', tenant)
    if (type === 'header') {
        if (tenant && tenant.logoUrl) {
            return <SvgFromUri width={isMobile ? 20 : 30} height={isMobile || isTablet ? 16 : 8} uri={tenant.logoUrl} />
        } else {
            return <SvgFromUri width={isMobile ? 20 : 30} height={isMobile || isTablet ? 16 : 8} uri={null} />
        }
    }

    if (type === 'start')
        if (tenant && tenant.logoUrl) {
            return <SvgFromUri width={3} height={4} uri={tenant.logoUrl} />
        } else {
            return <SvgFromUri width={3} height={4} uri={null} />
        }
}
