import { useEffect, useState } from 'react'
import BaseCard from './baseCard'
import { Text, View } from 'react-native-web'
import { useSchedule } from '../../hooks/scheduleContext'
import { LiaCheckSolid, LiaReadme } from 'react-icons/lia'
import useColorScheme from '../../hooks/useColorScheme'
import { Image } from 'react-native'
import { FileRepository } from '../../repositories/entities/fileRepository'
import { RepositoryFactory } from '../../repositories/repositoryFactory'
import i18n from '../../i18n/i18n'
import TipModel from '../../models/entities/tipModel'
import { IoTime } from 'react-icons/io5'
import useResponsiveStates from '../../hooks/useResponsiveStates'
import { pageNormalFontSize, pageTitleFontSize } from '../../constants/Layout'

type tipCardProps = {
    tip: TipModel
}

export const TipCard = ({ tip }: tipCardProps) => {
    const { scheduleBlock, tipRead } = useSchedule()
    const { getByStyleProp } = useColorScheme()
    const color: string = getByStyleProp('text')
    const disabled: string = getByStyleProp('btnDisabled')
    const [img, setimg] = useState<string>()
    const [imgId, setimgId] = useState<string>()
    const { isMobile } = useResponsiveStates()
    const readTip = (): void => {
        if (scheduleBlock.scheduleBlock.tipRead === false) {
            if (tip.has_read_tip === false) {
                tipRead(tip.id)
                tip.has_read_tip = true
            }
        }
        tip.link && window.open(tip.link, '_blank')
    }

    const buttonText = (): string => {
        return tip?.video ? i18n.t('watchTipBtn') : i18n.t('readTipBtn')
    }

    useEffect(() => {
        if (tip?.image && tip?.image !== imgId) {
            tip?.image && getFile(tip?.image)
        }

        if (!tip?.image) {
            setimg(undefined)
            setimgId(undefined)
        }
    }, [tip?.image])

    const getFile = (id: string) => {
        RepositoryFactory.get(FileRepository)
            .getFileInBase64(id)
            .then((img: string) => {
                setimg(img)
                setimgId(id)
            })
    }

    return tip ? (
        <BaseCard
            buttonLabel={tip.link ? buttonText() : undefined}
            route={readTip}
            title={
                <div style={{ flexDirection: 'column', alignItems: 'flex-start', display: 'flex', marginBottom: 15 }}>
                    <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                            <div className="fw-bold" style={{ fontFamily: 'Montserratbold', fontSize: pageTitleFontSize }}>
                                {' '}
                                {i18n.t('tipTitle')}
                            </div>
                            <Text title={false} style={{ fontSize: pageNormalFontSize }}>
                                {tip.label}
                            </Text>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
                            <IoTime size={20} />

                            {tip.readMinutes?.toString() && (
                                <View parent={false} style={{ alignItems: 'center', paddingRight: 0 }}>
                                    <Text title={false} style={{ marginTop: 5 }}>
                                        {tip.readMinutes.toString()} min
                                    </Text>
                                </View>
                            )}
                        </div>
                    </div>
                </div>
            }
            buttonLeft={
                tip?.link ? (
                    <View parent={false} style={{ paddingLeft: 0, alignItems: 'center', flexDirection: 'row' }}>
                        <div style={{ display: 'flex' }}>
                            {tip.has_read_tip ? (
                                <LiaCheckSolid size={20} style={{ paddingLeft: 0, marginRight: 10 }} color={color} />
                            ) : (
                                <LiaReadme size={20} style={{ paddingLeft: 0, marginRight: 10 }} color={color} />
                            )}
                        </div>

                        <Text
                            title={false}
                            style={{
                                color: tip.has_read_tip ? color : disabled,
                            }}
                        >
                            {buttonText}
                        </Text>
                    </View>
                ) : undefined
            }
        >
            {img && (
                <Image
                    style={{
                        width: '100%',
                        height: isMobile ? 200 : window.innerHeight / 6,
                        marginTop: 0,
                        marginBottom: isMobile ? 10 : 15,
                        resizeMode: 'container',
                    }}
                    source={{
                        uri: 'data:image/png;base64,' + img + '',
                    }}
                />
            )}
        </BaseCard>
    ) : null
}

export default TipCard
