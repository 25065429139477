import { Link } from 'react-router-dom'
import { CSSProperties, ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import Logo from './Logo'
import i18n from '../i18n/i18n'
import useColorScheme from '../hooks/useColorScheme'
import addOpacityToHexColor from '../helpers/addOpacity'
import { refreshStyle, styleFlag } from '../helpers/helpers'
import { IoMdSettings } from 'react-icons/io'
import useStyles from '../hooks/useStyles'
import useResponsiveStates from '../hooks/useResponsiveStates'
import { IoHome } from 'react-icons/io5'
import { CgPoll } from 'react-icons/cg'
import { AiFillTrophy } from 'react-icons/ai'
import { HiSquares2X2 } from 'react-icons/hi2'
import { iconSize } from '../constants/Layout'
import { LOGO_LINK, LOGO_SVG } from '../helpers/constants'
// import { INTERVAL_PERCENT, NEW_INTERVAL_EXP, TIMER_ON } from '../helpers/constants'

type NavBarComponentProps = {
    activeRoute: string | null
    children?: ReactNode
}

function NavBarComponent({ activeRoute = null, children }: NavBarComponentProps) {
    const navbarContentRef = useRef<HTMLButtonElement>(null)
    const { navButton, navBarUlElement } = useStyles()
    const { isMobile, isDesktop, isBigScreen, isTablet } = useResponsiveStates()

    const handleToggleClick = () => {
        if (navbarContentRef.current) {
            const target = navbarContentRef.current.getAttribute('data-bs-target')
            if (target) {
                const collapseElement = document.querySelector(target)
                collapseElement?.classList.remove('show')
                collapseElement?.classList.add('collapse')
            }
        }
    }

    // settingTheTimer()

    const { getByStyleProp } = useColorScheme()
    const selectedElementRef = useRef<HTMLLIElement | null>(null)
    const [activeTintColor, setActiveTintColor] = useState<string>('')
    const [menuBackground, setMenuBackground] = useState<string>('')
    const [appBackground, setAppBackground] = useState<string>('')
    const [isBackgroundArray, setIsBackgroundArray] = useState<CSSProperties>()
    const textColor = getByStyleProp('text')

    const getBackgroundStyle = (appBackground: any, menuBackground: any): CSSProperties => {
        if (Array.isArray(appBackground)) {
            if (isMobile) return { backgroundColor: menuBackground }
            //@ts-ignore
            else return { backgroundImage: appBackground }
        }
        if (isMobile) return { backgroundColor: menuBackground }
        else return { backgroundImage: appBackground }
    }

    useEffect(() => {
        let tint = getByStyleProp('secondary')
        const newTintColor = addOpacityToHexColor(tint && typeof tint !== undefined ? tint : '#fffff', 0.3)
        let elemets = document.querySelectorAll('li')
        elemets.forEach((elm) => {
            if (elm.classList.contains('selected')) {
                selectedElementRef.current = elm
            }
            elm.style.background = ''
            elm.style.color = newTintColor
            elm.style.fontWeight = 'normal'
            if (isMobile) (elm.children[0]?.children[0] as SVGSVGElement).style.color = newTintColor
        })
        if (selectedElementRef.current) {
            !isMobile
                ? (selectedElementRef.current.style.background = newTintColor)
                : (selectedElementRef.current.style.background = newTintColor) &&
                  (selectedElementRef.current.style.color = textColor) &&
                  ((selectedElementRef.current.children[0].children[0] as SVGSVGElement).style.color = textColor)
            selectedElementRef.current.style.fontWeight = 'bold'
        }
        setActiveTintColor(newTintColor)
        if (!isBackgroundArray || typeof isBackgroundArray === undefined) {
            settingUpBackground()
        }
    }, [activeRoute])
    function settingUpBackground() {
        const bgArray = getByStyleProp('appBackground')
        const newAppBackground = Array.isArray(bgArray) ? `linear-gradient(to left, ${bgArray.join(', ')})` : bgArray
        const newMenuBackground = getByStyleProp('ctaSecondary')
        setMenuBackground(newMenuBackground)
        setAppBackground(newAppBackground)

        const backgroung = getBackgroundStyle(newAppBackground, newMenuBackground)
        setIsBackgroundArray(backgroung)
    }

    const [isHovered, setIsHovered] = useState(false)

    const renderLogo = useCallback(() => {
        return <Logo type={'header'} />
    }, [sessionStorage.getItem(LOGO_LINK), sessionStorage.getItem(LOGO_SVG), isMobile, isDesktop, isTablet, isBigScreen])

    useEffect(() => {
        const newTintColor = addOpacityToHexColor(getByStyleProp('secondary'), 0.5)
        setActiveTintColor(newTintColor)

        settingUpBackground()
    }, [styleFlag])

    return (
        <div>
            {!isMobile && navigationBar()}
            {isMobile && (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        background: appBackground,
                        padding: 15,
                    }}
                >
                    {' '}
                    <Link to="/">{renderLogo()}</Link>
                    <Link to="/settings">
                        <IoMdSettings size={iconSize} style={{ color: 'black' }} />
                    </Link>
                </div>
            )}
            <main
                style={{
                    background: appBackground,
                    margin: 0,
                    paddingRight: isMobile ? 0 : isBigScreen ? '25%' : isTablet ? '10%' : '22%',
                    paddingLeft: isMobile ? 0 : isBigScreen ? '25%' : isTablet ? '10%' : '22%',
                }}
            >
                {children}
            </main>
            {isMobile && navigationBar()}
        </div>
    )

    function navigationBar() {
        return (
            <nav
                className="navbar navbar-default navbar-expand-md navbar-toggleable-sm "
                style={{
                    paddingTop: isMobile || isTablet ? 10 : 20,
                    paddingBottom: isBigScreen || isDesktop ? 30 : isMobile ? 0 : 12,
                    paddingRight: isMobile ? 0 : isTablet ? '12%' : isBigScreen ? '25%' : '22%',
                    paddingLeft: isMobile ? 0 : isTablet ? '10%' : isBigScreen ? '25%' : '22.5%',
                    position: 'sticky',
                    bottom: 0,
                    opacity: 1,
                    zIndex: 3,
                    boxShadow: isMobile ? '0 1px 8px rgba(0,0,0,0.6)' : isTablet ? '0 1px 8px rgba(0,0,0,0.6)' : '0',
                    ...isBackgroundArray,
                }}
            >
                {!isMobile && (
                    <Link
                        to="/"
                        style={{
                            height: isTablet ? '100%' : '70%',
                            width: isTablet ? '20%' : '25%',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            padding: 0,
                        }}
                    >
                        {renderLogo()}
                    </Link>
                )}
                <div
                    style={
                        isMobile
                            ? {
                                  padding: 0,
                                  marginRight: 5,
                                  width: '100%',
                              }
                            : {
                                  alignContent: 'center',
                                  display: 'flex',
                                  padding: 0,
                                  marginRight: 'auto',
                                  marginLeft: 'auto',
                              }
                    }
                >
                    {!isMobile && (
                        <ul
                            className={`${isMobile ? '' : `navbar-nav `}`}
                            style={{ paddingBottom: isMobile ? 15 : 0, alignContent: 'center' }}
                        >
                            <li
                                className={`mx-2 btn btn-outline-light border-0 p-0 text-dark ${
                                    activeRoute === '/home' ? 'selected' : ''
                                }`}
                                ref={(el) => {
                                    if (activeRoute === '/home') selectedElementRef.current = el
                                }}
                                style={navBarUlElement}
                            >
                                <Link className="nav-link mx-2" to="/home" style={{ color: textColor, ...navButton }}>
                                    {i18n.t('bottomTabHome')}
                                </Link>
                            </li>

                            <li
                                className={`mx-2 btn btn-outline-light border-0 p-0 text-dark ${
                                    activeRoute === '/answers' ? 'selected' : ''
                                }`}
                                ref={(el) => {
                                    if (activeRoute === '/answers') selectedElementRef.current = el
                                }}
                                style={navBarUlElement}
                            >
                                <Link className="nav-link mx-2" to="/answers" style={{ color: textColor, ...navButton }}>
                                    {i18n.t('bottomTabResults')}
                                </Link>
                            </li>

                            <li
                                className={`mx-2 btn btn-outline-light border-0 p-0 text-dark ${
                                    activeRoute === '/badges' ? 'selected' : ''
                                }`}
                                ref={(el) => {
                                    if (activeRoute === '/badges') selectedElementRef.current = el
                                }}
                                style={navBarUlElement}
                            >
                                <Link className="nav-link mx-2" to="/badges" style={{ color: textColor, ...navButton }}>
                                    {i18n.t('bottomTabBadges')}
                                </Link>
                            </li>

                            <li
                                className={`mx-2 btn btn-outline-light border-0 p-0 text-dark ${
                                    activeRoute?.includes('/feedback') ? 'selected' : ''
                                }`}
                                ref={(el) => {
                                    if (activeRoute?.includes('/feedback')) {
                                        selectedElementRef.current = el
                                        handleToggleClick()
                                    }
                                }}
                                style={navBarUlElement}
                            >
                                <Link className="nav-link mx-2" to="/feedback" style={{ color: textColor, ...navButton }}>
                                    {i18n.t('FeedbackScreenTitle')}
                                </Link>
                            </li>
                        </ul>
                    )}
                    {isMobile && (
                        <ul
                            style={{
                                display: 'flex',
                                alignContent: 'flex-start',
                                justifyContent: 'space-between',
                                width: '100%',
                                paddingRight: 10,
                                paddingLeft: 10,
                            }}
                        >
                            <li
                                style={{
                                    // padding: 2,
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    borderRadius: 5,
                                }}
                                ref={(el) => {
                                    if (activeRoute === '/home') selectedElementRef.current = el
                                }}
                            >
                                <Link className={`mx-2 btn  border-0  text-dark `} to="/home">
                                    <IoHome
                                        style={{
                                            marginLeft: 0,
                                            color: activeTintColor, // Change color based on hover state
                                        }}
                                        className={`${activeRoute === '/home' ? 'selected' : ''}`}
                                        size={35}
                                    />
                                </Link>
                                <p
                                    style={{
                                        paddingLeft: 5,
                                        paddingRight: 5,
                                        paddingBottom: 5,
                                        margin: 0,
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                        // maxWidth: 70,
                                    }}
                                >
                                    {i18n.t('bottomTabHome')}
                                </p>
                            </li>

                            <li
                                style={{
                                    padding: 2,
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    borderRadius: 5,
                                }}
                                ref={(el) => {
                                    if (activeRoute === '/answers') selectedElementRef.current = el
                                }}
                            >
                                <Link className={`mx-2 btn border-0 text-dark`} to="/answers">
                                    <CgPoll
                                        className={`${activeRoute === '/answers' ? 'selected' : ''}`}
                                        style={{
                                            marginLeft: 0,
                                            color: activeTintColor, // Change color based on hover state
                                        }}
                                        size={35}
                                    />
                                </Link>
                                <p
                                    style={{
                                        paddingLeft: 5,
                                        paddingRight: 5,
                                        paddingBottom: 5,
                                        margin: 0,
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                        // maxWidth: 70,
                                    }}
                                >
                                    {i18n.t('bottomTabResults')}
                                </p>
                            </li>

                            <li
                                style={{
                                    padding: 2,
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    borderRadius: 5,
                                }}
                                ref={(el) => {
                                    if (activeRoute === '/badges') selectedElementRef.current = el
                                }}
                            >
                                <Link className={`mx-2 btn border-0 text-dark `} to="/badges">
                                    <AiFillTrophy
                                        className={`${activeRoute === '/badges' ? 'selected' : ''}`}
                                        style={{
                                            marginLeft: 0,
                                            color: activeTintColor, // Change color based on hover state
                                        }}
                                        size={35}
                                    />
                                </Link>
                                <p
                                    style={{
                                        paddingLeft: 5,
                                        paddingRight: 5,
                                        paddingBottom: 5,
                                        margin: 0,
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                        // maxWidth: 70,
                                    }}
                                >
                                    {i18n.t('bottomTabBadges')}
                                </p>
                            </li>

                            <li
                                style={{
                                    padding: 2,
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    borderRadius: 5,
                                }}
                                ref={(el) => {
                                    if (activeRoute?.includes('/feedback')) selectedElementRef.current = el
                                }}
                            >
                                <Link className={`mx-2 btn border-0 `} to="/feedback">
                                    <HiSquares2X2
                                        className={`${activeRoute?.includes('/feedback') ? 'selected' : ''}`}
                                        style={{
                                            marginLeft: 0,
                                            color: activeTintColor, // Change color based on hover state
                                        }}
                                        size={35}
                                    />
                                </Link>
                                <p
                                    style={{
                                        paddingLeft: 5,
                                        paddingRight: 5,
                                        paddingBottom: 5,
                                        margin: 0,
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                        // maxWidth: 70,
                                    }}
                                >
                                    {' '}
                                    {i18n.t('FeedbackScreenTitle')}
                                </p>
                            </li>
                        </ul>
                    )}
                </div>
                {!isMobile && (
                    <div
                        style={{
                            padding: 2,
                            width: isTablet || isMobile ? '20%' : '25%',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            paddingLeft: isTablet || isMobile ? '4%' : '2%',
                            alignItems: 'center',
                        }}
                    >
                        <Link
                            to="/settings"
                            onMouseEnter={() => setIsHovered(true)} // Change state to true on hover
                            onMouseLeave={() => setIsHovered(false)} // Revert state on mouse leave
                        >
                            <IoMdSettings
                                style={{
                                    marginLeft: 0,
                                    color: isHovered ? 'white' : 'black', // Change color based on hover state
                                }}
                                size={30}
                            />
                        </Link>
                    </div>
                )}
            </nav>
        )
    }
}

export default NavBarComponent
