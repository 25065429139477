import AnswerModel from '../../models/entities/answerModel'
import QuestionModel, { selectOptions } from '../../models/entities/questionModel'
import { useEffect, useState } from 'react'
import { Text, View } from 'react-native-web'
import { useSchedule } from '../../hooks/scheduleContext'
import useColorScheme from '../../hooks/useColorScheme'
import Loadingscreen from './LoadingScreen'
import I18n from '../../i18n/i18n'
import AnswerCard from '../../components/cards/answer'
import CtaCard from '../../components/cards/ctaCard'
import i18n from '../../i18n/i18n'
import { Outlet } from 'react-router-dom'
import { navBarTitleFontSize, pageNormalFontSize } from '../../constants/Layout'
import useResponsiveStates from '../../hooks/useResponsiveStates'
import { logout } from '../../helpers/keycloakAuth'
import log from 'loglevel'

function QuestionScreen({
    questionId,
    onAnswerSubmit,
    maxScreen,
    currenQuestion,
}: {
    questionId: string
    onAnswerSubmit: any
    maxScreen: number
    currenQuestion: number
}) {
    const { scheduleBlock, sendAnswers } = useSchedule()
    const [question, setQuestion] = useState<QuestionModel | undefined>(undefined)
    const [loading, setLoading] = useState<boolean>(false)
    const [max, setMax] = useState(0)

    useEffect(() => {
        setLoading(true)
        let question = scheduleBlock.scheduleBlock && scheduleBlock.scheduleBlock.getQuestion(questionId)
        setQuestion(question)
        setLoading(false)
        return () => {
            setQuestion(undefined)
        }
    }, [questionId, scheduleBlock.version])

    useEffect(() => {
        if (maxScreen > 0) setMax(maxScreen)
    }, [maxScreen])

    const { getByStyleProp } = useColorScheme()
    const backgroundColor: string | string[] = getByStyleProp('appBackground')

    const send = (): void => {
        setLoading(true)
        sendAnswers()
        setLoading(false)
    }

    const handleAnswerSubmit = () => {
        if (onAnswerSubmit) {
            onAnswerSubmit()
        }
    }

    if (loading || !question) {
        return <Loadingscreen />
    }

    return (
        <>
            <View style={{ backgroundColor, marginHorizontal: 15 }}>
                <Text style={{ fontFamily: 'Montserratbold', fontSize: navBarTitleFontSize, marginTop: 10, marginBottom: 5 }}>
                    {question.content}
                </Text>
                <Text style={{ fontSize: pageNormalFontSize, textAlign: 'left', marginBottom: 15 }}>
                    {question.proposed_by ? `${I18n.t('questionProposedBy')} ${question.proposed_by}` : ''}
                </Text>
                {question.answerOptions?.map((answer: AnswerModel) => {
                    return (
                        <AnswerCard
                            //@ts-ignore
                            key={answer.label}
                            answer={answer}
                            questionId={question.id as string}
                            multiple={question.multiple === selectOptions.MULTISLECT}
                            //@ts-ignore
                            isSelected={answer.isSelected(question)}
                        />
                    )
                })}
            </View>
            {question.isAnswerd() && currenQuestion + 1 === max && (
                <CtaCard
                    label={i18n.t('next')}
                    disabled={!question.isAnswerd()}
                    onClick={() => {
                        send()
                    }}
                    primary={false}
                />
            )}
            {question.isAnswerd() && currenQuestion + 1 < max && (
                <CtaCard
                    label={i18n.t('nextQuestion')}
                    disabled={!question.isAnswerd()}
                    onClick={() => {
                        handleAnswerSubmit()
                    }}
                    primary={false}
                />
            )}
        </>
    )
}

export default function QuesetionScreens({ children }: { children: any }) {
    const { scheduleBlock, checkNetworkAndQuestions } = useSchedule()
    const { getByStyleProp } = useColorScheme()
    const backgroundColor: string | string[] = getByStyleProp('appBackground')
    const [currentQuestion, setCurrentQuestion] = useState<number>(0)
    const [maxScreen, setMaxScreen] = useState<number>(0)

    useEffect(() => {
        checkNetworkAndQuestions()
        if (scheduleBlock.scheduleBlock.questions && scheduleBlock.scheduleBlock.questions.length > 0)
            setMaxScreen(scheduleBlock.scheduleBlock.questions.length)
    }, [scheduleBlock.scheduleBlock.id])

    const { isMobile, isBigScreen, isTablet } = useResponsiveStates()
    function handlingAnswerSubmit() {
        let curQuestion = currentQuestion + 1
        setCurrentQuestion(curQuestion)
    }
    function showingCurrentQuestion() {
        if (scheduleBlock.scheduleBlock.questions) {
            const question = scheduleBlock.scheduleBlock.questions[currentQuestion]
            try {
                return (
                    <QuestionScreen
                        //@ts-ignore
                        questionId={question.id}
                        onAnswerSubmit={() => {
                            handlingAnswerSubmit()
                        }}
                        maxScreen={maxScreen}
                        currenQuestion={currentQuestion}
                    />
                )
            } catch (error) {
                log.error('something went wrong with questions in questionScreens component ', error)
                logout()
            }
        }
        return null // or some fallback UI
    }

    return scheduleBlock.scheduleBlock &&
        scheduleBlock.scheduleBlock.questions &&
        scheduleBlock.scheduleBlock.id !== 'NO_BLOCK' &&
        !scheduleBlock.scheduleBlock.questionsAnswerd ? (
        <div
            style={{
                background: backgroundColor[0],
                minHeight: window.innerHeight,
                paddingRight: isMobile ? 0 : isBigScreen ? '25%' : isTablet ? '10%' : '22%',
                paddingLeft: isMobile ? 0 : isBigScreen ? '25%' : isTablet ? '10%' : '22%',
            }}
        >
            {showingCurrentQuestion()}
        </div>
    ) : (
        <>
            {children}
            <Outlet />
        </>
    )
}
