import { FeedbackScreenTypes } from '../../models/entities/feedbackModel'
import { View } from 'react-native-web'
import i18n from '../../i18n/i18n'
import { useLocation } from 'react-router-dom'
import { normalizeFontSize } from '../../constants/Layout'

export const FeedbackScreenSubmitMsg = {
    QUESTIONPROPOSAL: i18n.t('thxFeedbackQuestion1'),
    TIPPROPOSAL: i18n.t('thxFeedbackTip1'),
    GENERAL: i18n.t('thxFeedbackGeneral1'),
    BUGREPORT: i18n.t('thxFeedbackBug1'),
} as const

export const FeedbackScreenSubmitSubMsg = {
    QUESTIONPROPOSAL: i18n.t('thxFeedbackQuestion2'),
    TIPPROPOSAL: i18n.t('thxFeedbackTip2'),
    GENERAL: i18n.t('thxFeedbackGeneral2'),
    BUGREPORT: i18n.t('thxFeedbackBug2'),
} as const

export default function FeedbackScreen() {
    const location = useLocation()
    const { success } = location.state || {}
    const feedbackType: FeedbackScreenTypes = success as FeedbackScreenTypes

    return (
        <div style={{ height: window.outerHeight, paddingTop: '20%', overflow: 'hidden' }}>
            <View parent={false}>
                {success && (
                    <div>
                        <div>
                            <div style={{ fontSize: normalizeFontSize(7) }}>{FeedbackScreenSubmitMsg[feedbackType]}</div>
                            <div style={{ fontFamily: 'Montserratlight', fontSize: normalizeFontSize(5) }}>
                                {FeedbackScreenSubmitSubMsg[feedbackType]}
                            </div>
                        </div>
                    </div>
                )}
            </View>
        </div>
    )
}
