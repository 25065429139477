import { IoMdCheckmark } from 'react-icons/io'
import AnswerModel from '../../models/entities/answerModel'
import { View } from 'react-native-web'
import { useSchedule } from '../../hooks/scheduleContext'
import useColorScheme from '../../hooks/useColorScheme'
import useStyles from '../../hooks/useStyles'
import { Text } from 'react-native-web'
import i18next from 'i18next'

type AnswerCardType = {
    answer: AnswerModel
    questionId: string
    isSelected: boolean
    multiple: boolean
}

export default function AnswerCard({ answer, questionId, isSelected, multiple }: AnswerCardType) {
    const { setAnswer } = useSchedule()
    const { getByStyleProp } = useColorScheme()
    const cardBackground: string = getByStyleProp('cardBackground')
    const activeCardBackground: string = getByStyleProp('ctaSecondary')
    const tint: string = getByStyleProp('tint')
    const backgroundColor: string = getByStyleProp('inputBackground')
    const { card: cardStyle, answerCheckboxContainer: answerCheckboxContainerStyle } = useStyles()

    const parseData = (answer: AnswerModel): string => {
        const localeLang = i18next.language.split('-')[0]
        const translation = answer.a_translations.find((translation) => translation.code === localeLang)
        return translation ? translation.content ?? '' : answer?.label ?? ''
    }

    return (
        <div
            style={{
                ...cardStyle,
                display: 'flex',
                alignItems: 'center',
                backgroundColor: isSelected ? activeCardBackground : cardBackground,
                width: '100%',
                borderWidth: 3,
                borderColor: isSelected ? tint : 'transparent',
                marginTop: 5,
                padding: 20,
                borderRadius: 5,
            }}
            onClick={() => {
                setAnswer(questionId, answer.id ? answer.id : '')
            }}
        >
            <View style={[{ backgroundColor, borderRadius: multiple ? 0 : 50 }, answerCheckboxContainerStyle]}>
                {isSelected && <IoMdCheckmark size={25} name="md-checkmark" color={tint} />}
            </View>
            <View style={{ marginLeft: 10, width: '90%' }}>
                <Text title={false}>{parseData(answer)}</Text>
            </View>
        </div>
    )
}
