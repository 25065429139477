import EntityModel from '../../helpers/interfaces/entityModel'
import AnswerModel, { AnswerModelStorage } from './answerModel'
import { BaseEntityModel } from './baseEntityModel'
import moment from 'moment'
import { UserModel } from '../userModel'
import TagModel from './tagModel'
import Q_translationModel from './q_translationModel'
import A_translationModel from './a_translationModel'

export enum selectOptions {
    SELECTED = 'SELECT',
    MULTISLECT = 'MULTISELECT',
}

// check if answersResults are ok:
export type QuestionModelStorage = {
    answersResults?: string[]
    answers: AnswerModelStorage[]
    id?: string
    content?: string
    proposed_by?: string
    weight_based_on?: string
    type: {
        value: selectOptions
    }
    q_translations: Q_translationModel[]
}
export type AnswersType = { content: string; a_translations?: A_translationModel[] }

export default class QuestionModel extends BaseEntityModel implements EntityModel<QuestionModel> {
    constructor(
        public id?: string,
        public answers?: string[],
        public answerOptions?: AnswerModel[],
        public label?: string,
        public multiple: selectOptions = selectOptions.SELECTED,
        public resultCount?: number,
        public content?: string,
        public isMultiple?: boolean,
        public lastUsed?: string,
        public lastUsedHistorical?: string,
        public status?: string,
        public inUse?: boolean,
        public updated_at?: string,
        public admin_comment?: string,
        public updated_by?: UserModel,
        public proposed_by?: string,
        public userAnswersId?: string[],
        public tags?: TagModel[],
        public weight_based_on?: string,
        public q_translations?: Q_translationModel[]
    ) {
        super(id)
    }

    public static deserialize(input: any): QuestionModel {
        let user
        if (input?.updated_by) {
            user = UserModel.deserialize(input?.updated_by)
        } else {
            user = null
        }

        const userAnswersId: string[] = []

        input.user_answers &&
            input.user_answers.forEach((userAnswer: any) => {
                userAnswersId.push(userAnswer.id)
            })

        let q_translationsArray = this.mapEntityArray(input.q_translations, Q_translationModel)

        return new QuestionModel(
            input.id,
            input.answersResults ? input.answersResults : [],
            input.answers ? BaseEntityModel.mapEntityArray(input.answers, AnswerModel) : [],
            input.content,
            input.type && input.type.value ? input.type.value : selectOptions.SELECTED,
            input.total_responses,
            input.content,
            input.type && input.type.value && input.type.value === selectOptions.MULTISLECT,
            input.last_used,
            input.last_used_historical,
            input.status?.label,
            input.in_use,
            input.updated_at && moment(input.updated_at).format('YYYY-MM-DD H:mm'),
            input.admin_comment,
            user as any,
            input.proposed_by,
            userAnswersId,
            input.tags,
            input.weight_based_on,
            q_translationsArray
        )
    }

    // Check if it is right:
    public setAnswer(answerId: string): QuestionModel {
        if (this.multiple === selectOptions.SELECTED) {
            this.answers = []
        }
        if (this.answers && this.multiple === selectOptions.MULTISLECT && this.answers.indexOf(answerId) >= 0) {
            this.answers = this.answers.filter((answer: string) => answer !== answerId)
        } else {
            this.answers && this.answers.push(answerId)
        }
        return this
    }

    public isAnswerd(): boolean {
        if (this.answers) {
            return this.answers.length > 0
        } else {
            return false
        }
    }

    public serialize(): Object {
        const answersArray: Object[] = []
        const q_translationsArray: Object[] = []

        this.answerOptions &&
            this.answerOptions.forEach((answer: AnswerModel) => {
                answersArray.push(answer.serialize())
            })

        this.q_translations &&
            this.q_translations.forEach((translation: Q_translationModel) => {
                q_translationsArray.push(translation.serialize())
            })

        const tags: string[] = []

        if (this.tags && this.tags.length > 0) {
            this.tags.forEach((tag) => {
                if (tag.id) {
                    tags.push(tag.id)
                }
            })
        }
        return {
            answers: answersArray,
            content: this.content,
            type: this.isMultiple ? selectOptions.MULTISLECT : selectOptions.SELECTED,
            admin_comment: this.admin_comment ? this.admin_comment : '',
            proposed_by: this.proposed_by,
            weight_based_on: this.weight_based_on,
            tags: tags,
            q_translations: q_translationsArray,
            id: this.id,
        }
    }

    public serialzeForStorage(): QuestionModelStorage {
        const q_translationsArray: Q_translationModel[] = []

        this.q_translations &&
            this.q_translations.forEach((translation: Q_translationModel) => {
                q_translationsArray.push(translation.serialize() as Q_translationModel)
            })
        const answerOptions: AnswerModelStorage[] = []
        this.answerOptions &&
            this.answerOptions.forEach((answerOption: AnswerModel) => answerOptions.push(answerOption.serializeForStorage()))

        return {
            answersResults: this.answers,
            answers: answerOptions,
            id: this.id,
            content: this.label,
            proposed_by: this.proposed_by,
            weight_based_on: this.weight_based_on,
            type: {
                value: this.multiple,
            },
            q_translations: q_translationsArray,
        }
    }
}
