import { useState } from 'react'
import { ActivityIndicator } from 'react-native'
import BaseCard from './baseCard'
import { Button, Text, View } from 'react-native-web'
import useColorScheme from '../../hooks/useColorScheme'
import QuestionModel from '../../models/entities/questionModel'
import AnswerModel from '../../models/entities/answerModel'
import { LinearGradient } from 'react-native-svg-web'
import { RepositoryFactory } from '../../repositories/repositoryFactory'
import { FileRepository } from '../../repositories/entities/fileRepository'
import i18n from '../../i18n/i18n'
import useFeatures from '../../hooks/useFeatures'
import { pageNormalFontSize, pageTitleFontSize } from '../../constants/Layout'
import useResponsiveStates from '../../hooks/useResponsiveStates'

type ResultCardType = {
    question: QuestionModel
}

export const ResultCard = ({ question }: ResultCardType) => {
    const { allowResultShare } = useFeatures()
    const { getByStyleProp } = useColorScheme()
    const color: string = getByStyleProp('text')
    const backgroundColor: string = getByStyleProp('tint')
    const appBackground: string = getByStyleProp('inputBackground')
    const [loading, setLoading] = useState<boolean>(false)
    const appBackgroundColor: string | string[] = getByStyleProp('appBackground')
    const { isMobile } = useResponsiveStates()
    const share = (id: string | undefined): void => {
        setLoading(true)
        if (id !== undefined) {
            RepositoryFactory.get(FileRepository)
                .getShareImage(id, appBackgroundColor[0], appBackgroundColor[1], backgroundColor)
                .then(async (base64: string) => {
                    setLoading(false)
                })
                .catch(() => {
                    setLoading(false)
                })
        }
    }

    return (
        <div style={{ marginBottom: isMobile ? 0 : 15, marginTop: !isMobile ? 30 : 0, minHeight: 250 }}>
            <BaseCard
                title={
                    <div style={{ fontFamily: 'Montserratbold', textAlign: 'left', fontSize: pageTitleFontSize }}>
                        {question.label}
                    </div>
                }
            >
                <View parent={false} style={{ paddingHorizontal: 0 }}>
                    <Text
                        title={false}
                        style={{ marginBottom: 15, textAlign: 'left', width: '100%', marginTop: 5, fontSize: pageNormalFontSize }}
                    >
                        {question.proposed_by ? i18n.t('questionProposedBy') + question.proposed_by : ''}
                    </Text>
                    {question.answerOptions &&
                        question.answerOptions.map((answer: AnswerModel) => (
                            <View
                                key={answer.id}
                                parent={false}
                                style={{ paddingHorizontal: 0, justifyContent: 'flex-start', marginBottom: 20 }}
                            >
                                <View parent={false} style={{ paddingHorizontal: 0, marginBottom: 5 }}>
                                    <Text key={answer.id} title={false} style={{ paddingRight: 40 }}>
                                        {answer.label}
                                    </Text>
                                </View>
                                {answer.result !== undefined && answer.id && (
                                    <View
                                        parent={false}
                                        style={{
                                            paddingHorizontal: 0,
                                            flexDirection: 'row',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <LinearGradient
                                            style={{
                                                backgroundColor: appBackground,
                                                width: isMobile ? '80%' : '88%',
                                                flexDirection: 'row',
                                                height: isMobile ? 35 : 30,
                                                paddingHorizontal: 0,
                                                justifyContent: 'flex-start',
                                                //@ts-ignore
                                                borderWidth:
                                                    answer.id === answer.id && question.userAnswersId?.includes(answer.id)
                                                        ? 2
                                                        : 0,
                                                borderColor: color,
                                            }}
                                            locations={[0, 0.15, 0.15]}
                                            colors={['rgba(200, 200, 218, 0.25)', 'rgba(200, 200, 218, 0.005)', appBackground]}
                                        >
                                            <View
                                                parent={false}
                                                style={{
                                                    backgroundColor,
                                                    minWidth: 0,
                                                    width: answer.result + '%',
                                                    paddingHorizontal: 0,
                                                    paddingVertical: 0,
                                                    height: isMobile ? 35 : 30,

                                                    // answer.id === answer.id && question.userAnswersId?.includes(answer.id)
                                                    //     ? 35
                                                    //     : 35,
                                                }}
                                            />
                                        </LinearGradient>

                                        <Text
                                            key={answer.id}
                                            title={false}
                                            style={{
                                                marginLeft: isMobile ? 15 : 24,
                                                fontFamily:
                                                    answer.id === answer.id && question.userAnswersId?.includes(answer.id)
                                                        ? 'Montserratbold'
                                                        : 'Montserratlight',
                                            }}
                                        >
                                            {answer.result} %
                                        </Text>
                                    </View>
                                )}
                            </View>
                        ))}
                    <View parent={false} style={{ paddingLeft: 0 }} />
                </View>
                <View
                    parent={false}
                    style={{
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        height: 40,
                        paddingRight: 0,
                        paddingLeft: 5,
                    }}
                >
                    <Text title={false} style={{ paddingLeft: 0, marginTop: 0 }}>
                        {question.resultCount} {i18n.t('answersText')}
                    </Text>
                    <Text
                        title={false}
                        style={{ paddingLeft: 0, textAlign: 'left', fontSize: 12, fontStyle: 'italic', width: '100%' }}
                    >
                        {question.weight_based_on !== '' ? i18n.t('weightedBasedOn') + question.weight_based_on : ''}
                    </Text>
                    {loading ? (
                        <ActivityIndicator size="small" style={{ marginRight: 25 }} color={backgroundColor} />
                    ) : (
                        allowResultShare && (
                            <Button
                                title={i18n.t('shareAnonymously')}
                                strong={true}
                                style={{ marginTop: 10, marginBottom: 5, width: '100%' }}
                                onPress={() => share(question.id)}
                            />
                        )
                    )}
                </View>
            </BaseCard>
        </div>
    )
}

export default ResultCard
