import { BaseRepository, repoParams } from '../repositories/baseRepository'
import { RepositoryFactory } from '../repositories/repositoryFactory'
import { returnTypes } from '../helpers/interfaces/baseRepositoryInterface'
import { Dispatch, SetStateAction, useState } from 'react'
import useVars from './useVars'

export default function useBaseRepository<T>(
    repoModel: new (..._params: any[]) => BaseRepository<T>,
    func: keyof BaseRepository<T>,
    inputParams?: repoParams
): {
    result: T | undefined
    results: T[]
    loading: boolean
    doCall: (_params?: repoParams) => Promise<T[] | T>
    setParams: Dispatch<SetStateAction<repoParams>>
} {
    const { getEnvVars } = useVars()
    const { baseUrl, apiSuffix } = getEnvVars()

    RepositoryFactory.setUrl({
        baseUrl,
        apiSuffix,
    })

    const repo: BaseRepository<T> = RepositoryFactory.get(repoModel)
    const [result, setResult] = useState<T>()
    const [results, setResults] = useState<T[]>([])
    const [loading, setLoading] = useState<boolean>(true)
    const [params, setParams] = useState<repoParams>(inputParams ? inputParams : {})

    const doCall: (_params?: repoParams) => Promise<T[] | T> = async (inputCallParams?: repoParams) => {
        setLoading(true)
        const doCallParams: repoParams = inputCallParams ? inputCallParams : params

        try {
            const response: returnTypes<T>['modelArray'] | returnTypes<T>['modelSingle'] = await repo[func](doCallParams)

            if (Array.isArray(response.result)) {
                setResults(response.result)
            } else {
                setResult(response.result)
            }

            setParams(response.params)
            setLoading(false)
            return response.result
        } catch (err) {
            setLoading(false)
            throw err
        }
    }

    return { result, results, loading, doCall, setParams }
}

export function useRepositoryFunction<T>(
    func: (_param: repoParams) => Promise<returnTypes<T>['modelArray']> | Promise<returnTypes<T>['modelSingle']>,
    inputParams?: repoParams
): {
    result: T | undefined
    results: T[]
    loading: boolean
    doCall: () => Promise<T[] | T>
    setParams: Dispatch<SetStateAction<repoParams>>
} {
    const [result, setResult] = useState<T>()
    const [results, setResults] = useState<T[]>([])
    const [loading, setLoading] = useState<boolean>(true)
    const [params, setParams] = useState<repoParams>(inputParams ? inputParams : {})

    const doCall: () => Promise<T[] | T> = async () => {
        setLoading(true)
        const response: returnTypes<T>['modelArray'] | returnTypes<T>['modelSingle'] = await func(params)

        if (Array.isArray(response.result)) {
            setResults(response.result)
        } else {
            setResult(response.result)
        }

        setParams(response.params)
        setLoading(false)
        return response.result
    }

    return { result, results, loading, doCall, setParams }
}
