import { UserModel } from '../models/userModel'
import AuthRepository from '../repositories/authRepository'
import { createContext, useEffect, useReducer, useState } from 'react'
import colorSchemes, { dynamicSchemes } from '../constants/Colors'
import { colorSchemesType } from './useColorScheme'
import useBaseRepository from './useRepository'
import { KEY_COLOR_STORAGE, LOGGED_IN } from '../helpers/constants'
import log from 'loglevel'

export type useAuthType = {
    loggedIn: boolean
    invalidUser: Boolean
    checkLogin: () => void
    user: { version: number; user: UserModel; failed: boolean; colorSelection: colorSchemesType }
    getCurrentUserInfo: () => void
    isFetchingUserData: boolean
    updateUser: (_user: UserModel) => void
    setUserColor: (_input: colorSchemesType) => void
}

export const AuthContext = createContext<useAuthType>({
    loggedIn: false,
    invalidUser: true,
    isFetchingUserData: true,
    checkLogin: () => {},
    user: { version: 0, user: new UserModel(), failed: false, colorSelection: dynamicSchemes() },
    getCurrentUserInfo: () => {},
    updateUser: (_user: UserModel) => {},
    setUserColor: () => {},
})

function reducer(state: { version: number; user: UserModel; failed: boolean; colorSelection: colorSchemesType }, action: any) {
    switch (action.type) {
        case 'overwrite':
            return {
                version: state.version + 1,
                user: action.newModel,
                failed: false,
                colorSelection: state.colorSelection,
            }
        case 'reset':
            return {
                version: 0,
                user: {},
                failed: false,
                colorSelection: dynamicSchemes(),
            }
        case 'failed':
            return {
                version: 0,
                user: {},
                failed: true,
                colorSelection: state.colorSelection,
            }
        case 'color':
            return {
                version: state.version + 1,
                user: state.user,
                failed: state.failed,
                colorSelection: action.colorSelection,
            }
        default:
            throw new Error()
    }
}

const useAuth = (defaultColor: colorSchemesType): useAuthType => {
    // const { getStorage: getAccessToken } = useStorage(KEY_AUTH_ACCESS_TOKEN, false)
    // const { getStorage: getColorStorage, setStorage: setColorStorage } = useStorage(KEY_COLOR_STORAGE, false)
    const [loggedIn, setLoggedIn] = useState<boolean>(false)
    const [isFetchingUserData, setIsFetchingUserData] = useState<boolean>(true)
    const [invalidUser, setInvalidUser] = useState<Boolean>(false)
    const [user, setUser] = useReducer(reducer, {
        version: 0,
        user: new UserModel(),
        failed: false,
        colorSelection: defaultColor,
    })
    const { doCall: postUserInfo } = useBaseRepository<UserModel>(AuthRepository, 'update')
    const { doCall: getMe } = useBaseRepository<UserModel>(AuthRepository, 'getSingle', { id: 'me/' })

    const getCurrentUserInfo = () => {
        setIsFetchingUserData(true)
        getMe()
            .then((result: UserModel | UserModel[]) => {
                log.debug('++++++++USER INFO ', result)
                if (!Array.isArray(result)) {
                    setUser({ type: 'overwrite', newModel: result })
                    setLoggedIn(true)
                    sessionStorage.setItem(LOGGED_IN, 'true')
                }
                setIsFetchingUserData(false)
            })
            .catch((er: any) => {
                setUser({ type: 'failed' })
                log.error('getCurrentUserError :', er)
                setIsFetchingUserData(false)
            })
    }

    const updateUser = (user: UserModel) => {
        postUserInfo({
            id: 'user_data',
            model: user,
        })
            .then(() => {
                getCurrentUserInfo()
            })
            .catch(() => {
                getCurrentUserInfo()
            })
    }

    const checkLogin = async () => {
        //@ts-ignore
        await getAccessToken().then((token: string) => {
            if (token && token !== '' && typeof token !== undefined) {
                checkForUserInfo().then((checkForUserInfo: boolean | undefined) => {
                    if (checkForUserInfo) {
                        setInvalidUser(false)
                        setLoggedIn(true)
                    } else {
                        setLoggedIn(false)
                        setInvalidUser(true)
                    }
                })
            } else {
                setLoggedIn(false)
            }
        })
    }

    const checkForUserInfo = async (): Promise<boolean | undefined> => {
        return await getMe().then((user: UserModel | UserModel[]) => {
            if (!Array.isArray(user)) {
                return true
            }
        })
    }

    useEffect(() => {
        if (loggedIn) {
            getCurrentUserInfo()

            const colors = sessionStorage.getItem(KEY_COLOR_STORAGE)
            if (colors && colors !== '') {
                setUserColor(JSON.parse(colors))
            }
        }
    }, [loggedIn])

    const setUserColor = (input: colorSchemesType) => {
        setUser({ type: 'color', colorSelection: input })
        sessionStorage.setItem(KEY_COLOR_STORAGE, JSON.stringify(input))
    }

    return {
        loggedIn,
        invalidUser,
        checkLogin,
        user,
        getCurrentUserInfo,
        isFetchingUserData,
        updateUser,
        setUserColor,
    }
}

export default useAuth
