import { BaseApiRepository } from '../repositories/baseApiRepository'
import { KEY_AUTH_ACCESS_TOKEN, KEY_AUTH_REFRESH_TOKEN } from '../helpers/constants'
// import { clearSecureStorage, setSecureStorage } from '../hooks/useStorage'
import { getEnvVars } from '../hooks/useVars'
import { AxiosInstance } from 'axios'
import log from 'loglevel'

export type ocidTokens = {
    access: string
    refresh: string
}

export default class OidcRepository extends BaseApiRepository {
    protected axiosInstance!: AxiosInstance
    id = 'oidc'
    endpoint = 'oidc'

    getTokensFromServer(authCode: string): Promise<ocidTokens> {
        const { oidc_redirectUrl, oidc_clientId, oidc_realm } = getEnvVars()

        const redirectUrl = oidc_redirectUrl

        const body = {
            authCode,
            realm: oidc_realm,
            clientId: oidc_clientId,
            redirectUri: redirectUrl,
        }

        return this.postToOIDC('token', body).then((result: any) => {
            return {
                access: result.access_token,
                refresh: result.refresh_token,
            }
        })
    }

    refreshTokensFromServer(refreshToken: string): Promise<ocidTokens | void> {
        const { oidc_clientId, oidc_realm } = getEnvVars()

        const body = {
            realm: oidc_realm,
            clientId: oidc_clientId,
            refreshToken,
        }

        const promise = this.postToOIDC('refresh', body)

        const refreshPromise = promise.then(
            async (result: any) => {
                const tokens: ocidTokens = {
                    access: result.access_token,
                    refresh: result.refresh_token,
                }
                await sessionStorage.setItem(tokens.access, KEY_AUTH_ACCESS_TOKEN)
                await sessionStorage.setItem(tokens.refresh, KEY_AUTH_REFRESH_TOKEN)
                return tokens
            },
            () => {
                log.error('refresh token error')
                sessionStorage.removeItem(KEY_AUTH_ACCESS_TOKEN)
                sessionStorage.removeItem(KEY_AUTH_REFRESH_TOKEN)
            }
        )
        return refreshPromise
    }

    private postToOIDC(endpoint: string, body: object): Promise<unknown> {
        return this.post(this.endpoint + '/' + endpoint + '/', body, {}, true)
    }
}
