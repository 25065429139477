import { envVar } from '../../hooks/useVars'

export const env: envVar = {
    baseUrl: 'http://127.0.0.1:8000',
    apiSuffix: 'api',
    oidc_clientId: 'inuits-tell-me-app',
    oidc_realm: 'youthapp',
    oidc_redirectUrl: 'http://localhost:3000',
    autoDiscoverUrl: 'https://auth.inuits.io/',
}
