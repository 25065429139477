import BaseCard from './baseCard'
import BadgeModel from '../../models/entities/BadgeModel'
import useBadges, { BadgeType } from '../../hooks/useBadges'
import I18n from '../../i18n/i18n'
import { badges } from '../../constants/badges'
import { useNavigate } from 'react-router-dom'
import useResponsiveStates from '../../hooks/useResponsiveStates'
import { pageNormalFontSize, pageTitleFontSize } from '../../constants/Layout'
const defaultBadges: BadgeType = badges
type BadgeCardProps = {
    badges: BadgeModel[]
    title: string
    linkToBadgeScreen?: boolean
    info?: string
    beingAbleToClose?: boolean
}

export const BadgeCard = ({ title, info, linkToBadgeScreen = false, badges, beingAbleToClose = false }: BadgeCardProps) => {
    const availableBadges = useBadges(defaultBadges)
    const navigation = useNavigate()
    const { isMobile } = useResponsiveStates()
    return (
        <BaseCard
            beingAbletoClose={beingAbleToClose}
            buttonLabel={linkToBadgeScreen ? I18n.t('checkBadges') : undefined}
            route={
                linkToBadgeScreen
                    ? () => {
                          navigation('/badges')
                      }
                    : undefined
            }
            title={
                <div
                    style={{
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: isMobile ? 'center' : 'flex-start',
                        textAlign: isMobile ? 'center' : 'left',
                    }}
                >
                    <div style={{ fontFamily: 'Montserratbold', fontSize: pageTitleFontSize }}>{title}</div>
                    {!isMobile && info && <div style={{ fontSize: pageNormalFontSize }}>{info}</div>}
                </div>
            }
        >
            {isMobile && info && <div style={{ fontSize: pageNormalFontSize }}>{info}</div>}
            <div style={{ display: 'flex', flexDirection: 'row', paddingTop: 0, flexWrap: 'wrap', marginBottom: 15 }}>
                {badges.map((badge: BadgeModel) => availableBadges[badge.key] && gettingBadge(badge))}
            </div>
        </BaseCard>
    )

    function gettingBadge(badge: BadgeModel) {
        return (
            <div key={badge.id} style={{ height: '100%', width: isMobile ? '50%' : '32%' }}>
                {availableBadges[badge.key].component({
                    amount: badge.amount,
                    target: badge.target,
                    reached: badge.reached,
                    backgroundColor: availableBadges[badge.key].props.backgroundColor,
                    color: availableBadges[badge.key].props.color,
                    tint: availableBadges[badge.key].props.tint,
                })}
            </div>
        )
    }
}

export default BadgeCard
