import EntityModel from '../../helpers/interfaces/entityModel'
import { BaseEntityModel } from './baseEntityModel'

export default class Q_translationModel extends BaseEntityModel implements EntityModel<Q_translationModel> {
    constructor(
        public id?: string,
        public content?: string,
        public code?: string,
        public name?: string,
        public description?: string
    ) {
        super(id)
    }

    public static deserialize(input: any): Q_translationModel {
        return new Q_translationModel(input.id, input.content, input.code, input.name, input.description)
    }

    public serialize(): Object {
        return {
            content: this.content,
            code: this.code,
        }
    }
}
