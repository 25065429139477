import { styles as defaultStyles } from '../constants/Layout'

type ViewStyle = React.CSSProperties

export type stylesType = {
    card?: ViewStyle
    btnViewContainer?: ViewStyle
    scrollView?: ViewStyle
    checkboxLabelContainer?: ViewStyle
    answerTouchContainer?: ViewStyle
    answerCheckboxContainer?: ViewStyle
    answerTextContainer?: ViewStyle
    threedeffect?: boolean
    logoutButton?: ViewStyle
    navButton?: ViewStyle
    pageButton?: ViewStyle
    navBarUlElement?: ViewStyle
}

let styles: stylesType = defaultStyles

export default function useStyles(styleInput?: stylesType): stylesType {
    if (styleInput) {
        styles = styleInput
    }

    return styles
}
