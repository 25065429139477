import React from 'react'
import ReactDOM from 'react-dom/client'
import * as Sentry from '@sentry/browser'
import './index.css'
import './App.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.bundle'
import { getGlitchTipDsn } from './constants/EnvVars'
import log from 'loglevel'
let currentEnvironment: null | undefined | string = null
try {
    currentEnvironment = window.__RUNTIME_CONFIG__.REACT_APP_ENVIRONMENT
} catch (error) {
    currentEnvironment = null
}

Sentry.init({ dsn: getGlitchTipDsn(currentEnvironment) })

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
