import { env } from '../constants/EnvVars'
import { BaseApiRepository } from '../repositories/baseApiRepository'

export type ocidTokens = {
    access: string
    refresh: string
}

export default class HealthRepository extends BaseApiRepository {
    // protected axiosInstance!: AxiosInstance
    id = 'healthcheck'
    endpoint = '/auth/'

    async checkHealth(): Promise<any> {
        try {
            const result = await fetch(`${env.baseUrl}/api/auth/healthcheck/`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            })

            if (!result.ok) {
                return result.json()
            }
            return 'Service is healthy !'
        } catch (error) {
            console.error('Fetch error: ', error)
        }
    }
}
