import EntityModel from '../../helpers/interfaces/entityModel'
import { BaseEntityModel } from './baseEntityModel'
import QuestionModel from './questionModel'

export default class ResultModal extends BaseEntityModel implements EntityModel<ResultModal> {
    constructor(
        public questions?: QuestionModel[],
        public id?: string,
        public date?: string
    ) {
        super(id)
    }

    public static deserialize(input: any): ResultModal {
        const questionArray: any[] = []
        input.forEach((resultInput: any) => {
            resultInput.answers = resultInput.results // Assign new key
            delete resultInput.results // Delete old key
        })

        return new ResultModal(this.mapEntityArray(input, QuestionModel), input.id, input.date)
    }
}
