import { useCallback, useEffect, useRef } from 'react'
import BadgeCard from '../../components/cards/BadgeCard'
import { useSchedule } from '../../hooks/scheduleContext'
import BadgeModel from '../../models/entities/BadgeModel'
import useHomeScreen from '../../hooks/useHomeScreen'
import Moment from 'moment'
import I18n from '../../i18n/i18n'
import TipCard from '../../components/cards/tipCard'
import CtaCard from '../../components/cards/ctaCard'
import { FaCheckCircle } from 'react-icons/fa'
import { useTenant } from '../../hooks/tenantContext'
import i18n from '../../i18n/i18n'
import useResponsiveStates from '../../hooks/useResponsiveStates'
import log from 'loglevel'

export default function HomeScreen() {
    const { scheduleBlock, badges, refreshBadges } = useSchedule()
    const { tenant } = useTenant()
    const HomeScreens = useHomeScreen()
    useEffect(() => {
        if (scheduleBlock && refreshBadges) {
            if (!badges || badges.length < 1) refreshBadges(scheduleBlock.scheduleBlock)
        }
    }, [])

    const { isMobile } = useResponsiveStates()
    const stortBadges = (a: BadgeModel, b: BadgeModel) => {
        const deltaA = a.target - a.amount
        const deltaB = b.target - b.amount

        if (deltaA < deltaB) {
            return -1
        }
        if (deltaA > deltaB) {
            return 1
        }
        return 0
    }

    const filterBadges = (obj: BadgeModel) => {
        if (obj.reached) {
            return false
        } else {
            return true
        }
    }

    const thxMessage = useRef<string>('')

    const message = useCallback(() => {
        thxMessage.current = I18n.t('thxAnswers')
        log.debug('inside message start next date', scheduleBlock.scheduleBlock.start_date_next)
        log.debug('inside message answered questions', scheduleBlock.scheduleBlock.questionsAnswerd)
        if (scheduleBlock.scheduleBlock.start_date_next && scheduleBlock.scheduleBlock.start_date_next !== undefined) {
            const formatted_start_date_next = Moment(scheduleBlock.scheduleBlock.start_date_next).format('DD-MM-YYYY')
            if (tenant.tenant.schedule_daily_start_time !== undefined) {
                log.debug('date is not null ', formatted_start_date_next)
                thxMessage.current =
                    thxMessage.current +
                    i18n.t('nextQuestionsOn') +
                    formatted_start_date_next +
                    ' ' +
                    tenant.tenant.schedule_daily_start_time
            } else {
                thxMessage.current = thxMessage.current + i18n.t('nextQuestionsOn') + formatted_start_date_next
            }
        }
    }, [scheduleBlock.scheduleBlock])

    message()
    // let myVar: string | null | undefined = window.__RUNTIME_CONFIG__.REACT_APP_ENVIRONMENT
    return (
        <div style={{ minHeight: window.innerHeight, paddingBottom: 15, paddingTop: 15, overflow: 'hidden' }}>
            <div>
                {scheduleBlock.scheduleBlock.tips && scheduleBlock.scheduleBlock.tips.map((t) => <TipCard key={t.id} tip={t} />)}

                <HomeScreens.homeScreenTop />
                <HomeScreens.homeScreenBottom />
            </div>
            <div>
                {badges && (
                    <BadgeCard
                        badges={badges
                            .sort(stortBadges)
                            .filter(filterBadges)
                            .slice(0, isMobile ? 2 : 3)}
                        title={I18n.t('BadgeScreenTitle')}
                        info={I18n.t('badgesAlmostAchieved')}
                        linkToBadgeScreen={true}
                    />
                )}
                {scheduleBlock.scheduleBlock.questionsAnswerd && (
                    <CtaCard label={thxMessage.current} icon={<FaCheckCircle size={30} />} primary={false} />
                )}
            </div>
        </div>
    )
}
