import ResultModal from '../../models/entities/resultModal'
import { BaseRepository, repoParams } from '../baseRepository'

export class ResultRepository extends BaseRepository<ResultModal> {
    id = '/question_results_2/'
    endpoint = '/schedule_blocks/'
    entityModel = ResultModal
    publicGet = false

    async getSingle(params: repoParams): Promise<{ result: ResultModal; params: repoParams }> {
        let endpointPartTwo: string = params.id + '/results/'
        if (params.id === 'active_results') {
            endpointPartTwo = 'active_results/?lang=' + params.lang
        }

        const response = await this.get(this.endpoint + endpointPartTwo, {}, this.publicGet)
        return { result: this.entityModel.deserialize(response), params: params }
    }
}
