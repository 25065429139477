import { RepositoryFactory } from '../repositories/repositoryFactory'
import { TenantRepository } from '../repositories/entities/tenantRepository'
import TenantModel from '../models/entities/tenantModel'
import { createContext, FunctionComponent, useEffect, useState } from 'react'
import { useContext } from 'react'
import { useReducer } from 'react'
import { AuthContext } from '../hooks/useAuth'
import { colorSchemesType } from '../hooks/useColorScheme'
import { initializeClient, setIsRun } from '../helpers/keycloakAuth'
import { NO_TENANT, TENANT_STORAGE_KEY } from '../helpers/constants'
import log from 'loglevel'

type tenantContextType = {
    tenant: { version: number; tenant: TenantModel }
    loading: boolean
    checkNetworkAndTenant: (activationCode?: string) => void
    isOffline: boolean
}

const TenantContext = createContext<tenantContextType>({
    tenant: { version: 0, tenant: new TenantModel() },
    loading: false,
    checkNetworkAndTenant: (activationCode?: string) => {},
    isOffline: false,
})

//Reducer for triggering changes on model, without version change not possible
function reducer(state: { version: number; tenant: TenantModel }, action: any) {
    switch (action.type) {
        case 'overwrite':
            return {
                version: state.version + 1,
                tenant: action.newModel,
            }
        case 'reset':
            return {
                version: state.version + 1,
                tenant: {},
            }
        case 'resetAndOverwrite':
            return {
                version: 1,
                tenant: action.newModel,
            }
        default:
            throw new Error()
    }
}

export const TenantProvider: FunctionComponent<{ children: any }> = ({ children }) => {
    const [tenant, setTenant] = useReducer(reducer, { version: 0, tenant: new TenantModel() })
    const [loading, setLoading] = useState<boolean>(false)
    const [isOffline] = useState<boolean>(false)
    const { user, setUserColor } = useContext(AuthContext)

    const setTenantInstorage = async (input: TenantModel): Promise<TenantModel> => {
        log.debug('tenant to storage ', { ...input })
        setTenant({ type: 'resetAndOverwrite', newModel: input })
        sessionStorage.setItem(TENANT_STORAGE_KEY, JSON.stringify(input))
        return input
    }

    const getTenantFromStorage = async (): Promise<TenantModel | undefined> => {
        const tenantStringFromStorage: string | null = sessionStorage.getItem(TENANT_STORAGE_KEY)
        log.debug('tenant from session storage ', { tenantStringFromStorage })
        if (
            tenantStringFromStorage !== null &&
            tenantStringFromStorage !== '' &&
            typeof tenantStringFromStorage !== 'undefined'
        ) {
            const tenant: TenantModel = TenantModel.deserialize(JSON.parse(tenantStringFromStorage))
            log.debug('tenant ')
            setTenant({ type: 'overwrite', newModel: tenant })
            return tenant
        }
    }

    const getFromApi = (activationCode?: string, fromStorage?: TenantModel, force: boolean = false) => {
        if (activationCode) {
            RepositoryFactory.get(TenantRepository)
                .getTenantConfig(activationCode)
                .then((result: TenantModel) => {
                    log.info('tenant from RepositoryFactory TenantRepository ', { ...result })
                    if (result !== null) {
                        if (!fromStorage || result !== fromStorage || force === true) {
                            if (result.backgroundcolor && result.primarycolor && result.secondarycolor) {
                                const currentSchem: colorSchemesType = user.colorSelection

                                currentSchem.light.appBackground = [result.backgroundcolor, result.backgroundcolor]
                                currentSchem.light.defaultAppBackground = [result.backgroundcolor, result.backgroundcolor]
                                currentSchem.light.tint = result.primarycolor
                                currentSchem.light.defaultTint = result.primarycolor
                                currentSchem.light.menuActive = result.secondarycolor
                                currentSchem.light.defaultMenuActive = result.secondarycolor

                                setUserColor(currentSchem)
                            }
                            setTenantInstorage(result)
                                .then(() => {
                                    const tenantString = sessionStorage.getItem(TENANT_STORAGE_KEY)

                                    if (tenantString) {
                                        const tenantObject = JSON.parse(tenantString)

                                        setIsRun(false)
                                        initializeClient(tenantObject)
                                    } else {
                                        log.info('No tenant data found in storage.')
                                    }
                                })
                                .catch((error) => {
                                    log.error('Tenantproblem', error)
                                    noTenantPresent()
                                })
                        }
                    } else {
                        noTenantPresent()
                    }
                })
                .catch(() => {
                    setLoading(false)
                })
        }
    }

    const noTenantPresent = () => {
        setTenantInstorage(TenantModel.deserialize({ id: NO_TENANT })).then((tenant: TenantModel) => {
            setLoading(false)
        })
    }

    const checkNetworkAndTenant = async (activationCode?: string) => {
        await checkTenant(activationCode)
    }

    const checkTenant = async (activationCode?: string, force: boolean = false): Promise<void> => {
        if (!activationCode) {
            await getTenantFromStorage()
        }
        if (activationCode) {
            await getTenantFromStorage().then((fromStorage: TenantModel | unknown) => {
                //@ts-ignore
                getFromApi(activationCode, fromStorage, force)
            })
        }
    }

    return (
        <TenantContext.Provider
            value={{
                tenant,
                loading,
                checkNetworkAndTenant,
                isOffline,
            }}
        >
            {children}
        </TenantContext.Provider>
    )
}

export const useTenant = (): tenantContextType => {
    const { tenant, loading, checkNetworkAndTenant, isOffline } = useContext(TenantContext)
    return {
        tenant,
        loading,
        checkNetworkAndTenant,
        isOffline,
    }
}
