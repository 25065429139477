import { RepositoryFactory } from '../repositories/repositoryFactory'
import { AppScheduleBlockRepository } from '../repositories/entities/AppScheduleBlockRepository'
import QuestionModel from '../models/entities/questionModel'
import ScheduleBlockModel from '../models/entities/AppScheduleBlockModel'
import { createContext, FunctionComponent, useCallback, useState } from 'react'
import useBaseRepository from './useRepository'
import { useContext } from 'react'
import { useReducer } from 'react'
import ResultModal from '../models/entities/resultModal'
import BadgeStatsModel from '../models/entities/BadgeStatsModel'
import { BadgesRepository } from '../repositories/entities/badgesRepository'
import BadgeModel from '../models/entities/BadgeModel'
import log from 'loglevel'
import { BLOCK_STORAGE_KEY, NO_BLOCK_PRESENT } from '../helpers/constants'
import i18n from '../i18n/i18n'

const initialScheduleState = { version: 0, scheduleBlock: new ScheduleBlockModel() }

type scheduleContextType = {
    scheduleBlock: { version: number; scheduleBlock: ScheduleBlockModel }
    setAnswer: (_qId: string, _aId: string) => void
    checkNetworkAndQuestions: () => void
    sendAnswers: () => void
    tipRead: (tip_instance_id?: string) => void
    setResults: (_results: ResultModal) => void
    refreshBadges?: (_block: ScheduleBlockModel) => Promise<BadgeStatsModel | void>
    loading: boolean
    isOffline: boolean
    badges?: BadgeModel[] | null
}

const ScheduleContext = createContext<scheduleContextType>({
    scheduleBlock: { version: 0, scheduleBlock: new ScheduleBlockModel() },
    setAnswer: (_q: string, _a: string) => ({}),
    checkNetworkAndQuestions: () => {},
    sendAnswers: () => {},
    tipRead: (tip_instance_id?: string) => {},
    setResults: (_r: ResultModal) => {},
    loading: false,
    isOffline: false,
    badges: [] as BadgeModel[],
})

//Reducer for triggering changes on model, without version change not possible
function reducer(state: { version: number; scheduleBlock: ScheduleBlockModel }, action: any) {
    switch (action.type) {
        case 'overwrite':
            return {
                version: state.version + 1,
                scheduleBlock: action.newModel,
            }
        case 'reset':
            return {
                version: state.version + 1,
                scheduleBlock: {},
            }
        case 'resetAndOverwrite':
            return {
                version: 1,
                scheduleBlock: action.newModel,
            }
        default:
            throw new Error()
    }
}

function useScheduleLogic() {
    const [scheduleBlock, setScheduleBlock] = useReducer(reducer, initialScheduleState)
    const [loading, setLoading] = useState(false)
    const [isOffline, setIsOffline] = useState(false)
    const [badges, setBadges] = useState<BadgeModel[] | null>(null)

    const fetchSchedule = useCallback(async () => {
        // Encapsulate logic for fetching and updating the schedule
        // ... (your getFromApi logic, using useCallback)
    }, [])

    // More logic here...

    return {
        scheduleBlock,
        loading,
        isOffline,
        badges,
        fetchSchedule,
        // Other exported functions
    }
}

export const ScheduleProvider: FunctionComponent<{ children: any }> = ({ children }) => {
    const [scheduleBlock, setScheduleBlock] = useReducer(reducer, initialScheduleState)
    const [loading, setLoading] = useState<boolean>(false)
    const [isOffline, setIsOffline] = useState<boolean>(false)
    const [badges, setBadges] = useState<null | BadgeModel[]>(null)

    const { doCall: getScheduleBlock } = useBaseRepository<ScheduleBlockModel>(AppScheduleBlockRepository, 'getSingle', {
        id: 'active/',
        lang: i18n.language.split('-')[0],
    })

    const { doCall: getBadges } = useBaseRepository<BadgeStatsModel>(BadgesRepository, 'getSingle', { id: 'info/' })

    //Set and get schedule in regular storage
    const setBlockInstorage = (input: ScheduleBlockModel): ScheduleBlockModel => {
        sessionStorage.setItem(BLOCK_STORAGE_KEY, JSON.stringify(input.serializeForStorage()))
        setScheduleBlock({ type: 'resetAndOverwrite', newModel: input })
        return input
    }

    const getBlockFromStorage = async (): Promise<ScheduleBlockModel | undefined> => {
        const blockStringFromStorage: string | null = sessionStorage.getItem(BLOCK_STORAGE_KEY)
        if (!blockStringFromStorage || typeof blockStringFromStorage === undefined) {
            log.info('no scheduled in storage')
        } else {
            const block: ScheduleBlockModel = ScheduleBlockModel.deserialize(JSON.parse(blockStringFromStorage))
            setScheduleBlock({ type: 'overwrite', newModel: block })
            log.debug('()()())()(())()()( schedule ', { ...block })
            return block
        }
    }

    const getFromApi = (fromStorage?: ScheduleBlockModel, force: boolean = false) => {
        RepositoryFactory.get(AppScheduleBlockRepository)
            .checkBlockId()
            .then((result: null | string) => {
                if (result !== null) {
                    if (!fromStorage || result !== fromStorage.id || force === true) {
                        getScheduleBlock()
                            .then((result: ScheduleBlockModel | ScheduleBlockModel[]) => {
                                if (!Array.isArray(result)) {
                                    setBlockInstorage(result)
                                    checkAnsweredTodayQuestion()
                                }
                            })
                            .catch((e) => {
                                log.error('error in de context: ' + e)
                                noBlockPresent()
                            })
                    } else {
                        checkAnsweredTodayQuestion()
                    }
                } else {
                    noBlockPresent()
                }
            })
            .catch((error) => {
                log.error('error in getting from api ', error)
                setLoading(false)
                setIsOffline(true)
            })
    }

    const noBlockPresent = () => {
        let block = setBlockInstorage(ScheduleBlockModel.deserialize({ id: NO_BLOCK_PRESENT }))
        refreshBadges(block)
        setLoading(false)
    }

    const checkNetworkAndQuestions = () => {
        setIsOffline(false)
        checkForNewQuestions()
    }

    const checkForNewQuestions = (force: boolean = false): void => {
        getBlockFromStorage().then((fromStorage: ScheduleBlockModel | undefined) => {
            getFromApi(fromStorage, force)
        })
    }

    const checkAnsweredTodayQuestion = (): boolean => {
        let answeredTodayQuestion: boolean = true
        scheduleBlock.scheduleBlock.questions &&
            scheduleBlock.scheduleBlock.questions?.forEach((question: QuestionModel) => {
                if (question.isAnswerd() === false) {
                    answeredTodayQuestion = false
                }
            })
        setLoading(false)
        return answeredTodayQuestion && scheduleBlock.scheduleBlock.questionsAnswerd
    }

    const setAnswer = (questionId: string, answerId: string): void => {
        const block: ScheduleBlockModel = scheduleBlock.scheduleBlock.setAnswer(questionId, answerId)
        setScheduleBlock({ type: 'overwrite', newModel: block })
        // setBlockInstorage(scheduleBlock.scheduleBlock)
    }

    const setResults = (results: ResultModal): void => {
        scheduleBlock.scheduleBlock.results = results
        setBlockInstorage(scheduleBlock.scheduleBlock)
    }

    const refreshBadges = async (input: ScheduleBlockModel): Promise<BadgeStatsModel | void> => {
        return await getBadges().then((result: BadgeStatsModel | BadgeStatsModel[]) => {
            if (!Array.isArray(result)) {
                input.badgesStatus = result
                // setBlockInstorage(input)
                setBadges(input.badgesStatus.badges)
                // setScheduleBlock({ type: 'overwrite', newModel: input })
                log.debug('**************badges ', { ...result })
                return result
            }
        })
    }

    const sendAnswers = (): void => {
        setBlockInstorage(scheduleBlock.scheduleBlock) //added to test
        RepositoryFactory.get(AppScheduleBlockRepository)
            .respond({ model: scheduleBlock.scheduleBlock, id: scheduleBlock.scheduleBlock.id })
            .then(() => {
                scheduleBlock.scheduleBlock.questionsAnswerd = true
                let block = setBlockInstorage(scheduleBlock.scheduleBlock)
                refreshBadges(block)
            })
            .catch(() => checkForNewQuestions(true))
    }

    const tipRead = (tip_instance_id?: string): void => {
        RepositoryFactory.get(AppScheduleBlockRepository)
            .tipRead({ model: scheduleBlock.scheduleBlock, id: scheduleBlock.scheduleBlock.id }, tip_instance_id)
            .then(() => {
                scheduleBlock.scheduleBlock.tipRead = true
                let block = setBlockInstorage(scheduleBlock.scheduleBlock)
                refreshBadges(block)
            })
            .catch(() => checkForNewQuestions())
    }

    return (
        <ScheduleContext.Provider
            value={{
                scheduleBlock,
                setAnswer,
                checkNetworkAndQuestions,
                sendAnswers,
                tipRead,
                setResults,
                refreshBadges,
                loading,
                isOffline,
                badges,
            }}
        >
            {children}
        </ScheduleContext.Provider>
    )
}

export const useSchedule = (): scheduleContextType => {
    const context = useContext(ScheduleContext)
    if (!context) {
        throw new Error('useSchedule must be used within a ScheduleProvider')
    }
    return context
}
