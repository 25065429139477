import { useEffect, useState } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import NavBarComponent from './components/navBar'
import StartScreen from './components/screens/StartScreen'
import { badges } from './constants/badges'
import { setInitialEnv } from './constants/EnvVars'
import colorSchemes, { dynamicSchemes } from './constants/Colors'
import { styles } from './constants/Layout'
import { text } from './constants/text'
import LoginScreen from './components/screens/loginScreen'
import BadgesScreen from './components/screens/badgesScreen'
import QuestionFeedbackScreen from './components/screens/QuestionFeedbackScreen'
import { initializeClient } from './helpers/keycloakAuth'
import AnswersScreen from './components/screens/answersScreen'
import SettingsScreen from './components/screens/settingsScreen'
import { ProtectedRoute } from './components/protectedRoute'
import useAuth, { AuthContext } from './hooks/useAuth'
import { TenantProvider } from './hooks/tenantContext'
import FeedbackScreen from './components/screens/FeedbackScreen'
import FeedbackResultScreen from './components/screens/feedbackScreenGeneral'
import { ScheduleProvider } from './hooks/scheduleContext'
import HomeScreen from './components/screens/HomeScreen'
import i18n from './i18n/i18n'
import HealthScreen from './components/screens/health'
import log from 'loglevel'
import { INTERVAL_PERCENT, TENANT_STORAGE_KEY, TIMER_ON } from './helpers/constants'
import { settingTheTimer } from './helpers/helpers'
import { envVar } from './hooks/useVars'

window.console.log = log.info
window.console.warn = log.warn
window.console.error = log.error
const originalWarn = log.warn.bind(log)
log.warn = (message, ...rest) => {
    if (message.includes('Warning: <linearGradient />')) {
        // Do nothing, or handle it some other way
    } else {
        originalWarn(message, ...rest)
    }
}
//For production use SILENT
//For development ERROR
//For local development TRACE

log.setLevel(log.levels.TRACE)
let env: envVar
function App() {
    const [effectHasRun, setEffectHasRun] = useState(false)
    let interval_percentage = localStorage.getItem(INTERVAL_PERCENT)
    if (!interval_percentage || typeof interval_percentage === undefined) {
        localStorage.setItem(INTERVAL_PERCENT, '100')
        sessionStorage.setItem(TIMER_ON, 'false')
    } else {
        if (Number(interval_percentage) < 100) {
            settingTheTimer()
        }
    }
    try {
        let currentEnv = setInitialEnv(window.__RUNTIME_CONFIG__.REACT_APP_ENVIRONMENT)
        currentEnv
            ? (env = currentEnv)
            : (env = {
                  baseUrl: '',
                  apiSuffix: '',
                  oidc_clientId: '',
                  oidc_realm: '',
                  oidc_redirectUrl: '',
                  autoDiscoverUrl: '',
              })
    } catch (error) {
        log.error('The REACT_APP_ENVIRONMENT is undefined ', error)
        setInitialEnv(null)
    }

    useEffect(() => {
        const tenantString = sessionStorage.getItem(TENANT_STORAGE_KEY)
        log.info('tenant variable ---> ', TENANT_STORAGE_KEY)

        if (tenantString && typeof tenantString !== undefined) {
            const tenantObject = JSON.parse(tenantString)
            log.info('tenant object ---> ', { ...tenantObject })

            if (tenantObject && Object.keys(tenantObject).length > 0) {
                initializeClient(tenantObject)
            }
        } else {
            log.warn('No tenant data found in storage.')
        }

        setEffectHasRun(true)
        i18n.init()
    }, [])

    const { loggedIn, user, getCurrentUserInfo, updateUser, setUserColor, checkLogin, invalidUser, isFetchingUserData } =
        useAuth(dynamicSchemes())

    return (
        <div className="App">
            {effectHasRun && (
                <AuthContext.Provider
                    value={{
                        loggedIn,
                        invalidUser,
                        user,
                        getCurrentUserInfo,
                        isFetchingUserData,
                        updateUser,
                        setUserColor,
                        checkLogin,
                    }}
                >
                    <ScheduleProvider>
                        <TenantProvider>
                            <BrowserRouter>
                                <div className={'main_frame'}></div>
                                <Routes>
                                    <Route path="/login" element={<LoginScreen />} />
                                    <Route
                                        path="/"
                                        element={
                                            <StartScreen
                                                envVars={env}
                                                colorSchemes={dynamicSchemes()}
                                                styles={styles}
                                                badges={badges}
                                                textInput={text}
                                                featuresInput={undefined}
                                            />
                                        }
                                    />
                                    <Route
                                        path="/answers"
                                        element={
                                            <ProtectedRoute path="/answers">
                                                <NavBarComponent activeRoute={'/answers'} children={<AnswersScreen />} />
                                            </ProtectedRoute>
                                        }
                                    />
                                    <Route path="/health" element={<HealthScreen />} />

                                    <Route
                                        path="/fb-result"
                                        element={
                                            <ProtectedRoute path="/fb-result">
                                                <NavBarComponent activeRoute={'/fb-result'} children={<FeedbackScreen />} />
                                            </ProtectedRoute>
                                        }
                                    />
                                    <Route
                                        path="/feedback/send-question"
                                        element={
                                            <ProtectedRoute path="/feedback/send-question">
                                                <NavBarComponent
                                                    activeRoute={'/feedback/send-question'}
                                                    children={<QuestionFeedbackScreen screenType="QUESTION" />}
                                                />
                                            </ProtectedRoute>
                                        }
                                    />
                                    <Route
                                        path="/feedback/send-tip"
                                        element={
                                            <ProtectedRoute path="/feedback/send-tip">
                                                <NavBarComponent
                                                    activeRoute={'/feedback/send-tip'}
                                                    children={<QuestionFeedbackScreen screenType="TIP" />}
                                                />
                                            </ProtectedRoute>
                                        }
                                    />
                                    <Route
                                        path="/feedback/send-bug"
                                        element={
                                            <ProtectedRoute path="/feedback/send-bug">
                                                <NavBarComponent
                                                    activeRoute={'/feedback/send-bug'}
                                                    children={<QuestionFeedbackScreen screenType="BUG" />}
                                                />
                                            </ProtectedRoute>
                                        }
                                    />
                                    <Route
                                        path="/badges"
                                        element={
                                            <ProtectedRoute path="/badges">
                                                <NavBarComponent activeRoute={'/badges'} children={<BadgesScreen />} />
                                            </ProtectedRoute>
                                        }
                                    />
                                    <Route
                                        path="/settings"
                                        element={
                                            <ProtectedRoute path="/settings">
                                                <NavBarComponent activeRoute={'/settings'} children={<SettingsScreen />} />
                                            </ProtectedRoute>
                                        }
                                    />
                                    <Route
                                        path="/feedback"
                                        element={
                                            <ProtectedRoute path="/feedback">
                                                <NavBarComponent activeRoute={'/feedback'} children={<FeedbackResultScreen />} />
                                            </ProtectedRoute>
                                        }
                                    />

                                    <Route
                                        path="/home"
                                        element={
                                            <ProtectedRoute path="/home">
                                                <NavBarComponent activeRoute={'/home'} children={<HomeScreen />} />
                                            </ProtectedRoute>
                                        }
                                    />
                                </Routes>
                            </BrowserRouter>
                        </TenantProvider>
                    </ScheduleProvider>
                </AuthContext.Provider>
            )}
            {!effectHasRun && <div>Loading...</div>}
        </div>
    )
}

export default App
