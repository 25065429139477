import {  features as defaultFeatures } from '../constants/features'

export type appFeaturesType  = {
  allowResultShare: boolean
}

let appFeatures : appFeaturesType = defaultFeatures

export default function useFeatures(featuresInput ?: appFeaturesType): appFeaturesType {
  if (featuresInput) {appFeatures = featuresInput}

  return appFeatures
}
