import EntityModel from '../../helpers/interfaces/entityModel'
import { BaseEntityModel } from './baseEntityModel'

export type BadgeModelStorage = {
    id: string
    reached: boolean
    label: string
    key: string
    target: number
    amount: number
    progress: number
}

export default class BadgeModel extends BaseEntityModel implements EntityModel<BadgeModel> {
    constructor(
        public id: string,
        public reached: boolean,
        public label: string,
        public key: string,
        public target: number,
        public amount: number,
        public progres: number
    ) {
        super(id)
    }

    public static deserialize(input: any): BadgeModel {
        return new BadgeModel(
            input.id,
            input.reached ? input.reached : false,
            input.label,
            input.key,
            input.target,
            input.amount,
            input.progress
        )
    }

    public serialize(): Object {
        return {}
    }

    public serializeForStorage(): BadgeModelStorage {
        return {
            id: this.id,
            reached: this.reached,
            label: this.label,
            key: this.key,
            target: this.target,
            amount: this.amount,
            progress: this.progres,
        }
    }
}
