import axios, { AxiosRequestConfig, AxiosInstance, AxiosResponse } from 'axios'
import { Buffer } from 'buffer'
import { RepositoryFactory } from './repositoryFactory'
import { getClient, logout } from '../helpers/keycloakAuth'
import { INTERVAL_EXP } from '../helpers/constants'
import log from 'loglevel'
import { getTheInterval } from '../helpers/helpers'

export type interceptorsType = {
    request: (config: AxiosRequestConfig) => Promise<AxiosRequestConfig>
    response: (_error: any) => any
}
export abstract class BaseApiRepository {
    protected axiosInstance: AxiosInstance
    protected publicAxiosInstance: AxiosInstance
    abstract id: string

    constructor() {
        // const config: MasterConfig = getModule(configModule, store).config

        const config = {
            api: RepositoryFactory.getUrl(),
        }

        this.axiosInstance = axios.create({
            baseURL: config.api.baseUrl + '/' + config.api.apiSuffix,
        })
        this.publicAxiosInstance = axios.create({
            baseURL: config.api.baseUrl + '/' + config.api.apiSuffix,
        })

        // Add oidc interceptors
        this.axiosInstance.interceptors.request.use(async (config: AxiosRequestConfig) => {
            try {
                // await getClient().updateToken(5);  // Refresh the token if it's within 5 seconds of expiring
                const token = getClient().token
                if (token) {
                    config.headers = config.headers || {} // Ensure headers is an object
                    config.headers.Authorization = `Bearer ${token}` // Update the Authorization header with the new token
                } else {
                    log.error('No token available')
                    // logout()
                }
            } catch (e) {
                log.error('Failed to refresh token', e)
            }
            return config as any // Cast to any to bypass type checking, or find a more precise type if available
        })

        this.axiosInstance.interceptors.response.use(
            (response) => response,
            async (error) => {
                if (error.response && error.response.status === 401) {
                    try {
                        await getClient().updateToken(3) // refresh the token
                        const token = getClient().token
                        if (token) {
                            const interval = getTheInterval(token)
                            localStorage.setItem(INTERVAL_EXP, interval)
                            log.debug('parsed interval ', interval)

                            // retry the failed request with the new token
                            const config = error.config
                            config.headers.Authorization = `Bearer ${token}`
                            return this.axiosInstance(config)
                        } else {
                            // logout()
                        }
                        // else {
                        //     logout()
                        //     // window.open(window.location.href)
                        // }
                    } catch (e) {
                        // window.open(window.location.href)
                        log.error('Failed to refresh token', e)
                        // logout()
                    }
                }
                // handle other types of errors
                log.error(error)
                return Promise.reject(error)
            }
        )
    }

    private isPublicCall(isPublic: boolean): AxiosInstance {
        return isPublic ? this.publicAxiosInstance : this.axiosInstance
    }

    protected get(endpoint: string, config: AxiosRequestConfig = {}, publicCall: boolean = false): Promise<any> {
        return this.isPublicCall(publicCall)
            .get(endpoint, config)
            .then(function (result: AxiosResponse) {
                // Only return the data of response
                return result.data
            })
    }

    protected getFile(endpoint: string, config: AxiosRequestConfig = {}): Promise<any> {
        config = {
            ...config,
            responseType: 'arraybuffer',
        }
        return this.axiosInstance.get(endpoint, config).then(function (result: AxiosResponse) {
            // Only return the data of response
            return Buffer.from(result.data, 'binary').toString('base64')
        })
    }

    protected post(endpoint: string, data: any, config: AxiosRequestConfig = {}, publicCall: boolean = false): Promise<any> {
        return this.isPublicCall(publicCall)
            .post(endpoint, data, config)
            .then(function (result: AxiosResponse) {
                // Only return the data of response

                return result.data
            })
    }

    protected patch(endpoint: string, data: any, config: AxiosRequestConfig = {}): Promise<any> {
        return this.axiosInstance.patch(endpoint, data, config).then(function (result: AxiosResponse) {
            // Only return the data of response
            return result.data
        })
    }

    protected delete(endpoint: string): Promise<any> {
        return this.axiosInstance.delete(endpoint).then(function (result: AxiosResponse) {
            // Only return the data of response
            return result.data
        })
    }

    private processError(error: any): void {}
}
