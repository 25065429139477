import { FunctionComponent, useContext, useEffect, useRef, useState } from 'react'
import useLoginScreenDisclaimer from '../../hooks/useLoginScreenDisclaimer'
import { AuthContext } from '../../hooks/useAuth'
import { useTenant } from '../../hooks/tenantContext'
import { keycloakLogin } from '../../helpers/keycloakAuth'
import Logo from '../Logo'
import useResponsiveStates from '../../hooks/useResponsiveStates'
import i18n from '../../i18n/i18n'
import log from 'loglevel'
import { setInitialEnv } from '../../constants/EnvVars'
export type loginScreenDisclaimerType = { loginScreenDisclaimer: FunctionComponent }

export default function LoginScreen() {
    const { tenant, checkNetworkAndTenant } = useTenant()
    const { user, loggedIn } = useContext(AuthContext)
    const isRunLocally = useRef<boolean>(false)
    try {
        let isLocal = window.__RUNTIME_CONFIG__.REACT_APP_ENVIRONMENT
        if ((isLocal && isLocal === 'local_dev') || isLocal === 'local') isRunLocally.current = true
    } catch (error) {
        setInitialEnv(null)
        isRunLocally.current = true
    }

    const [activationCode, setActivationCode] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const LoginScreenDisclaimer = useLoginScreenDisclaimer()
    const { isMobile, isTablet } = useResponsiveStates()

    const queryParams = new URLSearchParams(location.search)
    const activate = (getUrl: string | null = null): void => {
        try {
            if (getUrl !== null) {
                checkNetworkAndTenant(getUrl)
            } else {
                checkNetworkAndTenant(activationCode)
            }
            setActivationCode('')
            if (!tenant) {
                setErrorMessage('something went wrong with registration')
            } else {
                setErrorMessage('')
            }
        } catch (error) {
            setErrorMessage('something went wrong with registration')
            log.error(error)
        }
    }

    useEffect(() => {
        if (tenant.version > 0) {
        }
        // Get the activationCode parameter
        const code = queryParams.get('activationCode')
        if (code && typeof code !== undefined) {
            activate(code)
        }
        return () => {
            setActivationCode('')
            setErrorMessage('')
        }
    }, [])

    return (
        <div
            style={{
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                minHeight: window.innerHeight,
                flexDirection: 'column',
                backgroundColor: tenant ? tenant.tenant.backgroundcolor : 'transparent',
            }}
        >
            <p className="text-danger bottom-100">{errorMessage} </p>
            {isRunLocally.current && tenant.version === 0 && (
                <input
                    type="text"
                    className="activation_code form-control"
                    placeholder={i18n.t('enterCodePlaceholder')}
                    value={activationCode}
                    style={{ maxWidth: isMobile ? '70%' : isTablet ? '70%' : '30%' }}
                    onChange={(e) => {
                        if (e !== undefined && e !== null) {
                            setActivationCode(e.target.value)
                        }
                    }}
                />
            )}
            {isRunLocally.current && activationCode !== '' ? (
                <button style={{ margin: 15, minWidth: '25%' }} className="btn btn-outline-dark p-2 " onClick={() => activate()}>
                    {i18n.t('activate')}
                </button>
            ) : (
                <div />
            )}
            {!isRunLocally.current && tenant.version === 0 && (
                <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
                    <Logo type={'start'} />
                    <div style={{ marginBottom: 10, marginTop: 10 }}>{i18n.t('useLinkToLogin')}</div>
                    <LoginScreenDisclaimer.loginScreenDisclaimer />
                </div>
            )}
            {user.failed && loggedIn ? (
                <div />
            ) : tenant.tenant.slug === undefined ? (
                <div />
            ) : (
                <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
                    <Logo type={'start'} />
                    <button
                        onClick={() => keycloakLogin()}
                        className="btn btn-outline-dark p-2 text-light"
                        style={{ margin: 15, minWidth: '25%' }}
                    >
                        {i18n.t('start')}
                    </button>
                    <LoginScreenDisclaimer.loginScreenDisclaimer />
                </div>
            )}
        </div>
    )
}
