import * as React from 'react'
import { Text as NativeText } from 'react-native-web'
import i18n from '../i18n/i18n'

export type loginScreenDisclaimerType = { loginScreenDisclaimer: React.FunctionComponent }

let LoginScreenDisclaimerStart: loginScreenDisclaimerType = {
    loginScreenDisclaimer: () => {
        return (
            <div style={{ width: '80%' }}>
                <NativeText style={{ textAlign: 'center' }}>
                    {i18n.t('disclaimer1')}&nbsp;
                    <NativeText
                        onPress={() => window.open(i18n.t('privacyPolicyLink'), '_blank')}
                        style={{ textDecorationLine: 'underline' }}
                    >
                        {i18n.t('disclaimer4')}{' '}
                    </NativeText>
                    {i18n.t('disclaimer5')}
                    <div style={{ marginTop: 200, marginBottom: 50 }} />
                </NativeText>
            </div>
        )
    },
}

export default function useLoginScreenDisclaimer(
    loginScreenDisclaimerInput?: loginScreenDisclaimerType
): loginScreenDisclaimerType {
    if (loginScreenDisclaimerInput) {
        LoginScreenDisclaimerStart = loginScreenDisclaimerInput
    }

    return LoginScreenDisclaimerStart
}
