import Keycloak from 'keycloak-js'
import {
    INTERVAL_EXP,
    KEY_AUTH_ACCESS_TOKEN,
    KEY_AUTH_REFRESH_TOKEN,
    NEW_INTERVAL_EXP,
    TIMER_ON,
    TENANT_STORAGE_KEY,
    INTERVAL_PERCENT,
    NEW_INTERVAL_EXP_TIMER,
} from '../helpers/constants'
import log from 'loglevel'
import { calculateMinutesOfTime, getTheInterval, settingTheTimer } from './helpers'
import { env } from '../constants/EnvVars'

export let client: any
export let isRun: boolean
export function keycloakInitConfigParser(tenantObject: any) {
    return {
        redirectUri: env.oidc_redirectUrl,
        checkLoginIframe: false,
        checkLoginIframeInterval: 5,
    }
}
export function kecloakConfigParser(tenantObject: any) {
    let corrected_uri: string = tenantObject.oidc_realm.split('/auth')[1].startsWith('.')
        ? tenantObject.oidc_realm.split('/auth')[0] + '/auth.inuits.io'
        : tenantObject.oidc_realm.split('/auth')[0] + '/auth'

    const keycloakConfig = {
        name: tenantObject.name,
        url: corrected_uri,
        realm: tenantObject.oidc_realm.split('/realms/')[1].split('/')[0],
        clientId: tenantObject.oidc_clientId,
        redirectUri: env.oidc_redirectUrl,
        checkLoginIframe: false,
        checkLoginIframeInterval: 5,
    }
    return keycloakConfig
}
export function settingEnvVariables() {
    // ;(client as Keycloak).onAuthSuccess
    //     ? () => {
    log.info('Authentication Success')
    sessionStorage.setItem(KEY_AUTH_ACCESS_TOKEN, client.token)
    try {
        const interval = getTheInterval(client.token)
        log.info('time from the token ', interval)
        sessionStorage.setItem(INTERVAL_EXP, interval)
        // localStorage.setItem(NEW_INTERVAL_EXP, interval)
        const timer_percentage = Number(localStorage.getItem(INTERVAL_PERCENT))
        // const timer_on = Boolean(localStorage.getItem(TIMER_ON))
        if (timer_percentage && timer_percentage < 100) {
            const new_time = calculateMinutesOfTime(Number(interval), timer_percentage)
            sessionStorage.setItem(NEW_INTERVAL_EXP, new_time.toString())
            // const timerIsOne = Boolean(localStorage.getItem(TIMER_ON))
            // log.debug('TIMER from keycloak ', timerIsOne)
            // if (!timerIsOne) {
            sessionStorage.setItem(NEW_INTERVAL_EXP_TIMER, new_time.toString())
            sessionStorage.setItem(TIMER_ON, 'true')
            // log.debug('inside the keycloak setting the timer ', new_time)
            settingTheTimer()
            // }
        }
        log.debug('parsed interval ', interval)
        sessionStorage.setItem(KEY_AUTH_REFRESH_TOKEN, client.refreshToken)
    } catch (error) {
        log.error('error in parsing or setting interval ', error)
    }
    //   }
    // : null
}
export const initializeClient = async (tenantObject: any) => {
    if (isRun) return
    try {
        log.info('Initilizing keycloak started...')
        isRun = true

        client = new Keycloak(kecloakConfigParser(tenantObject))
        log.info('keycloak !!!!! ', client)
        await client.init(keycloakInitConfigParser(tenantObject))

        const acces_t = sessionStorage.getItem(KEY_AUTH_ACCESS_TOKEN)
        const refresh_t = sessionStorage.getItem(KEY_AUTH_REFRESH_TOKEN)

        if (acces_t && refresh_t) {
            client.token = sessionStorage.getItem(KEY_AUTH_ACCESS_TOKEN)
            client.refreshToken = sessionStorage.getItem(KEY_AUTH_REFRESH_TOKEN)
        }

        log.info('Initilizing keycloak completed!')

        client.onAuthRefreshError = () => {
            keycloakLogin()
        }
    } catch (error) {
        log.error('error initialization keycloak ', error)
    }
}

export const getClient = (): Keycloak => {
    return client
}

export const setIsRun = (flag: boolean) => {
    isRun = flag
}

export const keycloakLogin = async () => {
    log.debug('FROM LOGIN ! uri ', env.oidc_redirectUrl)
    try {
        getClient().login({ redirectUri: env.oidc_redirectUrl })
    } catch (error) {
        log.error('error from keycloack ', error)
        getClient().logout({ redirectUri: window.location.href })
    }
}

export const logout = () => {
    if (client) {
        const tenantString = sessionStorage.getItem(TENANT_STORAGE_KEY)
        let logout_url: string | null = null
        if (tenantString && typeof tenantString !== undefined) {
            const tenantObject = JSON.parse(tenantString)
            logout_url = tenantObject.oidc_redirectUrl
        }
        logout_url ? client.logout({ redirectUri: logout_url }) : client.logout({ redirectUri: window.location.href })
        sessionStorage.clear()
        localStorage.removeItem(NEW_INTERVAL_EXP)
        localStorage.setItem(TIMER_ON, 'false')
        client.logout({ redirectUri: window.location.href })
    }
}

export const isAuthenticated = () =>
    (getClient().onAuthSuccess = () => {
        return true
    })

export const isAuthenticatedAsync = async () => {
    return new Promise((resolve, reject) => {
        const client = getClient()

        client.onAuthSuccess = () => {
            resolve(true)
        }

        client.onAuthError = () => {
            resolve(false)
        }
    })
}
