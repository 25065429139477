import { ResultRepository } from '../../repositories/entities/resultRepository'
import QuestionModel from '../../models/entities/questionModel'
import ResultModal from '../../models/entities/resultModal'
import { useCallback, useEffect, useState } from 'react'
import { ResultCard } from '../../components/cards/result'
import { View } from 'react-native-web'
import { useSchedule } from '../../hooks/scheduleContext'
import useBaseRepository from '../../hooks/useRepository'
import i18n from '../../i18n/i18n'
import log from 'loglevel'

export default function ResultScreen() {
    const { scheduleBlock, setResults } = useSchedule()
    const { doCall: getResults, loading } = useBaseRepository<ResultModal>(ResultRepository, 'getSingle', {
        id: 'active_results',
        lang: i18n.language.split('-')[0],
    })
    const [results, setResultsInComponent] = useState<QuestionModel[]>()
    const answers = useCallback(() => {
        if (scheduleBlock.scheduleBlock.results) {
            setResultsInComponent(scheduleBlock.scheduleBlock.results.questions)
        } else {
            getResults()
                .then((result: ResultModal | ResultModal[]) => {
                    if (!Array.isArray(result)) {
                        setResults(result)
                        setResultsInComponent(result.questions)
                    }
                })
                .catch((er) => {
                    log.error('an error in answersScreen', er)

                    setResultsInComponent([])
                })
        }
    }, [scheduleBlock.scheduleBlock.results])

    useEffect(() => {
        answers()
    }, [scheduleBlock.scheduleBlock.results])

    return (
        <View parent={true} style={{ minHeight: window.innerHeight }}>
            {
                <div style={{ margin: 0, padding: 0 }}>
                    {results && results.map((question: QuestionModel) => <ResultCard question={question} key={question.id} />)}
                    {!loading && results?.length === 0 && <div style={{ marginTop: '20%' }}>{i18n.t('noResultsForToday')}</div>}
                </div>
            }
        </View>
    )
}
