export default {
    startScreen: "Deel anoniem jouw mening en ontdek wat andere collega's denken",
    userInfoTitle: 'Nog 2 snelle vragen',
    userInfoIntro:
        "AskForceOne gebruikt deze info alleen om te zien wat collega's binnen jouw bedrijf denken.\n\nDe app bekijkt geen individuele gebruikers en deelt deze info niet. ",
    userInfoBtnLabel: 'Klaar!',
    bottomTabHome: 'Home',
    bottomTabResults: 'Antwoorden',
    bottomTabBadges: 'Badges',
    bottomTabFeedback: 'Feedback',
    thxAnswers: 'Dank je wel voor je antwoorden',
    nextQuestionsOn: ', de volgende vragen verschijnen op ',
    atThisScheduleTime: ' om 17:00',
    readTipBtn: 'Lees tip',
    watchTipBtn: 'Bekijk video',
    CtaResults: "Zie wat andere collega's op de vorige vragen antwoordden",
    CtaFeedback: 'Welke vraag wil jij in de app zien?',
    CtaNotifications1: 'Je meldingen staan uit. Wil je een ',
    CtaNotifications2: 'melding krijgen',
    CtaNotifications3: ' als er nieuwe vragen zijn? \n\n Je kan dit altijd nog aanpassen in de instellingen.',
    BadgeScreenTitle: 'Goed bezig!',
    ResultScreenTitle: 'Antwoorden op de vorige vragen',
    FeedbackScreenTitle: 'Feedback',
    FeedbackScreenIntro: 'Heb je tips voor of vragen over de app?\nTijdens de testfase kan je feedback geven',
    NotificationsDisabled: 'Meldingen voor deze app zijn afgezet in uw systeeminstellingen.',
    NotificationsAllowed: 'AskForceOne mag mij meldingen sturen',
    NotificationsSettingsTitle: 'Meldingen',
    NotificationsSettingsLink: 'Zet dit aan in de instellingen van uw telefoon.',
    OfflineTitle: 'AskForceOne is offline',
    OfflineText1: 'Zet je ',
    OfflineText2: 'wifi of data even uit en weer aan',
    OfflineText3: ', en probeer opnieuw.',
    OfflineText4: 'Niet opgelost?',
    OfflineText5: 'Dan is de app zelf offline en zijn we al hard aan het werken om die weer online te krijgen.',
    OfflineCta1: 'Kom later nog eens terug',
    OfflineCta2: 'Dan weet je het meteen als AskForceOne weer online is.',
    OfflineCtaLinkText: 'volg Inuits op Twitter.',
    OfflineCtaLink: 'https://twitter.com/inuits',
    OfflineButton: 'Probeer opnieuw',
    tipRead: 'Gelezen',
    tipTitle: 'Tip van de \ndag',
    feedbackMsg: 'Bedankt voor uw feedback',
    BadgeColorInfoTitle: 'Kleur van de app veranderen?\n',
    BadgeColorInfo: 'Klik op een van de badges die je al behaalde.',
    BadgeColorInfoTitleNotReached: 'Wat kan ik doen met die badges?\n\n',
    BadgeColorInfoNotReached1: 'Met een badge ',
    BadgeColorInfoNotReached2: 'verander',
    BadgeColorInfoNotReached3: ' je de ',
    BadgeColorInfoNotReached4: 'kleuren',
    BadgeColorInfoNotReached5: ' in de app. Zodra je een badge hebt behaald, klik je erop en klaar! Verzamel jij alle kleuren?',
    next: 'Volgende',
    nextQuestion: 'Volgende vraag',
    questionProposedBy: 'vraag gesteld door ',
    shareAnonymously: 'deel anoniem op social media ',
    questionsAnsweredText: 'vragen beantwoord',
    tipsViewed: 'tips bekeken',
    day: 'dag',
    days: 'dagen',
    currentStreak: 'Streak van',
    badgesEarned: 'Behaalde badges',
    achievable: 'Nog te behalen',
    appNeedsUpdate: 'De app moet bijgewerkt worden.',
    whatHappensWithMyAnswers: 'Wat gebeurt er met mijn antwoorden?',
    badgesAlmostAchieved: 'Deze badges zijn bijna binnen',
    extraInfo: 'Extra info of hulp',
    seeAllInfoLines: 'Zie alle infolijnen voor jongeren',
    askThroughChatMailPhone: 'Stel je vraag via chat, mail, telefoon of ga langs.',
    turnOn: 'zet aan',
    no: 'Nee',
    questionProposal1: 'Stuur vraag in',
    questionProposal2: "Wat wil jij vragen aan andere collega's?",
    questionProposal3: 'Wil je nog iets zeggen? ',
    questionProposal4: 'Stuur je vraag in',
    tipProposal1: 'Stuur tip in',
    tipProposal2: "Welke tip wil jij meegeven aan andere collega's?",
    tipProposal3: 'Wil je nog iets zeggen bij deze tip?',
    tipProposal4: ' Stuur tip in',
    general1: 'Zoek je hulp?',
    general2: 'Wat wil jij aan de makers van AskForceOne vragen?',
    general3: 'Je vraag of suggestie',
    general4: 'Stuur vraag of suggestie',
    bugReport1: 'Meld fout in de app',
    bugReport2: '',
    bugReport3: 'Wat loopt er fout in de app?',
    bugReport4: 'Meld fout',
    yourQuestion: 'Jouw vraag',
    possibleAnswers: 'Mogelijke antwoorden op je vraag',
    answerToYourQuestion: 'Antwoord ',
    addAnswer: 'Voeg antwoord toe',
    maxOf6Answers: 'Er is een maximum van 6 antwoorden',
    linkToTip: 'Link naar tip',
    yourName: 'Je naam',
    myNameCanBeShown: 'Mijn naam mag vermeld worden.',
    anonymous: 'Stuur anoniem',
    canContactMe: 'AskForceOne mag mij contacteren',
    yourMailOrPhone: 'Je e-mailadres of gsm-nummer',
    fillInOneOfTwo: 'Vul één van de twee',
    provideValidMail: 'Geef een geldig emailadres in',
    yourPhoneNumber: 'Gsm-nummer',
    provideValidPhone: 'Geef een geldig telefoonnummer in',
    noResultsForToday: 'Geen resultaten voor vandaag',
    settingsTitle: 'Instellingen',
    userDataTitle: 'Werk gebruikersinformatie bij',
    privacyStatementTitle: 'Privacy statement',
    tAndCTitle: 'Terms and conditions',
    standardColorTitle: 'Zet standaard kleur terug',
    logoutTitle: 'Log out',
    answersText: 'antwoorden',
    weightedBasedOn: 'Gewogen op basis van ',
    checkBadges: 'Bekijk badges ',
    badgeTitleWelcome: 'Welkom',
    badgeTitleQuestions: 'Vragen beantwoord',
    badgeTitleTips: 'Tips bekeken',
    badgeTitleStreak: 'Streak',
    thxFeedbackQuestion1: 'Bedankt voor je vraag',
    thxFeedbackTip1: 'Bedankt voor je tip',
    thxFeedbackGeneral1: 'Bedankt voor je vraag of suggestie',
    thxFeedbackBug1: 'Bedankt om een fout te melden',
    thxFeedbackQuestion2: 'Misschien zie je die binnenkort in de app!',
    thxFeedbackTip2: 'Misschien zie je die binnenkort in de app!',
    thxFeedbackGeneral2: 'We gaan er snel mee aan de slag!',
    thxFeedbackBug2: 'Zo help je om de app te verbeteren!',
    disclaimer1: 'Ter info: om de app te gebruiken, moet je akkoord gaan met het',
    disclaimer2: 'gebruiksvoorwaarden ',
    disclaimer3: 'en het',
    disclaimer4: 'privacybeleid',
    disclaimer5: ' van AskForceOne.',
    disclaimer6: 'Ben je een pinguïn? Dan moet een van je verzorgers toestemming geven.',
    disclaimer7: 'Je krijgt hier in ',
    disclaimer8: 'de volgende stap meer info',
    disclaimer9: ' over, voor je een account aanmaakt.',
    privacyPolicyLink: 'https://askforce.one/privacy-policy/',
    //------------
    contactedByApp: 'Er wordt contact met je opgenomen via je sollicitatie en, indien je dat wenst, ', //check also the native app
    activationChoise: 'Je kunt kiezen tussen het gebruik van activatiecode',
    enterCodePlaceholder: 'Vul hier een activatiecode in!',
    activate: 'Activeren',
    scanQRCode: 'QR-code scannen',
    stopCamera: 'Camera stoppen',
    start: 'Begin',
    or: 'of',
    timer: 'Stel het tijdstip in waarop de login verloopt',
    timerReset: 'De timer resetten',
    useLinkToLogin: 'Gebruik de link die je in je mailbox hebt ontvangen om het inlogproces te starten.',
}
