import FileModel from '../../models/entities/fileModel'
import { BaseRepository } from '../baseRepository'

export type fileReturn = {
  id: string,
  url: string
}

export class FileRepository extends BaseRepository<FileModel> {
  id = '/files/'
  endpoint = '/files/'
  entityModel = FileModel
  publicGet = false

  uploadFile(file: any): Promise<fileReturn> {
    const fd = new FormData()
    fd.append('upload', file)

    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }
    return this.post(this.endpoint + 'upload/', fd, config).then((response: fileReturn) => {
      return response
    })
  }

  downloadFile(imageId: string): Promise<any> {
    return this.get(this.endpoint + 'download/' + imageId)
  }

  getFileInBase64(fileId: string): Promise<string> {
    return this.getFile(this.endpoint + 'download/' + fileId)
  }

  getShareImage(id: string, color1: string, color2: string, tint: string): Promise<string> {
    return this.getFile('/schedule_blocks/active_question_result_chart/' + id + '/', {
      params: {
        color_1: this.hexToRGB(color1),
        color_2: this.hexToRGB(color2),
        tint: this.hexToRGB(tint)
      }
    })
  }

  hexToRGB(h: any) {
    let r: any = 0; let g: any = 0; let b: any = 0

    // 3 digits
    if (h.length === 4) {
      r = '0x' + h[1] + h[1]
      g = '0x' + h[2] + h[2]
      b = '0x' + h[3] + h[3]

      // 6 digits
    } else if (h.length === 7) {
      r = '0x' + h[1] + h[2]
      g = '0x' + h[3] + h[4]
      b = '0x' + h[5] + h[6]
    }

    return 'rgba(' + +r + ',' + +g + ',' + +b + ', 1)'
  }
}
