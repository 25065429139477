import { useState, useEffect } from 'react'

const useResponsiveStates = () => {
    const [isBigScreen, setIsBigScreen] = useState(window.innerWidth >= 1824)
    const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 992 && window.innerWidth < 1824)
    const [isTablet, setIsTablet] = useState(window.innerWidth >= 768 && window.innerWidth < 992)
    const [isPortrait, setIsPortrait] = useState(window.matchMedia('(orientation: portrait)').matches)
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768)
    const [isRetina, setIsRetina] = useState(window.devicePixelRatio >= 2)

    useEffect(() => {
        const handleResize = () => {
            setIsBigScreen(window.innerWidth >= 1824)
            setIsDesktop(window.innerWidth >= 992 && window.innerWidth < 1824)
            setIsTablet(window.innerWidth >= 768 && window.innerWidth < 992)
            setIsPortrait(window.matchMedia('(orientation: portrait)').matches)
            setIsMobile(window.innerWidth < 768)
            setIsRetina(window.devicePixelRatio >= 2)
        }

        window.addEventListener('resize', handleResize)
        window.addEventListener('orientationchange', handleResize)

        handleResize()

        return () => {
            window.removeEventListener('resize', handleResize)
            window.removeEventListener('orientationchange', handleResize)
        }
    }, [])

    return { isBigScreen, isDesktop, isTablet, isPortrait, isMobile, isRetina }
}

export default useResponsiveStates
