import { View } from 'react-native-web'
import { Text as NativeText } from 'react-native-web'
import useColorScheme from '../../hooks/useColorScheme'
import { useCallback } from 'react'
import { useSchedule } from '../../hooks/scheduleContext'
import BadgeCard from '../../components/cards/BadgeCard'
import BadgeModel from '../../models/entities/BadgeModel'
import CtaCard from '../../components/cards/ctaCard'
import i18n from '../../i18n/i18n'
import { FcIdea } from 'react-icons/fc'
import { fontSize, iconSize, pageNormalFontSize, pageTitleFontSize } from '../../constants/Layout'
import useResponsiveStates from '../../hooks/useResponsiveStates'
import { FaThumbsUp } from 'react-icons/fa'

type StatusLineType = {
    number: number
    text: string
    beforeText?: string
    marginLeft?: number
    marginRight?: number
    adjustText?: string
}

export function StatusLine({ number, text, beforeText, marginLeft, marginRight, adjustText = 'start' }: StatusLineType) {
    const { getByStyleProp } = useColorScheme()
    const { isMobile } = useResponsiveStates()
    const color: string = getByStyleProp('text')

    return (
        <div
            style={{ display: 'flex', flexDirection: 'row', margin: 0, width: '100%', justifyContent: adjustText, color: color }}
        >
            {beforeText && (
                <div
                    style={{
                        marginRight: marginRight ? marginRight : 6,
                        fontFamily: 'Montserratlight',
                        fontSize: pageNormalFontSize,
                        color: color,
                    }}
                >
                    {beforeText}
                </div>
            )}
            <div
                style={{
                    color: color,
                    fontSize: pageNormalFontSize,
                    marginLeft: marginLeft ? marginLeft : 0,
                    marginTop: isMobile ? 0 : 2,
                }}
            >
                {number}
            </div>
            <div style={{ marginLeft: 5, fontFamily: 'Montserratlight', fontSize: pageNormalFontSize }}>{text}</div>
        </div>
    )
}

export default function BadgesScreen() {
    const { scheduleBlock, badges, refreshBadges } = useSchedule()
    const filterBadgeReached = (value: BadgeModel) => value.reached === true
    const filterBadgeNotReached = (value: BadgeModel) => value.reached === false
    const { isMobile } = useResponsiveStates()

    const getBadgeLists = useCallback(() => {
        if (refreshBadges && scheduleBlock && (!badges || badges.length < 1)) {
            refreshBadges(scheduleBlock.scheduleBlock)
        }
        const reached = badges?.filter(filterBadgeReached) || []
        const notReached = badges?.filter(filterBadgeNotReached) || []
        return { reachedBadges: reached, notReachedBadges: notReached }
    }, [badges])

    const { reachedBadges, notReachedBadges } = getBadgeLists()

    return (
        <View parent={true}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {scheduleBlock.scheduleBlock.badgesStatus && (
                        <div>
                            {isMobile ? (
                                <div>
                                    <div style={{ textAlign: 'center', marginTop: 15 }}>
                                        <div style={{ fontFamily: 'Montserratbold', fontSize: fontSize }}>
                                            {i18n.t('BadgeScreenTitle')}
                                        </div>
                                    </div>
                                    <div style={{ justifyItems: 'center' }}>
                                        <StatusLine
                                            number={scheduleBlock.scheduleBlock.badgesStatus.questionsAnswered}
                                            text={i18n.t('questionsAnsweredText')}
                                            adjustText="center"
                                        />
                                        <StatusLine
                                            number={scheduleBlock.scheduleBlock.badgesStatus.tipsRead}
                                            text={i18n.t('tipsViewed')}
                                            adjustText="center"
                                        />
                                        <StatusLine
                                            beforeText={i18n.t('currentStreak')}
                                            adjustText="center"
                                            number={scheduleBlock.scheduleBlock.badgesStatus.currentResponseStreak}
                                            text={
                                                scheduleBlock.scheduleBlock.badgesStatus.currentResponseStreak === 1
                                                    ? i18n.t('day')
                                                    : i18n.t('days')
                                            }
                                        />
                                    </div>
                                </div>
                            ) : (
                                <CtaCard
                                    //@ts-ignore
                                    label={
                                        <div style={{ marginLeft: 0 }}>
                                            <div style={{}}>
                                                <div style={{ fontFamily: 'Montserratbold', fontSize: pageTitleFontSize }}>
                                                    {i18n.t('BadgeScreenTitle')}
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'left' }}>
                                                <StatusLine
                                                    number={scheduleBlock.scheduleBlock.badgesStatus.questionsAnswered}
                                                    text={i18n.t('questionsAnsweredText')}
                                                />
                                                <StatusLine
                                                    number={scheduleBlock.scheduleBlock.badgesStatus.tipsRead}
                                                    text={i18n.t('tipsViewed')}
                                                />
                                                <StatusLine
                                                    beforeText={i18n.t('currentStreak')}
                                                    number={scheduleBlock.scheduleBlock.badgesStatus.currentResponseStreak}
                                                    text={
                                                        scheduleBlock.scheduleBlock.badgesStatus.currentResponseStreak === 1
                                                            ? i18n.t('day')
                                                            : i18n.t('days')
                                                    }
                                                />
                                            </div>
                                        </div>
                                    }
                                    icon={<FaThumbsUp size={iconSize} style={{ marginLeft: isMobile ? 15 : 5 }} />}
                                    primary={false}
                                />
                            )}
                        </div>
                    )}
                    {reachedBadges && reachedBadges.length > 0 && (
                        <BadgeCard
                            badges={reachedBadges}
                            title={i18n.t('badgesEarned')}
                            beingAbleToClose={isMobile ? false : true}
                        />
                    )}
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <CtaCard
                        //@ts-ignore
                        label={
                            <div
                                style={{
                                    fontFamily: 'Montserratlight',
                                    fontSize: pageNormalFontSize,
                                    padding: isMobile ? 0 : 5,
                                }}
                            >
                                {scheduleBlock.scheduleBlock.badgesStatus &&
                                scheduleBlock.scheduleBlock.badgesStatus.badges.length > 0 ? (
                                    <NativeText style={{ fontFamily: 'Montserratlight', fontSize: pageTitleFontSize }}>
                                        <NativeText style={{ fontFamily: 'Montserratbold', fontSize: pageTitleFontSize }}>
                                            {i18n.t('BadgeColorInfoTitle')}
                                        </NativeText>
                                        {i18n.t('BadgeColorInfo')}
                                    </NativeText>
                                ) : (
                                    <NativeText style={{ fontFamily: 'Montserratlight', fontSize: pageNormalFontSize }}>
                                        <NativeText style={{ fontFamily: 'Montserratbold', fontSize: pageNormalFontSize }}>
                                            {i18n.t('BadgeColorInfoTitleNotReached')}
                                        </NativeText>
                                        {i18n.t('BadgeColorInfoNotReached1')}
                                        <NativeText style={{ fontFamily: 'Montserratbold', fontSize: pageNormalFontSize }}>
                                            {i18n.t('BadgeColorInfoNotReached2')}
                                        </NativeText>
                                        {i18n.t('BadgeColorInfoNotReached3')}
                                        <NativeText style={{ fontFamily: 'Montserratbold', fontSize: pageNormalFontSize }}>
                                            {i18n.t('BadgeColorInfoNotReached4')}
                                        </NativeText>
                                        {i18n.t('BadgeColorInfoNotReached5')}
                                    </NativeText>
                                )}
                            </div>
                        }
                        icon={<FcIdea size={iconSize} style={{ marginLeft: isMobile ? 0 : 10 }} />}
                        primary={false}
                    />
                    {notReachedBadges && (
                        <BadgeCard
                            badges={notReachedBadges}
                            title={i18n.t('achievable')}
                            beingAbleToClose={isMobile ? false : true}
                        />
                    )}
                </div>
                <View parent={false} style={{ height: 100 }} />
            </div>
        </View>
    )
}
