import EntityModel from '../../helpers/interfaces/entityModel'

export class BaseEntityModel implements EntityModel<BaseEntityModel> {
    constructor(public id?: string) {
        return this
    }

    public static deserialize(input: any): BaseEntityModel {
        return new BaseEntityModel(input.id)
    }

    public serialize(): Object {
        return {}
    }

    public static mapEntityArray(inputArray: any[], entityModel: any): any[] {
        const returnArray: BaseEntityModel[] = []

        inputArray &&
            inputArray.forEach((element: any) => {
                returnArray.push(entityModel.deserialize(element))
            })

        return returnArray
    }

    public static StringToFloat(input: string): number {
        return parseFloat(input)
    }

    public static FloatToString(input: number): string {
        return input.toString()
    }
}
