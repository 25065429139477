import EntityModel from '../../helpers/interfaces/entityModel'
import { BaseEntityModel } from '../../models/entities/baseEntityModel'
import moment from 'moment'

export enum FeedbackScreenTypes {
    QUESTION = 'QUESTIONPROPOSAL',
    TIP = 'TIPPROPOSAL',
    GENERAL = 'GENERAL',
    BUG = 'BUGREPORT',
}

export default class FeedbackModel extends BaseEntityModel implements EntityModel<FeedbackModel> {
    constructor(
        public id?: string,
        public type?: FeedbackScreenTypes,
        public email?: string,
        public phone?: string,
        public remark?: string,
        public questionOrTip?: string,
        public answers?: string[],
        public createdAt?: string,
        public content?: string,
        public proposedBy?: string,
        public isAnonymous?: boolean,
        public status?: string,
        public message?: string,
        public title?: string
    ) {
        super(id)
    }

    public static deserialize(input: any): FeedbackModel {
        return new FeedbackModel(
            input.id,
            input.type,
            input.email,
            input.phone_number,
            input.comment,
            input.questionOrTip,
            input.question_answers,
            input.created_at && moment(input.created_at).format('YYYY-MM-DD H:mm'),
            input.content,
            input.proposed_by,
            input.is_anonymous,
            input.status && input.status.value
        )
    }

    public serialize(): Object {
        return {
            type: this.type,
            comment: this.remark,
            email: this.email,
            phone_number: this.phone,
            content: this.questionOrTip ? this.questionOrTip : '',
            question_answers: this.answers,
            is_anonymous: this.isAnonymous,
            proposed_by: this.proposedBy,
        }
    }
}
