import { FunctionComponent } from 'react'
import BaseCard from '../components/cards/baseCard'
import i18n from '../i18n/i18n'
import { useNavigate } from 'react-router-dom'
import CtaCard from '../components/cards/ctaCard'
import { RiBarChart2Fill } from 'react-icons/ri'
import { iconSize, normalizeFontSize, pageNormalFontSize } from '../constants/Layout'
import { FeedbackScreenTypes } from '../models/entities/feedbackModel'
import { IoIosHelpCircle } from 'react-icons/io'

export type HomeScreens = { homeScreenTop: FunctionComponent; homeScreenBottom: FunctionComponent }

let HomeScreenStart: HomeScreens = {
    homeScreenTop: () => {
        const navigation = useNavigate()

        return (
            <CtaCard
                label={i18n.t('CtaResults')}
                icon={<RiBarChart2Fill size={iconSize} />}
                onClick={() => {
                    navigation('/answers')
                }}
                primary={false}
            />
        )
    },
    homeScreenBottom: () => {
        const navigation = useNavigate()

        return (
            <CtaCard
                label={i18n.t('CtaFeedback')}
                onClick={() => {
                    navigation('/feedback/send-question')
                }}
                icon={<IoIosHelpCircle size={iconSize} />}
                primary={false}
            />
            // <BaseCard
            //     title={
            //         <div>
            //             <div style={{ textAlign: 'center', fontSize: pageNormalFontSize, marginBottom: 15 }}>
            //                 {i18n.t('extraInfo')}
            //             </div>
            //         </div>
            //     }
            //     buttonLabel={i18n.t('seeAllInfoLines')}
            //     route={() => {
            //         window.open('https://www.watwat.be/hulp?utm_source=Waddist-app&utm_medium=referral', '_blank')
            //     }}
            // >
            //     <div>
            //         <div style={{ textAlign: 'left', marginBottom: 15 }}>{i18n.t('askThroughChatMailPhone')}</div>
            //     </div>
            // </BaseCard>
        )
    },
}

export default function useHomeScreen(homeScreenInput?: HomeScreens): HomeScreens {
    if (homeScreenInput) {
        HomeScreenStart = homeScreenInput
    }

    return HomeScreenStart
}
