import EntityModel from '../../helpers/interfaces/entityModel'
import { BaseEntityModel } from './baseEntityModel'

export default class FileModel extends BaseEntityModel implements EntityModel<FileModel> {
    constructor(public id?: string) {
        super(id)
    }

    public static deserialize(input: any): FileModel {
        return new FileModel(input.id)
    }
}
