import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import i18n from '../../i18n/i18n'
import { refreshStyle } from '../../helpers/helpers'
import useStyles from '../../hooks/useStyles'
import useColorScheme from '../../hooks/useColorScheme'
import useResponsiveStates from '../../hooks/useResponsiveStates'
import { IoIosHelpCircle, IoIosBug, IoIosAlert } from 'react-icons/io'

function FeedbackScreen() {
    const { pageButton } = useStyles()
    const { getByStyleProp } = useColorScheme()
    const { isMobile } = useResponsiveStates()

    const textColor = getByStyleProp('text')
    useEffect(() => {
        refreshStyle()
    }, [])

    return (
        <div
            style={{
                minHeight: isMobile ? '90vh' : '84vh',
                padding: !isMobile ? '5%' : 0,
            }}
        >
            <div className="spacer" style={{ height: !isMobile ? 22 : 0 }}></div>
            <div
                style={{
                    minHeight: '0vh',
                    overflow: 'hidden',
                    display: 'grid',
                    gridTemplateColumns: '1fr',
                    marginLeft: isMobile ? '1rem' : '',
                    marginRight: isMobile ? '1rem' : '',
                    paddingTop: isMobile ? '1rem' : '',
                    gap: '20px',
                    maxWidth: '30',
                }}
            >
                <Link
                    to="/feedback/send-question"
                    className="btn btn-outline-light border-0 p-2 text-dark"
                    style={{
                        color: textColor,
                        ...pageButton,
                        background: 'white',
                        height: '10rem',
                        margin: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: '2rem',
                        fontSize: '1rem',
                        justifyContent: 'center',
                    }}
                >
                    <IoIosHelpCircle
                        style={{
                            marginLeft: 0,
                            paddingTop: !isMobile ? 10 : 0,
                            // color: 'black',
                        }}
                        size={80}
                    />
                    {i18n.t('questionProposal1')}
                </Link>
                <Link
                    to="/feedback/send-tip"
                    className="btn btn-outline-light border-0 p-2 text-dark"
                    style={{
                        color: textColor,
                        ...pageButton,
                        background: 'white',
                        height: '10rem',
                        margin: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: '2rem',
                        fontSize: '1rem',
                        justifyContent: 'center',
                    }}
                >
                    <IoIosAlert
                        style={{
                            marginLeft: 0,
                            paddingTop: !isMobile ? 10 : 0,
                            // color: 'black',
                        }}
                        size={80}
                    />
                    {i18n.t('tipProposal1')}
                </Link>
                <Link
                    to="/feedback/send-bug"
                    className="btn btn-outline-light border-0 p-2 text-dark"
                    style={{
                        color: textColor,
                        ...pageButton,
                        background: 'white',
                        height: '10rem',
                        margin: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: '2rem',
                        fontSize: '1rem',
                        justifyContent: 'center',
                    }}
                >
                    <IoIosBug
                        style={{
                            marginLeft: 0,
                            paddingTop: !isMobile ? 10 : 0,
                        }}
                        size={80}
                    />
                    {i18n.t('bugReport1')}
                </Link>
            </div>
        </div>
    )
}

export default FeedbackScreen
