import { FunctionComponent } from 'react'
import useColorScheme from '../../hooks/useColorScheme'
import { pageTitleFontSize } from '../../constants/Layout'
import { View } from 'react-native-web'
import { SlArrowRight } from 'react-icons/sl'
import useResponsiveStates from '../../hooks/useResponsiveStates'

type baseCardProps = {
    title?: any
    buttonLeft?: any
    buttonLabel?: string
    route?: () => void
    children?: any
    beingAbletoClose?: boolean
}

export const BaseCard: FunctionComponent<baseCardProps> = ({
    children,
    title,
    buttonLeft,
    buttonLabel,
    route = () => {
        window.open('www.google.com', '_blank')
    },
    beingAbletoClose,
}) => {
    const { getByStyleProp } = useColorScheme()
    const { isMobile, isTablet } = useResponsiveStates()
    const backgroundColor: string = getByStyleProp('cardBackground')
    const textColor = getByStyleProp('text')
    return (
        <div
            style={
                beingAbletoClose
                    ? {
                          backgroundColor: backgroundColor,
                          margin: 15,
                          boxShadow: '0 1px 5px rgba(0,0,0,0.4)',
                          border: 'none',
                          padding: 15,
                          height: '100%',
                          maxHeight: isMobile ? '100%' : '30%',
                      }
                    : {
                          backgroundColor: backgroundColor,
                          margin: 15,
                          boxShadow: '0 1px 5px rgba(0,0,0,0.4)',
                          border: 'none',
                          padding: 15,
                          height: '100%',
                          maxHeight: isMobile ? '100%' : isTablet ? '30%' : '40%',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                      }
            }
            className={`img-thumbnail ${beingAbletoClose ? 'base-card-scrollable' : ''}`}
        >
            {title && <div style={{ marginBottom: 0, paddingLeft: 0, color: textColor }}>{title}</div>}
            <View parent={false} style={{ paddingLeft: 0, paddingRight: 0 }}>
                {children}
            </View>
            <View
                parent={false}
                style={{
                    marginTop: 0,
                    flexDirection: 'row',
                    justifyContent: buttonLeft ? 'space-between' : 'flex-end',
                    alignItems: 'center',
                    paddingRight: 0,
                }}
            >
                <div style={{ color: textColor }}>{buttonLeft}</div>
                {buttonLabel && (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-evenly',
                            zIndex: 10,
                            cursor: 'pointer',
                            color: textColor,
                        }}
                        onClick={() => {
                            route()
                        }}
                    >
                        <div style={{ fontFamily: 'Montserratbold', fontSize: pageTitleFontSize }}>{buttonLabel}</div>
                        <SlArrowRight size={20} style={{ marginLeft: 5, color: textColor }}></SlArrowRight>
                    </div>
                )}
            </View>
        </div>
    )
}

export default BaseCard
