import { useEffect, useState } from 'react'
import HealthRepository from '../../repositories/healthRepository'
import log from 'loglevel'

export default function HealthScreen() {
    const healthService = new HealthRepository()
    const [status, setStatus] = useState<string>('')
    const [errors, setErrors] = useState<string>('')

    const fetchHealthStatus = async () => {
        return await healthService.checkHealth()
    }

    const res = fetchHealthStatus()

    useEffect(() => {
        res.then((x) => {
            if (x === 'Service is healthy !') setStatus(x)
            else {
                setStatus('Something is wrong')
                setErrors(JSON.stringify(x, null, 2))
                log.debug('OBJECT ', JSON.stringify(x, null, 2))
            }
        })
    }, [])

    return (
        <>
            <h3>{status}</h3>
            <pre>{errors}</pre>
        </>
    )
}
