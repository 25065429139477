import log from 'loglevel'
import { LOGGED_IN, NEW_INTERVAL_EXP_TIMER, TIMER_ON } from './constants'
import { logout } from './keycloakAuth'

export const refreshStyle = () => {
    styleFlag = !styleFlag
}
export let styleFlag: boolean = false

const parseJwt = (token: string) => {
    var base64Url = token.split('.')[1]
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    var jsonPayload = decodeURIComponent(
        window
            .atob(base64)
            .split('')
            .map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
            })
            .join('')
    )

    return JSON.parse(jsonPayload)
}
export function calculatePercentageOfTime(totalMinutes: number, percentage: number): string {
    // Calculate the total time in seconds
    const totalTimeInSeconds = totalMinutes * (percentage / 100) * 60

    // Calculate minutes and seconds
    const minutes = Math.floor(totalTimeInSeconds / 60)
    const seconds = Math.round(totalTimeInSeconds % 60)

    // Format the output as "minutes:seconds"
    // Pad seconds with a leading zero if it's less than 10 for proper formatting
    return `${minutes}${seconds >= 60 ? '' : `:${seconds}`}`
}

export function calculateMinutesOfTime(totalMinutes: number, percentage: number): number {
    // Calculate the total time in seconds
    const totalTimeInSeconds = totalMinutes * (percentage / 100) * 60

    // Calculate minutes and seconds
    return totalTimeInSeconds
}

const parseExpInterval = (unixTimestamp: string | number) => {
    const givenDate = new Date(Number(unixTimestamp) * 1000) // Convert Unix timestamp to milliseconds
    const currentDate = new Date() // Current date and time

    const differenceInMilliseconds = currentDate.getTime() - givenDate.getTime()
    const differenceInMins = differenceInMilliseconds / (1000 * 60) // Convert milliseconds to minutes

    return differenceInMins
}

export const getTheInterval = (token: string) => {
    return Math.abs(parseExpInterval(parseJwt(token).exp)).toFixed(2)
}
export let timerOfnewInterval: NodeJS.Timer | null
export const settingTimerToNull = () => {
    timerOfnewInterval = null
}
export function settingTheTimer() {
    if (!timerOfnewInterval) {
        let time = Number(sessionStorage.getItem(NEW_INTERVAL_EXP_TIMER))

        if (time && typeof time !== undefined)
            timerOfnewInterval = setInterval(() => {
                let current_time = Number(sessionStorage.getItem(NEW_INTERVAL_EXP_TIMER))

                if (current_time <= 0) {
                    clearInterval(timerOfnewInterval ?? undefined)
                    timerOfnewInterval = null
                    sessionStorage.setItem(TIMER_ON, 'false')
                    sessionStorage.setItem(LOGGED_IN, 'false')
                    logout()
                } else {
                    let newTime = current_time - 1
                    log.debug('TIMER new time downcounting ', newTime)
                    sessionStorage.setItem(NEW_INTERVAL_EXP_TIMER, newTime.toString())
                }
            }, 1000)
    }
}
