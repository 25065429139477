import { View } from 'react-native-web'
import useColorScheme from '../../hooks/useColorScheme'
import useStyles from '../../hooks/useStyles'
import { Text } from 'react-native-web'
import { SlArrowRight } from 'react-icons/sl'
import { fontSize, normalizeFontSize, pageNormalFontSize } from '../../constants/Layout'
import useResponsiveStates from '../../hooks/useResponsiveStates'

type CtaButtonType = {
    label: string
    onClick?: () => void
    primary?: boolean
    icon?: any
    routeParams?: Object
    disabled?: boolean
    style?: any
}

type InnerCtaButtonType = {
    label: string
    primary?: boolean
    icon?: any
    isButton: boolean
    disabled: boolean
    threedeffect: boolean
    onClick?: () => void
}

function InnerCtaCard({ primary, icon, label, isButton, disabled, threedeffect = true, onClick = () => {} }: InnerCtaButtonType) {
    const { getByStyleProp } = useColorScheme()
    const textColor = getByStyleProp('text')
    const backgroundColor: string = primary ? getByStyleProp('tint') : getByStyleProp('ctaSecondary')
    const color: string = primary ? getByStyleProp('textSecondary') : getByStyleProp('ctaTintText')
    const colorDisabled: string = getByStyleProp('btnTextDisabled')
    const { isMobile } = useResponsiveStates()
    return (
        <div
            onClick={onClick}
            style={{
                backgroundColor: backgroundColor,
                margin: 15,
                boxShadow: '0 1px 5px rgba(0,0,0,0.4)',
                border: 'none',
                padding: 10,
                minHeight: isMobile ? 0 : '13%',
                color: textColor,
                cursor: 'pointer',
            }}
            className="img-thumbnail"
        >
            <View
                style={{
                    width: '100%',
                    height: '100%',
                    minHeight: threedeffect ? 50 : 50,
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                {icon && (
                    <View
                        style={{
                            backgroundColor,
                            justifyContent: 'center',
                            alignItems: 'center',
                            paddingVertical: 0,
                            marginBottom: threedeffect ? '5%' : '0%',
                        }}
                    >
                        {icon}
                    </View>
                )}
                <View
                    style={{
                        backgroundColor,
                        flex: 2,
                        flexDirection: 'column',
                        display: 'flex',
                        justifyContent: 'center',
                        marginBottom: threedeffect ? '5%' : '0%',
                        alignItems: 'flex-start',
                        paddingLeft: 10,
                    }}
                >
                    <View style={{ justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                        <Text
                            title={true}
                            style={{
                                color: disabled ? colorDisabled : color,
                                paddingLeft: icon ? 15 : 0,
                                textAlign: 'left',
                                fontSize: pageNormalFontSize,
                            }}
                        >
                            {label}
                        </Text>
                    </View>
                </View>

                {isButton && <SlArrowRight size={30} />}
            </View>
        </div>
    )
}

function CtaCard({ label, onClick, primary = true, icon, disabled = false, style = {} }: CtaButtonType) {
    const { threedeffect } = useStyles()

    return onClick ? (
        <InnerCtaCard
            label={label}
            primary={primary}
            icon={icon}
            threedeffect={threedeffect ? threedeffect : false}
            isButton={true}
            disabled={disabled}
            onClick={onClick}
        />
    ) : (
        <InnerCtaCard label={label} primary={primary} icon={icon} threedeffect={false} isButton={false} disabled={false} />
    )
}

export default CtaCard
