import { RepositoryFactory } from '../../repositories/repositoryFactory'
import React, { useEffect } from 'react'
import { buildRequestTokenInterceptorCallback, buildResponseErrorInterceptorCallback } from '../../helpers/OidcInterceptors'
import useAuth from '../../hooks/useAuth'
import { KEY_AUTH_ACCESS_TOKEN, KEY_AUTH_REFRESH_TOKEN, LOGGED_IN } from '../../helpers/constants'
import useVars, { envVar } from '../../hooks/useVars'
import { colorSchemesType } from '../../hooks/useColorScheme'
import { logoDimensionsType } from '../../hooks/useLogo'
import { BadgeType } from '../../hooks/useBadges'
import { appTextType } from '../../hooks/useText'
import { HomeScreens } from '../../hooks/useHomeScreen'
import LoginScreen from '../screens/loginScreen'
import { getClient, settingEnvVariables } from '../../helpers/keycloakAuth'
import { useNavigate } from 'react-router-dom'
import Loadingscreen from './LoadingScreen'
import { dynamicSchemes } from '../../constants/Colors'

type StartScreenType = {
    envVars?: envVar
    colorSchemes: colorSchemesType
    styles: { [key: string]: React.CSSProperties }
    badges: BadgeType
    textInput: appTextType
    featuresInput: any
    homeScreen?: HomeScreens
    feedbackScreen?: any
    loginScreenDisclaimer?: any
    logo?: {
        logo: any
        logoDimensions: logoDimensionsType
    }
}

export default function StartScreen({ homeScreen, colorSchemes, styles, logo, badges, textInput }: StartScreenType) {
    const { getEnvVars } = useVars()
    const { baseUrl, apiSuffix } = getEnvVars()

    RepositoryFactory.setUrl({
        baseUrl,
        apiSuffix,
    })

    RepositoryFactory.setInterceptors(buildRequestTokenInterceptorCallback, buildResponseErrorInterceptorCallback)

    const { loggedIn, user, getCurrentUserInfo, isFetchingUserData } = useAuth(dynamicSchemes())

    let hasFetched = false
    const navigate = useNavigate()

    useEffect(() => {
        if (loggedIn) navigate('/home')
    }, [loggedIn, user])

    useEffect(() => {
        if (sessionStorage.getItem(LOGGED_IN) === 'true') {
            navigate('/home')
        } else if (loggedIn) navigate('/home')
        else if (!hasFetched) {
            let client = getClient()
            if (client) {
                client.onAuthSuccess = () => {
                    if (client.token && client.token !== undefined) {
                        sessionStorage.setItem(KEY_AUTH_ACCESS_TOKEN, client.token)
                        //@ts-ignore
                        sessionStorage.setItem(KEY_AUTH_REFRESH_TOKEN, client.refreshToken)
                        settingEnvVariables()
                        getCurrentUserInfo()
                    }
                }
            } else {
                getCurrentUserInfo()
            }
            hasFetched = true
        }
    }, [])

    if (isFetchingUserData) {
        return <Loadingscreen />
    }

    return <>{<LoginScreen />}</>
}
