import EntityModel from '../../helpers/interfaces/entityModel'
import BadgeStatsModel, { BadgeStatsModelStorage } from './BadgeStatsModel'
import { BaseEntityModel } from './baseEntityModel'
import QuestionModel, { QuestionModelStorage } from './questionModel'
import ResultModal from './resultModal'
import TipModel, { TipModelStorage } from './tipModel'

type AppScheduleBlockModelStorageType = {
    id?: string
    questions: QuestionModelStorage[]
    tips?: TipModelStorage[]
    has_responded: boolean
    has_read_tip?: boolean
    badge_status?: BadgeStatsModelStorage
    badgeColorScheme?: string
    start_date_next?: string
}

export default class AppScheduleBlockModel extends BaseEntityModel implements EntityModel<AppScheduleBlockModel> {
    constructor(
        public id?: string,
        public questions?: QuestionModel[],
        public questionsAnswerd: boolean = false,
        public tips?: TipModel[],
        public tipRead?: boolean,
        public results?: ResultModal,
        public badgesStatus?: BadgeStatsModel,
        public badgeColorScheme?: string,
        public start_date_next?: string,
        public resultCount?: number
    ) {
        super(id)
    }

    public static deserialize(input: any): AppScheduleBlockModel {
        return new AppScheduleBlockModel(
            input.id,
            this.mapEntityArray(input.questions, QuestionModel),
            input.has_responded ? input.has_responded : false,
            this.mapEntityArray(input.tips, TipModel),
            input.has_read_tip ? input.has_read_tip : false,
            undefined,
            input.badge_status ? BadgeStatsModel.deserialize(input.badge_status) : undefined,
            input.badgeColorScheme,
            input.start_date_next
        )
    }

    public serialize(): Object {
        return {}
    }

    public getQuestion(questionId: string): QuestionModel | undefined {
        const FilterQuestions: QuestionModel[] | undefined =
            this.questions && this.questions.filter((question: QuestionModel) => questionId === question.id)

        return FilterQuestions && FilterQuestions.length > 0 ? FilterQuestions[0] : undefined
    }

    public getTip(tipId: string): TipModel | undefined {
        const FilterTips: TipModel[] | undefined = this.tips && this.tips.filter((tip: TipModel) => tipId === tip.id)

        return FilterTips && FilterTips.length > 0 ? FilterTips[0] : undefined
    }

    public setAnswer(questionId: string, answerId: string): AppScheduleBlockModel {
        if (this.questions) {
            this.questions.forEach((question: QuestionModel) => {
                if (question.id === questionId) question.setAnswer(answerId)
            })
        }

        return this
    }

    public serializeForStorage(): AppScheduleBlockModelStorageType {
        let questionsArray: QuestionModelStorage[] = []
        this.questions &&
            this.questions.map((x) => x).forEach((question) => questionsArray.push(question.serialize() as QuestionModelStorage))
        const tips: TipModelStorage[] = []
        this.tips && this.tips.forEach((tip: TipModel) => tips.push(tip.serialzeForStorage()))

        return {
            id: this.id,
            questions: questionsArray,
            tips: tips,
            has_responded: this.questionsAnswerd,
            has_read_tip: this.tipRead,
            badge_status: this.badgesStatus?.serializeForStorage(),
            badgeColorScheme: this.badgeColorScheme,
            start_date_next: this.start_date_next,
        }
    }

    public serializeForRespond(): any {
        const returnObject: any[] = []
        this.questions &&
            this.questions.forEach((question: QuestionModel, index: number) => {
                returnObject.push({
                    question: question.id,
                    answers: question.answers,
                })
            })

        return { responses: returnObject }
    }
}
