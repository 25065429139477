import { dynamicSchemes } from '../constants/Colors'
import { AuthContext } from './useAuth'
import { KEY_COLOR_STORAGE } from '../helpers/constants'
import { useContext } from 'react'

export type colorOptionsType = {
    text: string
    textSecondary: string
    appBackground: string | string[]
    defaultAppBackground: string | string[]
    altAppBackground: string
    cardBackground: string
    tint: string
    defaultTint: string
    secondary: string
    gold: string
    silver: string
    bronze: string
    badgeAccent: string
    badgeAccentComplete: string
    ctaTint: string
    ctaSecondary: string
    ctaTintText: string
    successBackground: string
    successText: string
    menuBackground: string
    menuActive: string
    defaultMenuActive: string
    menuColor: string
    btn: string
    btnText: string
    btnDisabled: string
    btnTextDisabled: string
    btnSecondary: string
    btnSecondaryText: string
    btnBorder: string
    headerColor: string
    danger: string
    inputBackground: string
}

export type ThemeOptionsType = keyof colorSchemesType

export type colorSchemesType = {
    light: colorOptionsType
    dark: colorOptionsType
}

type useColorSchemeType = {
    colors: colorOptionsType
    colorScheme: ThemeOptionsType
    getByStyleProp: (_type: keyof colorOptionsType) => string
}

let colorSchemesVars: colorSchemesType = dynamicSchemes()

export default function useColorScheme(colorSchemesVarsInput?: colorSchemesType): useColorSchemeType {
    if (colorSchemesVarsInput) {
        colorSchemesVars = colorSchemesVarsInput
    }

    const { user } = useContext(AuthContext)
    const colorScheme: any = undefined
    const colors: colorOptionsType = colorSchemesVars.light
    let getByStyleProp = (type: keyof colorOptionsType): string => user.colorSelection.light[type] as string

    const inputColors = sessionStorage.getItem(KEY_COLOR_STORAGE)
    let userColors: colorSchemesType
    if (inputColors) {
        userColors = JSON.parse(inputColors)

        if (inputColors && userColors) getByStyleProp = (type: keyof colorOptionsType): string => userColors.light[type] as string
    }

    return {
        colors,
        colorScheme,
        getByStyleProp,
    }
}
