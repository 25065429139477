import * as React from 'react'

export interface BadgeProps {
  reached?: boolean,
  target?: number,
  amount?: number,
  backgroundColor?: string[],
  tint?: string,
  color?: string
}

export type BadgeType = { [index: string]: { component: React.FC<BadgeProps>, props: BadgeProps } }

let Badges: BadgeType = {}

export default function useBadges(BadgesInput?: BadgeType): BadgeType {
  if (BadgesInput) { Badges = BadgesInput }
  return Badges
}