//Here is the database, the keycloak and dashboard are from dev.

import { envVar } from '../../hooks/useVars'

export const env: envVar = {
    baseUrl: 'https://wellbeingapp-dev.inuits.dev',
    apiSuffix: 'api',
    oidc_clientId: 'wellbeingapp-app-stadgent',
    oidc_realm: 'askforce1-stadgent-dev',
    oidc_redirectUrl: 'http://localhost:3000',
    autoDiscoverUrl: 'https://auth.inuits.io/',
}
