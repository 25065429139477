export default {
    startScreen: 'Share your opinion anonymously and discover what others think',
    userInfoTitle: '2 quick questions',
    userInfoIntro:
        'AskForceOne only uses this info to see what others within your company think.\n\nThe app does not look at individual users or share this info.',
    userInfoBtnLabel: 'Ready!',
    bottomTabHome: 'Home',
    bottomTabResults: 'Answers',
    bottomTabBadges: 'Badges',
    bottomTabFeedback: 'Feedback',
    thxAnswers: 'Thank you for your answers',
    nextQuestionsOn: ', the next questions will appear on ',
    atThisScheduleTime: ' at 17:00',
    readTipBtn: 'Read tip',
    watchTipBtn: 'Watch video',
    CtaResults: 'See what others answered to the previous questions',
    CtaFeedback: 'What question would you like to see in the app?',
    CtaNotifications1: 'Your notifications are turned off. Would you like to ',
    CtaNotifications2: 'receive a notification',
    CtaNotifications3: ' when there are new questions? \n\n You can always change this in the settings.',
    BadgeScreenTitle: 'Doing great!',
    ResultScreenTitle: 'Answers to the previous questions',
    FeedbackScreenTitle: 'Feedback',
    FeedbackScreenIntro: 'Do you have any tips or questions about the app?\nYou can give feedback during the test phase',
    NotificationsDisabled: 'Notifications for this app are turned off in your system settings.',
    NotificationsAllowed: 'AskForceOne can send me notifications',
    NotificationsSettingsTitle: 'Notifications',
    NotificationsSettingsLink: "Turn this on in your phone's settings.",
    OfflineTitle: 'AskForceOne is offline',
    OfflineText1: 'Put your ',
    OfflineText2: 'wifi or data off and on again',
    OfflineText3: ', and try again.',
    OfflineText4: 'Still not resolved?',
    OfflineText5: 'Then the app itself will be offline and we are already working hard to get it back online.',
    OfflineCta1: 'Come back later',
    OfflineCta2: "Then you'll know immediately when AskForceOne is back online.",
    OfflineCtaLinkText: 'follow Inuits on Twitter.',
    OfflineCtaLink: 'https://twitter.com/inuits',
    OfflineButton: 'Try again',
    tipRead: 'Read',
    tipTitle: 'Tip of the \nday',
    feedbackMsg: 'Thanks for your feedback',
    BadgeColorInfoTitle: 'Want to change the color of the app?\n',
    BadgeColorInfo: 'Click on one of the badges you have already obtained.',
    BadgeColorInfoTitleNotReached: 'What can I do with those badges?\n\n',
    BadgeColorInfoNotReached1: 'With a badge ',
    BadgeColorInfoNotReached2: 'you can',
    BadgeColorInfoNotReached3: ' change ',
    BadgeColorInfoNotReached4: 'the colors',
    BadgeColorInfoNotReached5:
        " within the app. Once you've earned a badge, click on it and you're done! Can you collect all the colors?",
    next: 'Next',
    nextQuestion: 'Next question',
    questionProposedBy: 'question posed by ',
    shareAnonymously: 'share anonymously on social media ',
    questionsAnsweredText: 'questions answered',
    tipsViewed: 'tips viewed',
    day: 'day',
    days: 'days',
    currentStreak: 'Streak of',
    badgesEarned: 'Achieved badges',
    achievable: 'Still to be achieved',
    appNeedsUpdate: 'The app needs to be updated.',
    whatHappensWithMyAnswers: 'What happens to my answers?',
    badgesAlmostAchieved: 'These badges are almost in',
    extraInfo: 'Extra info or help',
    seeAllInfoLines: 'See all information lines for youth',
    askThroughChatMailPhone: 'Ask your question via chat, mail, telephone or visit us.',
    turnOn: 'turn on',
    no: 'No',
    // questionProposal1: "Submit question",
    questionProposal1: 'Submit feedback',
    // questionProposal2: "What would you like to ask others?",
    questionProposal2: "Is there any feedback you'd like to share with organisers?",
    questionProposal3: 'Anything else you want to say?',
    // questionProposal4: "Submit your question",
    questionProposal4: 'Submit your feedback',
    tipProposal1: 'Submit tip',
    // tipProposal2: "What tip would you like to pass to others?",
    tipProposal2: 'What tip would you like to pass to the participants or organisers?',
    tipProposal3: 'Is there anything else you want to say about this tip?',
    tipProposal4: ' Submit tip',
    general1: 'Are you looking for help?',
    general2: 'What do you want to ask the creators of AskForceOne?',
    general3: 'Your question or suggestion',
    general4: 'Submit question or suggestion',
    bugReport1: 'Report error in the app',
    bugReport2: '',
    bugReport3: "What's going wrong in the app?",
    bugReport4: 'Report error',
    yourQuestion: 'Your question',
    possibleAnswers: 'Possible answers to your question',
    answerToYourQuestion: 'Answer ',
    addAnswer: 'Add answer',
    maxOf6Answers: 'There is a maximum of 6 answers',
    linkToTip: 'Link to tip',
    yourName: 'Your name (optional)',
    myNameCanBeShown: 'My name can be mentioned.',
    anonymous: 'Send anonymously',
    canContactMe: 'AskForceOne can contact me by email or phone',
    yourMailOrPhone: 'input your e-mail address or phone number to contact you there.',
    fillInOneOfTwo: 'Fill in one of the two',
    provideValidMail: 'Please enter a valid email address',
    yourPhoneNumber: 'Phone number',
    provideValidPhone: 'Please enter a valid phone number',
    noResultsForToday: 'No results for today',
    settingsTitle: 'Settings',
    userDataTitle: 'Update user information',
    privacyStatementTitle: 'Privacy statement',
    tAndCTitle: 'Terms and conditions',
    standardColorTitle: 'Restore default colors',
    logoutTitle: 'Log out',
    answersText: 'answers',
    weightedBasedOn: 'Weighted based on ',
    checkBadges: 'Check badges ',
    badgeTitleWelcome: 'Welcome',
    badgeTitleQuestions: 'Questions answered',
    badgeTitleTips: 'Tips viewed',
    badgeTitleStreak: 'Streak',
    thxFeedbackQuestion1: 'Thank you for your question',
    thxFeedbackTip1: 'Thank you for your tip',
    thxFeedbackGeneral1: 'Thank you for your question or suggestion',
    thxFeedbackBug1: 'Thank you for reporting an error',
    thxFeedbackQuestion2: 'You might see it in the app soon!',
    thxFeedbackTip2: 'You might see it in the app soon!',
    thxFeedbackGeneral2: "We'll get started on it soon!",
    thxFeedbackBug2: 'This way you help to improve the app!',
    disclaimer1: 'For your information: to use the app, you must agree to the',
    disclaimer2: 'Terms of Use ',
    disclaimer3: 'and the ',
    disclaimer4: 'Privacy Policy',
    disclaimer5: ' of AskForceOne.',
    disclaimer6: 'Are you a penguin? Then one of your carers must give permission.',
    disclaimer7: 'You will receive more information about this ',
    disclaimer8: 'in the next step,',
    disclaimer9: ' before you create an account.',
    privacyPolicyLink: 'https://askforce.one/privacy-policy/',
    contactedByApp: 'You will be contacted by application and, if you would like, ',
    //----------
    activationChoise: 'You can choose between using activation code',
    enterCodePlaceholder: 'Enter an activation code here!',
    activate: 'Activate',
    scanQRCode: 'Scan QR-code',
    stopCamera: 'Stop camera',
    start: 'Start',
    or: 'or',
    timer: 'Set the time of login expiration',
    timerReset: 'Reset the timer',
    useLinkToLogin: 'Use the link you have received in your mailbox to start the login process.',
}
