import { KEY_COLOR_STORAGE } from '../helpers/constants'
import { colorSchemesType } from '../hooks/useColorScheme'

export const colorSchemes: colorSchemesType = {
    light: {
        text: '#000000',
        textSecondary: '#FFFFFF',
        appBackground: ['rgba(255, 255, 255, 0.8)', 'rgba(182, 179, 179, 0.8)'],
        defaultAppBackground: ['rgba(255, 255, 255, 0.8)', 'rgba(182, 179, 179, 0.8)'],
        altAppBackground: '#FFFFFF',
        cardBackground: 'rgba(255,255,255, 0.7)',
        tint: '#000000',
        defaultTint: '#000000',
        secondary: '#373A3C',
        gold: '#EACC62',
        silver: '#C0C0C0',
        bronze: '#B08D57',
        badgeAccent: '#C4C4C4',
        badgeAccentComplete: '#F58732',
        ctaTint: '#FFFFFF',
        ctaTintText: '#000000',
        ctaSecondary: '#FFFFFF',
        successBackground: '#F58732',
        successText: '#FFFFFF',
        menuBackground: 'rgba(255,255,255, 0.9)',
        menuActive: '#F58732',
        defaultMenuActive: '#F58732',
        menuColor: '#373A3C',
        btn: '#F58732',
        btnText: '#FFFFFF',
        btnDisabled: 'gray',
        btnTextDisabled: 'gray',
        btnSecondary: '#373A3C',
        btnSecondaryText: '#FFFFFF',
        btnBorder: '#C4C4C4',
        headerColor: '#000000',
        danger: '#F54027',
        inputBackground: '#F8F8F8',
    },
    dark: {
        text: '#FFFFFF',
        textSecondary: '#373A3C',
        appBackground: '#555759',
        defaultAppBackground: '#555759',
        altAppBackground: '#FFFFFF',
        cardBackground: '#373A3C',
        tint: '#F58732',
        defaultTint: '#F58732',
        secondary: '#9A9B9B',
        gold: '#EACC62',
        silver: '#C0C0C0',
        bronze: '#B08D57',
        badgeAccent: '#9A9B9B',
        badgeAccentComplete: '#F58732',
        ctaTint: '#9A9B9B',
        ctaTintText: '#373A3C',
        ctaSecondary: '#FFFFFF',
        successBackground: '#F58732',
        successText: '#FFFFFF',
        menuBackground: '#9A9B9B',
        menuActive: '#000000',
        defaultMenuActive: '#000000',
        menuColor: '#373A3C',
        btn: '#F58732',
        btnText: '#FFFFFF',
        btnDisabled: 'rgba(196,196,196, 0.5)',
        btnTextDisabled: '#FFFFFF',
        btnSecondary: '#9A9B9B',
        btnSecondaryText: '#373A3C',
        btnBorder: '#C4C4C4',
        headerColor: '#C4C4C4',
        danger: '#F54027',
        inputBackground: '#F8F8F8',
    },
}

export default colorSchemes

export const dynamicSchemes = (): colorSchemesType => {
    let userColors: colorSchemesType
    const input = sessionStorage.getItem(KEY_COLOR_STORAGE)
    if (input) {
        userColors = JSON.parse(input)
        return userColors
    }
    return colorSchemes
}
