import EntityModel from '../../helpers/interfaces/entityModel'
import { BaseEntityModel } from './baseEntityModel'
import TipTypeModel from './tipTypeModel'
import moment from 'moment'
import TagModel from './tagModel'

export type TipModelStorage = {
    id?: string
    content?: string
    link?: string
    image?: string
    read_minutes?: number
    video?: boolean
    has_read_tip?: boolean
}

export default class TipModel extends BaseEntityModel implements EntityModel<TipModel> {
    constructor(
        public id?: string,
        public label?: string,
        public link?: string,
        public video?: boolean,
        public read?: boolean,
        public image?: string,
        public content?: string,
        public created_at?: string,
        public updated_at?: string,
        public last_used?: string,
        public type?: TipTypeModel,
        public readMinutes?: number,
        public tags?: TagModel[],
        public has_read_tip?: boolean,
        public image_file?: string
    ) {
        super(id)
    }

    public static deserialize(input: any): TipModel {
        if (input.type?.value === 'VIDEO') {
            input.video = true
        }

        return new TipModel(
            input.id,
            input.content,
            input.link,
            input.video,
            input.read,
            input.image,
            input.content,
            input.created_at,
            input.updated_at && moment(input.updated_at).format('YYYY-MM-DD H:mm'),
            input.last_used,
            input.type,
            input.read_minutes,
            input.tags,
            input.has_read_tip
        )
    }

    public serialzeForStorage(): TipModelStorage {
        return {
            id: this.id,
            content: this.label,
            link: this.link,
            read_minutes: this.readMinutes,
            image: this.image,
            video: this.video,
            has_read_tip: this.has_read_tip,
        }
    }

    public serialize(): Object {
        let tmpType = 'TEXT'

        if (this.video) {
            tmpType = 'VIDEO'
        } else {
            tmpType = 'TEXT'
        }

        if (this.readMinutes === undefined || this.readMinutes < 1) {
            this.readMinutes = 1
        }

        const tags: string[] = []

        if (this.tags && this.tags.length > 0) {
            this.tags.forEach((tag) => {
                if (tag.id) {
                    tags.push(tag.id)
                }
            })
        }

        return {
            content: this.content,
            link: this.link,
            type: tmpType,
            read_minutes: this.readMinutes,
            image: this.image,
            tags: tags,
            has_read_tip: this.has_read_tip,
        }
    }
}
