import { useContext, useRef, useState } from 'react'
import { BLOCK_STORAGE_KEY, INTERVAL_EXP, INTERVAL_PERCENT, NEW_INTERVAL_EXP, TIMER_ON } from '../../helpers/constants'
import { AuthContext } from '../../hooks/useAuth'
import useColorScheme, { colorSchemesType } from '../../hooks/useColorScheme'
import i18n from '../../i18n/i18n'
import { useTenant } from '../../hooks/tenantContext'
import {
    calculateMinutesOfTime,
    calculatePercentageOfTime,
    refreshStyle,
    settingTheTimer,
    settingTimerToNull,
    timerOfnewInterval,
} from '../../helpers/helpers'
import useStyles from '../../hooks/useStyles'
import useResponsiveStates from '../../hooks/useResponsiveStates'
import { logout } from '../../helpers/keycloakAuth'

export default function SettingsScreen() {
    let new_interval_experation_precent = Number(localStorage.getItem(INTERVAL_PERCENT))
    let interval_experation = calculatePercentageOfTime(
        Number(sessionStorage.getItem(INTERVAL_EXP)),
        new_interval_experation_precent
    )

    const { setUserColor, user } = useContext(AuthContext)
    const { getByStyleProp } = useColorScheme()
    const { tenant } = useTenant()
    const positionOfRanger = useRef(new_interval_experation_precent ? new_interval_experation_precent : 100)
    let backgroundColor: string | string[] = getByStyleProp('defaultAppBackground')
    let tintColor: string = getByStyleProp('defaultTint')
    let menuActiveColor: string = getByStyleProp('defaultMenuActive')
    const { pageButton } = useStyles()
    const [timeToExpire, setTimeToExpire] = useState<string>(interval_experation)
    const [timerButtonIsVisible, setTimerButtonIsVisible] = useState<boolean>(
        new_interval_experation_precent < 100 ? true : false
    )
    const textColor = getByStyleProp('text')
    const { isMobile } = useResponsiveStates()

    if (tenant && tenant.tenant.backgroundcolor && tenant.tenant.primarycolor && tenant.tenant.secondarycolor) {
        backgroundColor = [tenant.tenant.backgroundcolor, tenant.tenant.backgroundcolor]
        tintColor = tenant.tenant.primarycolor
        menuActiveColor = tenant.tenant.secondarycolor
    }

    const handleSliderChange = (event: any) => {
        let percentage_of_interval = Number(event.target.value)
        if (percentage_of_interval < 100) {
            sessionStorage.setItem(TIMER_ON, 'true')
            setTimerButtonIsVisible(true)
        } else {
            sessionStorage.setItem(TIMER_ON, 'false')
            setTimerButtonIsVisible(false)
        }
        positionOfRanger.current = percentage_of_interval

        let interval = Number(sessionStorage.getItem(INTERVAL_EXP))
        if (interval && interval > 0) {
            let newTimeInSeconds = calculateMinutesOfTime(interval, percentage_of_interval)
            let newTime = calculatePercentageOfTime(interval, percentage_of_interval)
            sessionStorage.setItem(NEW_INTERVAL_EXP, newTimeInSeconds.toString())
            setTimeToExpire(newTime)
        }
        localStorage.setItem(INTERVAL_PERCENT, percentage_of_interval.toString())
    }

    return (
        <div
            style={{
                minHeight: isMobile ? '90vh' : '80vh',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center', // This centers the buttons horizontally
                    justifyContent: 'center', // This centers the buttons vertically
                    height: '100%', // Full height of the container
                }}
            >
                <label style={{ color: textColor, marginTop: isMobile ? 20 : 40 }} htmlFor="rangeInput">
                    {i18n.t('timer')}
                </label>
                <input
                    style={{ width: isMobile ? '75%' : '50%', marginTop: 20 }}
                    type="range"
                    id="rangeInput"
                    min="0"
                    max={100}
                    step={1}
                    value={positionOfRanger.current}
                    onChange={handleSliderChange}
                />
                <span>{timeToExpire} min</span>
                {timerButtonIsVisible && (
                    <button
                        className="btn btn-outline-light border-0 px-4 py-2 text-dark"
                        style={{ color: textColor, ...pageButton, background: 'white' }}
                        onClick={() => {
                            settingTheTimer()
                        }}
                    >
                        Set the timer
                    </button>
                )}
                <button
                    className="btn btn-outline-light border-0 px-4 py-2 text-dark"
                    style={{ color: textColor, ...pageButton, background: 'white' }}
                    onClick={() => {
                        localStorage.setItem(TIMER_ON, 'false')
                        localStorage.setItem(INTERVAL_PERCENT, '100')
                        positionOfRanger.current = 100
                        setTimerButtonIsVisible(false)
                        localStorage.setItem(TIMER_ON, 'false')
                        clearInterval(timerOfnewInterval ?? undefined)
                        settingTimerToNull()
                        let interval_experation = calculatePercentageOfTime(Number(sessionStorage.getItem(INTERVAL_EXP)), 100)
                        setTimeToExpire(interval_experation)
                    }}
                >
                    {i18n.t('timerReset')}
                </button>
                <button
                    className="btn btn-outline-light border-0 px-4 py-2 text-dark"
                    style={{ color: textColor, ...pageButton, background: 'white' }}
                    onClick={() => {
                        const currentSchem: colorSchemesType = user.colorSelection
                        currentSchem.light.appBackground = backgroundColor
                        currentSchem.light.tint = tintColor
                        currentSchem.light.menuActive = menuActiveColor

                        setUserColor(currentSchem)
                        sessionStorage.removeItem(BLOCK_STORAGE_KEY)
                        refreshStyle()
                    }}
                >
                    {i18n.t('standardColorTitle')}
                </button>
                {
                    <button
                        className="btn btn-outline-light border-0 px-4 py-2 text-dark"
                        style={{ color: textColor, ...pageButton, background: 'white' }}
                        onClick={() => logout()}
                    >
                        {i18n.t('logoutTitle')}
                    </button>
                }
            </div>
        </div>
    )
}
