import { envVar } from './../hooks/useVars'
import { env as localDevEnv } from './config/local_dev'
import { env as localEnv } from './config/local'
import { env as devEnv } from './config/dev'
import { env as uatEnv } from './config/uat'
import { env as prodEnv } from './config/prod'
import log from 'loglevel'
export let env: envVar
export let isRun: boolean = false
export function setInitialEnv(currentEnv: string | null | undefined = null) {
    try {
        log.debug('From setInitialEnv currentEnv ', currentEnv)
        log.debug('From setInitialEnv isRun ', isRun)
        if (!isRun)
            if (currentEnv === 'dev') {
                env = devEnv
                log.setLevel(log.levels.TRACE)
            } else if (currentEnv === 'uat') {
                env = uatEnv
                log.setLevel(log.levels.ERROR)
            } else if (currentEnv === 'prod') {
                env = prodEnv
                log.setLevel(log.levels.SILENT)
            } else if (currentEnv === 'local') {
                env = localEnv
                log.setLevel(log.levels.TRACE)
            } else env = localDevEnv
        isRun = true
        return env
    } catch (error) {
        env = localEnv
    }
}

export function getGlitchTipDsn(currentEnv: string | null | undefined = null) {
    try {
        log.debug('LINK SENTRY FRom getGlitch', process.env.REACT_APP_GLITCHTIP_DSN)
        if (currentEnv === 'dev') return process.env.REACT_APP_GLITCHTIP_DSN_DEV
        if (currentEnv === 'uat') return process.env.REACT_APP_GLITCHTIP_DSN_UAT
        if (currentEnv === 'prod') return process.env.REACT_APP_GLITCHTIP_DSN_PROD
        else return ''
    } catch (error) {
        log.error('You are running not in dev/uat or prod so there is no window.')
        return ''
    }
}
